import React,{useState,useEffect} from 'react';
import apiRequest from '../../../apiRequest'


function App() {
  const [loader,setLoader]=useState(true)
  const [data,setData] =useState({})
  const [paymentdata,setPaymentData] = useState({}) 

  useEffect(()=>{
      setPaymentData(JSON.parse(localStorage.getItem('payment')))
    setTimeout(()=>{
        paymentRequest()
        // console.log("super data",JSON.parse(localStorage.getItem('payment')))
    },200)
}, [])

async function paymentRequest () {
  const SETPAYM= JSON.parse(localStorage.getItem('payment')) 
  var bodyFormData = new FormData()
  bodyFormData.set('amount', SETPAYM.amount);
  bodyFormData.set('custid', SETPAYM.custid);
  bodyFormData.set('orderid', SETPAYM.orderid);
  bodyFormData.set('email', SETPAYM.email);
  bodyFormData.set('mobile', SETPAYM.mobile);
  apiRequest('checkout',bodyFormData)
  .then(function (response) {
      // console.log("success",response.data)
      setData(response.data)
      setLoader(false)
      // document.paytm.submit()
     setTimeout(()=>{
      document.paytm.submit()
     },200)
     
  })
  .catch(error => {
      console.log(error)
  })
}

const payData = {
  MID:              "jXuZuN82870606033808",
  WEBSITE:          "WEBSTAGING",
  CHANNEL_ID:       "WEB",
  INDUSTRY_TYPE_ID: "Retail",
  CALLBACK_URL:    "https://api.hellocheck.in/callback",
}
  return(
    <>
    {loader ? <div><center> <h1>Please do not refresh this page...</h1></center></div> : 
    <>
    <form  method="post" action="https://securegw-stage.paytm.in/order/process" name="paytm">
      <input type='hidden' name='MID' value={payData.MID} />
      <input type='hidden' name='WEBSITE' value={payData.WEBSITE} />
      <input type='hidden' name='CHANNEL_ID' value={payData.CHANNEL_ID} />
      <input type='hidden' name='INDUSTRY_TYPE_ID' value={payData.INDUSTRY_TYPE_ID} />
      <input type='hidden' name='ORDER_ID' value={data.orderid} />
      <input type='hidden' name='CUST_ID' value={data.custid} />
      <input type='hidden' name='TXN_AMOUNT' value={data.amount} />
      <input type='hidden' name='CALLBACK_URL' value={payData.CALLBACK_URL} />
      <input type='hidden' name='EMAIL' value={data.email} />
      <input type='hidden' name='MOBILE_NO' value={data.mobile} />
      <input type='hidden' name='CHECKSUMHASH' value={data.checksum}/>
    </form>
    </>
  }
    </>
  )
}
export default App