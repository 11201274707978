
import React, { Component,useEffect,useState,useRef } from 'react'
import {Header, Footer} from '../../components'
import apiRequest from '../../apiRequest'
import {data} from '../../constant'
import { CartContext } from '../../contexts/CartContext';
import Modal from 'react-modal';
import ReactToPrint from 'react-to-print';
import moment from 'moment'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    height:'500px',
    overlfow: 'scroll' // <-- This tells the modal to scrol
  }
};

class ComponentToPrint extends React.Component {

  addressList = (item) =>{
     if (item != undefined) {
       let set = []
       let p = item
       for (var key of Object.keys(p)) {
           set.push(<><div>{p[key]}</div></>)
       }
       return set
     }else{
       return null
     }
   }

render() {
 const {mydata} = this.props

 const getSubTotal =()=>{
   var price = 0
   mydata.items.map((item)=>{
     price = price + item.singleprice
   })
   return price + mydata.packageprice
 }
 
 var date1 = moment(mydata.date, 'DD/MM/YYYY'); 
 var date2 = moment(mydata.enddate, 'DD/MM/YYYY'); 

 date1 = new Date(moment(date1).format("MM/DD/YYYY"))
 date2 = new Date(moment(date2).format("MM/DD/YYYY"))
 const diffTime = Math.abs(date2 - date1);
 const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
 // console.log(diffDays);
   
 return (
     <div className="table-main" style={{marginTop:60}}>
     <div className="table-header">
         <div className="section-space">
           <img src={require("../../assets/helloCheck.png")} className="table-image" />
         </div>
         <div className="section-space">
          <strong>Sold By</strong>
          <p>
            AVL Marketing,<br></br>
            No.40,9603,Chittoor Rd,<br></br>
            Padma junction,pullepady,<br></br>
            Kochi,kerala 682018
          </p>
         </div>
     </div>
     <div className="table-section">
         <div style={{marginTop:30}} className="section-space">
             <div>
               TransactionId : {mydata.traid}
             </div>
             <div>
               OrderId : {mydata.orderid}
             </div>
         </div>
     </div>
     <div className="table-section2">
       <br/>
       <div className="section-space">
         <strong>Ship to</strong>
         <p>
         {this.addressList(mydata.address)}
         </p>
       </div>
     </div>

     <div className="table-section3">
       <div className="section-space">
         <strong>Event Date : {mydata.date} to {mydata.enddate} ({diffDays+1} {diffDays > 2 ? "Days" : "Day"} ) </strong>
       </div>
    </div>

    <div className="table-section4">
      <br />
       <div className="section-space">
         <strong>Order Details</strong>
       </div>
    </div>  

    <div className="table-section5">
     <br />
       <div className="section-space">
       <table class="main-table">
         <tr >
           <th width="10%">Sl.No</th>
           <th width="40%">Title</th>
           <th width="20%">Price</th>
           <th width="10%">Qty</th>
           <th width="20%">Total Price</th>
         </tr>
         <tr >
             <td align="center" className="tdtable">1</td>
             <td align="center"className="tdtable">{mydata.name}</td>
             <td align="center"className="tdtable">₹ {mydata.packageprice}</td>
             <td align="center"className="tdtable">1</td>
             <td align="center"className="tdtable">₹ {mydata.packageprice}</td>
         </tr>
         {mydata.items.map((item,index) =>(
           
         <tr >
           <td align="center" className="tdtable">{index+2}</td>
           <td align="center"className="tdtable">{item.bcname ? item.bcname : item.product_name}</td>
           <td align="center"className="tdtable">₹ {item.customer_price}</td>
           <td align="center"className="tdtable">{item.count}</td>
           <td align="center"className="tdtable">₹ {item.singleprice}</td>
         </tr>
         ))}
         
      
       </table>
       <hr style={{opacity:'0.5'}}></hr>
       <table class="main-table">
         <tr>
         <th width="10%"></th>
           <th align="center" width="40%"className="myth">Online Assistance</th>
           <th align="center" width="20%"className="myth"> ₹ {mydata.assistance ? "1000" : "0"}</th>
           <th align="center" width="10%"className="myth">-</th>
           <th align="center" width="30%"className="myth">₹ {mydata.assistance ? "1000" : "0"}</th>
         </tr>
       </table>

       <hr style={{opacity:'0.5'}}></hr>
       <table class="main-table">
         <tr>
           <th align="center" width="40%"className="myth"></th>
           <th align="center" width="10%"className="myth"></th>
           <th align="center" width="30%"className="myth">Sub-Total:</th>
           <th align="center" width="20%"className="myth"> ₹ {getSubTotal()}</th>
         </tr>

         <tr>
           <td align="center" width="40%"className="myth"></td>
           <td align="center" width="10%"className="myth"></td>
           <td align="center" width="30%"className="myth">Sub-Total for {diffDays + 1} ({diffDays > 2 ? "Days" : "Day"} ): </td>
           <td align="center" width="20%"className="myth"> ₹ {getSubTotal() * (diffDays + 1)} </td>
         </tr>

         <tr>
           <td align="center" width="40%"className="myth"></td>
           <td align="center" width="10%"className="myth"></td>
           <td align="center" width="30%"className="myth">Delivery Charge:</td>
           <td align="center" width="20%"className="myth"> ₹ {mydata.pincodeprice}</td>
         </tr>


         <tr>
         <td align="center" width="40%"className="myth"></td>
           <td align="center" width="10%"className="myth"></td>
           <td align="center" width="30%"className="myth"><hr style={{opacity:'0.5'}}></hr></td>
           <td align="center" width="20%"className="myth"><hr style={{opacity:'0.5'}}></hr></td>
         </tr>

         <tr>
           <td align="center" width="40%"className="myth"></td>
           <td align="center" width="10%"className="myth"></td>
           <td align="center" width="30%"className="myth"><strong>Total Amount Payable :</strong></td>
           <td align="center" width="20%"className="myth"><strong> ₹ {(getSubTotal() * (diffDays + 1)) + parseInt(mydata.pincodeprice) }</strong></td>
         </tr>
       </table>
       <hr style={{opacity:'0.5'}}></hr>
       </div>
    </div>   
   </div>
 );
}
}

function App (props){

  const Example = () => {
    const componentRef = useRef();
    return (
      <div>
      <div style={{ display: "none" }}>
        <ComponentToPrint ref={componentRef} mydata={mydata}/>
      </div>
        <ReactToPrint
          trigger={() => <div className="button-round" style={{marginLeft:40,marginRight:40,textAlign:'center',marginTop:30}}>print</div>}
          content={() => componentRef.current}
        />
      </div>
    );
  };

  const [orders, setOrders] = useState([])
  const [modal,setModal] = useState(false)
  const [mydata, setData] = useState([])
  useEffect(()=>{
    window.scrollTo(0, 0)
    localStorage.getItem('role') == 'vendor' ? ListVendorOrders() : Listmyorders()
 }, [])
  // listmyorders


function Listmyorders() {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('limit', 10);
  bodyFormData.set('page', 0);
  apiRequest('listmyorders',bodyFormData)
  .then(function (response) {
      console.log("data",response.data)
      setOrders(response.data)
  })
  .catch(error => {
    console.log(error.response)
  })
}


function ListVendorOrders() {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('limit', 10);
  bodyFormData.set('page', 0);
  apiRequest('listvendororders',bodyFormData)
  .then(function (response) {
      console.log("data",response.data)
      setOrders(response.data)
  })
  .catch(error => {
    console.log(error.response)
  })
}



function getDateorder (date) {
  var time =  moment(date)
  // console.log(time)
  return time.format("DD/MM/YYYY")
}

function lisProducts(products){
  // products.map((item)=>(
  //   <div>{item.id}</div>
  // ))
  return products.name
}

function onRequestCloseModal (setModal) {
  setModal(false)
}

function addressList(item){
  if (item != undefined) {
    let set = []
    let p = item
    for (var key of Object.keys(p)) {
        set.push(<><div>{key} : {p[key]}</div></>)
    }
    return set
  }else{
    return null
  }
}

function ModalOpen (item) {
  // console.log(item)
  setModal(true)
  let data = JSON.parse(item.Products)
  data["traid"] = item.PaytmId
  data["orderid"] = item.TransacId
  data["address"] = JSON.parse(item.Address||'{}')
  setData(data)
  console.log(data)
}
    return(
        <div id="container">
        <Header {...props} />
        <div class="about">
        <div class="welcome_container">
            <div class="box">
                <div class="text">My orders</div>
            </div>
        </div>
        </div>

{ localStorage.getItem('role') == 'vendor' ? 
<div style={{marginTop:60,width:'60%',transform:'translate(30%,0%)',marginBottom:60}}>
         <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
          <table class="main-table">
            <tr class="heading">
              <td>order id</td>
              <td>Order date</td>
              <td>Grand Total</td>
              <td>Status</td>
         
              <td></td>
            </tr>

          {orders.map((item) =>(
            
            <tr class="content">
              <td class="package-name-click">{item.id}</td>
              <td>{getDateorder(item.created)}</td>
              <td> Rs.{item.amount}</td>
              <td>{item.status}</td>
             
            </tr>
         
          ))}
         


          </table>
         
          </div>
          </div> 

:
 <div style={{marginTop:60,width:'60%',transform:'translate(30%,0%)',marginBottom:60}}>
         <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
          <table class="main-table">
            <tr class="heading">
              <td>Order id</td>
              <td>Order date</td>
              <td>Grand Total</td>
              <td>Status</td>
              <td>Action</td>
              <td></td>
            </tr>

          {orders.map((item) =>(
            
            <tr class="content" style={{cursor:'pointer'}} >
              <td >{item.TransacId}</td>
              <td class="package-name-click">{getDateorder(item.created)}</td>
              <td> ₹ {item.Amount}</td>
              <td>{item.Status}</td>
              <td ><div class="button button-round" onClick={()=>ModalOpen(item)}>view</div></td>
            </tr>
         
          ))}
         


          </table>
         
          </div>
          </div> 
}


        <Modal
          isOpen={modal}
          onRequestClose={()=>onRequestCloseModal(setModal)}
          style={customStyles}
          contentLabel="Custom Quote"
        >
              <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20}}>
                    <div style={{fontWeight:'bold'}}>Order Detail</div>
                    <div className="button-round" onClick={()=>onRequestCloseModal(setModal)}>cancel</div>
                </div>
                <div class="myprice_detail_box" style={{paddingLeft:40}}>
                            <div class="center_box">
                                {/* <div class="header">Order Details</div> */}
                                <div class="line"></div>
                                <div class="data">
                                    <div class="left">TransactionId</div>
                                    <div class="right">{mydata.traid}</div>
                                </div>
                                <div class="data">
                                    <div class="left">OrderId</div>
                                    <div class="right">{mydata.orderid}</div>
                                </div>
                                <div class="data">
                                    <div class="left">Shipping Charge</div>
                                    <div class="right orange">₹{mydata.pincodeprice}</div>
                                </div>
                                <div class="data">
                                    <div class="left">Need assistance</div>
                                    <div class="right orange">₹{mydata.assistance ?  1000 : 0 }</div>
                                </div>
                                <div class="data">
                                    <div class="left">Package Name</div>
                                    <div class="right">{mydata.name}</div>
                                </div>
                                <div class="data">
                                    <div class="left">Package Price</div>
                                    <div class="right">&#8377;{mydata.packageprice}</div>
                                </div>


                                    <div class="data" style={{marginLeft:5}}>
                                        <div class="left">Items include in package</div>
                                    </div>

                                    {/* {mydata.packageinclude.map((item)=>(
                                        <div class="data" style={{marginLeft:5,color:'grey'}}>
                                            <div class="left">{item.product_name}</div>
                                        </div>
                                    ))} */}
                                    {/* {console.log("htiit",mydata.packageinclude)} */}
                                  
                                    {mydata.packageinclude != undefined && 
                                        mydata.packageinclude.map((item)=>(
                                        <div class="data" style={{marginLeft:20}}>
                                            <div class="left">{item.product_name}</div>
                                            <div class="right"> <span style={{color:'black'}}>QTY : {item.count}</span></div>
                                        </div>
                                        ))
                                    }
                                <div class="data">
                                    <div class="left">Start date</div>
                                    <div class="right">{mydata.date}</div>
                                </div>
                                <div class="data">
                                    <div class="left">End date</div>
                                    <div class="right">{mydata.enddate}</div>
                                </div>
                                <div class="data">
                                    <div class="left">Addon Items</div>
                                </div>
                                {mydata.items != undefined && 
                                        mydata.items.map((item)=>(
                                        <div class="data" style={{marginLeft:20}}>
                                            <div class="left">{item.product_name}</div>
                                            <div class="left">QTY: {item.count}</div>
                                            <div class="right"> <span style={{color:'black'}}>&#8377;{item.singleprice}</span></div>
                                        </div>
                                        ))
                                    }
                                  <div class="data" style={{display:'flex',flexDirection:'column'}}>
                                      <div class="left">Ship to</div>
                                      <div style={{marginTop:10,marginLeft:8}}>
                                        {addressList(mydata.address)}
                                      </div>
                                  </div>
                                <div class="line"></div>
                                <div class="data">
                                    <div class="left">Total</div>
                                    <div class="right" ><strong>&#8377;{mydata.customer_price}</strong></div>
                                </div>
                                {/* <div class="data">
                                    <div class="left">Amount Payable</div>
                                    <div class="right">&#8377;{mydata.customer_price}</div>
                                </div> */}
                            </div>
                        </div>
                {Example()}
              </div>
        </Modal>
        
        
        <Footer />
        </div>
    )
}




export default App