import React, { Component } from 'react'
import axios from 'axios'
const checksum_lib = require('./checksum');

var paytmParams = {
    "MID" : "YzPFGQ42883563430554",
    "WEBSITE" :"localhost",
    "INDUSTRY_TYPE_ID" : "Retail",
    "CHANNEL_ID" : "WEB",
    "ORDER_ID" : "sadasda",
    "CUST_ID" : "sadasdasddffa",
    "MOBILE_NO" : "9745888185",
    "EMAIL" : "salioh@gmail.com",
    "TXN_AMOUNT" : "100",
    "CALLBACK_URL" : "http://localhost:8000/callback",
};
export default class App extends Component {

    state ={
        // checksum:''
    }
  
    componentDidMount() {
        window.testform.submit()
    }
 
    render(){
        return(
            <div>
             <table align='center'>
			<tr>
				<td>Transaction is being processed,</td>
			</tr>
			<tr>
				<td><font color='blue'>Please wait ...</font></td>
			</tr>
			<tr>
				<td>(Please do not press 'Refresh' or 'Back' button</td>
			</tr>
		</table>
	 <form name='testform' action='http://localhost:8000' method='post' >
			<input type='hidden' name='CURRENCY' value={'8'} />
			<input type='hidden' name='GATEWAYNAME' value='GATEWAY_USED_BY_PAYTM' />
			<input type='hidden' name='RESPMSG' value='PAYTM_RESPONSE_MESSAGE_DESCRIPTION' />
			<input type='hidden' name='BANKNAME' value='BANK_NAME_OF_ISSUING_PAYMENT_MODE' />
			<input type='hidden' name='PAYMENTMODE' value='PAYMENT_MODE_USED_BY_CUSTOMER' />
			<input type='hidden' name='MID' value='YOUR_MID_HERE' />
			<input type='hidden' name='RESPCODE' value='PAYTM_RESPONSE_CODE' />
			<input type='hidden' name='TXNID' value='PAYTM_TRANSACTION_ID' />
			<input type='hidden' name='TXNAMOUNT' value='ORDER_TRANSACTION_AMOUNT' />
			<input type='hidden' name='ORDERID' value='YOUR_ORDER_ID' />
			<input type='hidden' name='STATUS' value='PAYTM_TRANSACTION_STATUS' />
			<input type='hidden' name='BANKTXNID' value='BANK_TRANSACTION_ID' />
			<input type='hidden' name='TXNDATE' value='TRANSACTION_DATE_TIME' />
			<input type='hidden' name='CHECKSUMHASH' value={'0'} />
		</form> 
 

            </div>
        )
    }
}