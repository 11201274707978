import React, { Component } from 'react'
import { Link, Route } from 'react-router-dom'


function App(){

    function RentalSection() {
        if(localStorage.getItem('role') != 'vendor') {
            return <Link to="/rentals" className="item" style={{textDecoration:'none',color:'#000'}}>Rentals</Link>
        }
    }
    return(
        <div className="footer">
        <div className="first" style={{marginLeft:0}}>
            <div className="logo_section">
                {/* <div className="logo">
                    <div className="icon"></div>
                </div>
                <div className="text">Light and Sound</div> */}
                <img src={require('../assets/log.png')} className="logo" />
            </div>
            <div className="content">
                Avl is one of the best Audio-Visual Integration Company that has a dedicated Team of Sales, Maintenance and Project Staffs to work with you on your projects.
            </div>
        </div>
        <div className="second " style={{marginLeft:0}}>
            <div className="header">SITE MAP</div>
            <div className="content">&nbsp;</div>
            <Link to="/" className="item" style={{textDecoration:'none',color:'#000'}}>Home</Link>
            {RentalSection()}
            <Link to="/about" className="item" style={{textDecoration:'none',color:'#000'}}>About</Link>
            <Link to="/contact" className="item" style={{textDecoration:'none',color:'#000'}}>Contact</Link>
        
        </div>
        <div className="third" style={{marginLeft:0}}>
            <div className="header">Address</div>
            {/* <div className="content" style={{marginTop:"30px"}}>40/9604, Next to Old Mymoon Cinema,</div>
            <div className="content">Pullepady, Cochin-682018.</div> */}
            <div className="content" style={{marginTop:"30px"}}><a style={{color:"#000", fontFamily:"Montserrat", textDecorationLine:"none"}} href="mailto:contact@hellocheck.in"><span><img style={{height:"15px"}} src={require('../assets/mail-512.png')}></img></span> contact@hellocheck.in</a></div>
            <div className="content" ><a style={{color:"#000", fontFamily:"Montserrat", textDecorationLine:"none"}} href="tel:+917558041999"><span><img style={{height:"15px"}} src={require('../assets/phone-512.png')}></img></span> +91 75 58 04 19 99</a></div>
        </div>
    </div>
    )
}

export default App