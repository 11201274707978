import React, { useState, useEffect,useLayoutEffect } from 'react'
import { Header, Footer } from '../../../components'
import Resizer from 'react-image-file-resizer'
import apiRequest from '../../../apiRequest'
import { data } from '../../../constant'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import moment from 'moment'
import axios from 'axios'


function App(props) {

    const [mydata, setData] = useState({items:[]})
    const [email,setEmail]= useState({email:''})
    const [user,setUser]=useState({name:'',phone_number:'',email:''})
    useLayoutEffect(() => {
        !localStorage.getItem('cartdata') &&( window.location.href = data.fronturl + '/#/rentals' )
        setData(JSON.parse(localStorage.getItem('cartdata')))
        console.log("mydatra", JSON.parse(localStorage.getItem('cartdata')))
        updateUserdata()
    }, [])


    function updateUserdata () {
      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      apiRequest('getMe',bodyFormData)
      .then(function (response) {
          console.log("datauser",response.data)
            // setEmail({email:response.data.email})
            setUser(response.data)
      })
      .catch(error => {
        console.log(error.response.data)
      })
    }

async function sendInvoice(){
    // let orderid = 'AVL'+new Date().getTime()
    // var bodyFormData = new FormData();
    // bodyFormData.set('token', localStorage.getItem('token'));
    // bodyFormData.set('products',JSON.stringify(mydata));
    // bodyFormData.set('user', user.id);
    // bodyFormData.set('transid', orderid);
    // bodyFormData.set('custid', user.email);
    // bodyFormData.set('channel', "WEB");
    // bodyFormData.set('amount', mydata.customer_price);
    // bodyFormData.set('address', localStorage.getItem('address'));

    // apiRequest('OrderPackage',bodyFormData)
    // .then(function (response) {
    //     props.history.push('/payment')
    //     console.log("working post request")
 
    //     setTimeout(()=>{
    //     let payitem ={amount:mydata.customer_price,custid:user.id,orderid:orderid,email:user.email,mobile:user.phone_number}
    //     localStorage.setItem('payment',JSON.stringify(payitem))
    //     },200)
        
    // })
    // .catch(error => {
    //     console.log(error)
    // })
    // console.log('hello working')

    
}

const options = {
  key: 'rzp_test_ahUfJFCsHVNHIS',
  amount: mydata.customer_price * 100, 
  name: 'Hello Check',
  description: '',
  image: 'https://hellocheck.in/static/media/log.85194a0a.png',
  handler: function(response) {
      // alert('payment success',response.razorpay_payment_id);
      let orderid = 'AVL'+new Date().getTime()
      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      bodyFormData.set('products',JSON.stringify(mydata));
      bodyFormData.set('user', user.id);
      bodyFormData.set('transid', orderid);
      bodyFormData.set('payid', response.razorpay_payment_id);
      bodyFormData.set('custid', user.email);
      bodyFormData.set('channel', "WEB");
      bodyFormData.set('amount', mydata.customer_price);
      bodyFormData.set('address', localStorage.getItem('address'));

      apiRequest('OrderPackage',bodyFormData)
      .then(function (response) {
          props.history.push('/PaymentSuccess')
          console.log("done")        
      })
      .catch(error => {
          console.log(error)
      })
  },
  prefill: {
      name: user.name,
      contact: user.phone_number,
      email: user.email
  },
  theme: {
      color: '#FF5722',
      hide_topbar: false
  }
};

const openPayModal = () => {
  var rzp1 = new window.Razorpay(options);
  rzp1.open();
};
useEffect(() => {
  const script = document.createElement('script');
  script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  script.async = true;
  document.body.appendChild(script);
}, []);

function userAnalyticsRemove () {
   
        var bodyFormData = new FormData()
        bodyFormData.set('token', localStorage.getItem('token'))
        bodyFormData.set('packageid',mydata.id)
        apiRequest('deleteUserAnalytics',bodyFormData)
        .then(function (response) {
            console.log("success",response.data)
            // setIncluded(response.data)
        })
        .catch(error => {
           console.log(error.response)
        })

}

function addressList(){
        let set = []
        let address = localStorage.getItem('address')
        let p = JSON.parse(address||'{}')
        for (var key of Object.keys(p)) {
             set.push(<><div>{key} : {p[key]}</div></>)
        }
        return set
}

const getSubTotal =()=>{
    var price = 0
    mydata.items.map((item)=>{
      price = price + item.singleprice
    })
    return price + mydata.packageprice
  }
 
  var date1 = moment(mydata.date, 'DD/MM/YYYY'); 
  var date2 = moment(mydata.enddate, 'DD/MM/YYYY'); 
  const diffDays = moment.duration(date1.diff(date2)).asDays();

  // date1 = new Date(moment(date1).format("MM/DD/YYYY"))
  // date2 = new Date(moment(date2).format("MM/DD/YYYY"))
  // const diffTime = Math.abs(date2 - date1);
  // const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return (
        <>
        <Header  {...props}/>
        <div>
            <div>

            </div>
            <div class="checkout3">
                <div class="welcome_container">
                    <div class="decor left"></div>
                    <div class="decor right"></div>
                    <div class="box">
                        <div class="circle"></div>
                        <div class="text">Welcome user</div>
                    </div>
                </div>
                <div class="checkout_container">
                    <div class="text">CHECKOUT</div>
                    <div class="circle_container">
                        <div class="circle _1">1</div>
                        <div class="circle _2">2</div>
                        <div class="circle _3">3</div>
                    </div>
                </div>

<div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
  <div className="table-main" style={{marginTop:60,width:'150vh'}}>

     <div className="table-section2">
       <br/>
       <div className="section-space">
         <strong>Ship to</strong>
         <p>
         {addressList(mydata.address)}
         </p>
       </div>
     </div>

     <div className="table-section3">
       <div className="section-space">
         <strong>Event Date : {mydata.date} to {mydata.enddate} ({diffDays+1} {diffDays > 2 ? "Days" : "Day"} ) </strong>
       </div>
    </div>

    <div className="table-section4">
      <br />
       <div className="section-space">
         <strong>Order Details</strong>
       </div>
    </div>  

    <div className="table-section5">
     <br />
       <div className="section-space">
       <table class="main-table">
         <tr >
           <th width="10%">Sl.No</th>
           <th width="40%">Title</th>
           <th width="20%">Price</th>
           <th width="10%">Qty</th>
           <th width="20%">Total Price</th>
         </tr>
         <tr >
             <td align="center" className="tdtable">1</td>
             <td align="center"className="tdtable">{mydata.name}</td>
             <td align="center"className="tdtable">₹ {mydata.packageprice}</td>
             <td align="center"className="tdtable">1</td>
             <td align="center"className="tdtable">₹ {mydata.packageprice}</td>
         </tr>
         {mydata.items.map((item,index) =>(
           
         <tr >
           <td align="center" className="tdtable">{index+2}</td>
           <td align="center"className="tdtable">{item.bcname ? item.bcname : item.product_name}</td>
           <td align="center"className="tdtable">₹ {item.customer_price}</td>
           <td align="center"className="tdtable">{item.count}</td>
           <td align="center"className="tdtable">₹ {item.singleprice}</td>
         </tr>
         ))}
         
      
       </table>
       <hr style={{opacity:'0.5'}}></hr>
       <table class="main-table">
         <tr>
         <th width="10%"></th>
           <th align="center" width="40%"className="myth">Online Assistance</th>
           <th align="center" width="20%"className="myth"> ₹ {mydata.assistance ? "1000" : "0"}</th>
           <th align="center" width="10%"className="myth">-</th>
           <th align="center" width="30%"className="myth">₹ {mydata.assistance ? "1000" : "0"}</th>
         </tr>
       </table>

       <hr style={{opacity:'0.5'}}></hr>
       <table class="main-table">
         <tr>
           <th align="center" width="40%"className="myth"></th>
           <th align="center" width="10%"className="myth"></th>
           <th align="center" width="30%"className="myth">Sub-Total:</th>
           <th align="center" width="20%"className="myth"> ₹ {getSubTotal()}</th>
         </tr>

         <tr>
           <td align="center" width="40%"className="myth"></td>
           <td align="center" width="10%"className="myth"></td>
           <td align="center" width="30%"className="myth">Sub-Total for {diffDays + 1} ({diffDays > 2 ? "Days" : "Day"} ): </td>
           <td align="center" width="20%"className="myth"> ₹ {getSubTotal() * (diffDays + 1)} </td>
         </tr>

         <tr>
           <td align="center" width="40%"className="myth"></td>
           <td align="center" width="10%"className="myth"></td>
           <td align="center" width="30%"className="myth">Delivery Charge:</td>
           <td align="center" width="20%"className="myth"> ₹ {mydata.pincodeprice}</td>
         </tr>


         <tr>
         <td align="center" width="40%"className="myth"></td>
           <td align="center" width="10%"className="myth"></td>
           <td align="center" width="30%"className="myth"><hr style={{opacity:'0.5'}}></hr></td>
           <td align="center" width="20%"className="myth"><hr style={{opacity:'0.5'}}></hr></td>
         </tr>

         <tr>
           <td align="center" width="40%"className="myth"></td>
           <td align="center" width="10%"className="myth"></td>
           <td align="center" width="30%"className="myth"><strong>Total Amount Payable :</strong></td>
           <td align="center" width="20%"className="myth"><strong> ₹ {(getSubTotal() * (diffDays + 1)) + parseInt(mydata.pincodeprice) }</strong></td>
         </tr>
       </table>
       <hr style={{opacity:'0.5'}}></hr>
       </div>
    </div>   
   </div>
   </div>     
                <div class="buy_button_container">
                  
                    <div class="button" onClick={openPayModal}>BUY</div>
                 
                </div>
            </div>

        </div>
        </>
    )
}



export default App

