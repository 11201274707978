import React, { } from 'react';
// import '../pages/frontend/main.css';
import { Link } from 'react-router-dom'
import {data} from '../constant'
import { withRouter } from "react-router-dom";

const packages1 =({image,name,index,count,desc,price,themeid,packageid})=>{

    return(
        <div className="package_box row">
            <div className="l_box row wrap align_center">
                <div className="image" style={{backgroundImage: `url(${ data.baseurl + image})`}}></div>
                <div className="col justify_start det_box">
                    <div className="heading_main">{name}</div>
                    <div className="heading_sub">Upto <span>{count} people</span></div>
                    <div className="content">{desc}</div>
                </div>
            </div>
            <div className="r_box col align_center">
                <div className="upto_box row align_center">
                    <div className="icon bg"></div>
                    <div className="txt">Upto {count} people</div>
                </div>
                <div className="price">&#x20B9;&nbsp;{price}</div>
                 <Link to={`/packages/?theme=${themeid}&package=${packageid}`} style={{textDecoration:'none'}}>
                    <div className="buy">buy package</div>
                </Link>
            </div>
        </div>
    )
    }



    export default withRouter(packages1)