import React,{useState,useEffect} from 'react'
import {Header,Footer} from '../../components'
import axios from 'axios'
import auth from '../../auth'
import {data} from '../../constant'
import { withRouter } from "react-router-dom";
import firebase from 'firebase'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import Modal from 'react-modal';
import apiRequest from '../../apiRequest'

const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };

function App (props) {

    useEffect(()=>{
        window.scrollTo(0, 0)
        // console.log(makeid(5))
        // localStorage.removeItem('token')
        //         localStorage.removeItem('role')

        localStorage.getItem('token') && props.history.push('/')
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier("recaptcha-container",
            {
             size:"invisible"
            })
            checkRedirect()
     },[])


     
    const [phonenumber, setPhonenumber] = useState("");
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [loader3, setLoader3] = useState(false);
    const [firstview, setFirstview] = useState(true);
    const [otpview, setOtpview] = useState(false);
    const [additional, setAdditionalview] = useState(false);
    const [otpcode, setOtpCode] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [modalIsOpen,setModalIsOpen] = useState(false)
    const [modalIsOpenFb,setModalIsOpenFb] = useState(false)
    const [gmailuser,setGmailUser] = useState({email:'',given_name:''})
    const [fbuser,setFbUser] = useState({email:'',displayName:'',phoneNumber:''})
    const [fbuseredit,setFbUseredi] = useState({email:'',displayName:'',phoneNumber:''})
    // const [gloader, setGloader] = useState(false);
    // const [gmailPhoneotpview, setGmailPhoneOtpView] = useState(false);

    const checkValidation =()=>{
        if(phonenumber.length > 10 || phonenumber.length <= 9){
            return false
        }else{
            return true
        }
    }

    const checkOtpValidity =()=> {
        if (otpcode.length > 6 || otpcode.length <= 5) {
            return  false
        }else{
            return true
        }
    }
    function setUpPhonenumber(phonenumber){
        if (phonenumber.length <= 10) {
            setPhonenumber(phonenumber)
        }
    
        console.log(phonenumber)
        
    }
    function LoginorRegister() {
        const check = checkValidation()
        console.log(check)
        if (check) {
            setLoader(true)
            const phoneNumber = "+91"+phonenumber
            const appVerifier = window.recaptchaVerifier;
            firebase
            .auth()
            .signInWithPhoneNumber(phoneNumber, appVerifier)
            .then(confirmResult => {
                window.confirmationResult = confirmResult;
                console.log(confirmResult);
            setLoader(false)
            setFirstview(false)
            setOtpview(true)
            })
            .catch(error => {
            // error
            console.log(error)
            setLoader(false)
            })
        }else{
            Alert.error('Enter a valid number without +91', {
                position: 'top-right',
                effect: 'bouncyflip',
                timeout: 3000
            });
        }
    } 

    function onSubmit() {
        let check = checkOtpValidity()
       if (check) {
        setLoader2(true)
        window.confirmationResult.confirm(otpcode).then(function (result) {
            console.log("Onsubmit"+result.user.phoneNumber)
            RegisterServer(result.user.phoneNumber) 
            setLoader2(false)
            setOtpview(false)
            // setAdditionalview(true)
          }).catch(function (error) {
              setLoader2(false)
              console.log(error)
          }); 
       }else{
        Alert.error('Enter a valid OTP', {
            position: 'top-right',
            effect: 'bouncyflip',
            timeout: 3000
        });
       }
    }


 
    
        function checkRedirect () {
            firebase.auth().getRedirectResult().then(function(result) {
                if (result.credential) {
                    // localStorage.setItem('gloader',true) 
                
                    var user = result.additionalUserInfo.profile
                   console.log("user",user.email)
                    var bodyFormData = new FormData();
                    bodyFormData.set('email', user.email);
                    apiRequest('checkEmailExist',bodyFormData)
                    .then(function (response) {
                        var result = response.data
                        // setGloader(false)
                       
                        localStorage.setItem('token',result.token)
                        localStorage.setItem('role',result.role)
                        console.log(result)
                        if(result.role == "admin") {
                            auth.login(() => {
                            props.history.push("/Dashboard")
                        })
                        }else if(result.role == 'user') {
                            props.history.push("/")
                        }else {
                            props.history.push("/")
                        }
                        
                    })
                    .catch(error => {
                        console.log(error)
                        setModalIsOpen(true)
                        setGmailUser(user)
                        // localStorage.setItem('gloader',false) 
                    })
                }
        
    
              }).catch(function(error) {
                // Handle Errors here.
                var errorCode = error.code;
                var errorMessage = error.message;
                // The email of the user's account used.
                var email = error.email;
                // The firebase.auth.AuthCredential type that was used.
                var credential = error.credential;
                // ...
                console.log(error)
                Alert.error(errorMessage, {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 3000
                });
              });
         }

    function validatePhonenumber () {
        if(phonenumber.length > 10 || phonenumber.length <= 9){
            Alert.error("enter valid number", {
                position: 'top-right',
                effect: 'bouncyflip',
                timeout: 3000
            });
        }else{
            registerWithGmail()
        }
    }
    function registerWithGmail () {

        var bodyFormData = new FormData();
        bodyFormData.set('phone', phonenumber);
        axios({
            method: 'post',
            url: data.baseurl + 'checkPhoneExist',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(function (response) {
               
            var bodyFormData = new FormData();
            bodyFormData.set('email', gmailuser.email);
            bodyFormData.set('name', gmailuser.given_name);
            bodyFormData.set('phone',phonenumber);
            axios({
                method: 'post',
                url: data.baseurl + 'registerWithGmail',
                data: bodyFormData,
                config: { headers: {'Content-Type': 'multipart/form-data' }}
                })
                .then(function (response) {
                    var result = response.data
                    console.log(result)
                    localStorage.setItem('token',result.token)
                    localStorage.setItem('role',result.role)
                   
                    if(result.role == "admin") {
                        auth.login(() => {
                        props.history.push("/Dashboard")
                    })
                    }else if(result.role == 'user') {
                        props.history.push("/")
                    }else {
                        props.history.push("/")
                    }
                    // setGmailPhone(true)
                    console.log("result",result)
                    setPhonenumber('')
                })
                .catch(error => {
                    
                    // setOtpview(false)
                    props.history.push("/")
                    alert(error)
                    setPhonenumber('')
                })
            })
            .catch(error => {
                console.log('working state false')
                console.log(error)
                Alert.error('user with same phonenumber already exist', {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 3000
                });
            })
       
     
            
         }

   
    function googleSignIn() {
            const googleProvider = new firebase.auth.GoogleAuthProvider();
            firebase.auth().signInWithRedirect(googleProvider).then((result)=>{
                // localStorage.setItem('gloader',true)
                console.log(result)
            }).catch((err)=>{
                console.log(err)
            })
    }

    function RegisterServer (phone) {
        console.warn('check register user')
        var bodyFormData = new FormData();
        bodyFormData.set('phonenumber', phone.substr(3));
        
        axios({
            method: 'post',
            url: data.baseurl + 'registerUser',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(function (response) {
                console.log("Register Server"+response.data)
                var result = response.data

                // localStorage.setItem('phone',result.phone)
                if(result.role == "admin") {
                    localStorage.setItem('token',result.token)
                    localStorage.setItem('role',result.role)
                    auth.login(() => {
                    props.history.push("/Dashboard")
                })
                }else if(result.role == 'user') {
                   result.email.length < 1 ? userActivities() : userPush(result)
                    // props.history.push("/")
                }else {
                    result.email.length < 1 ? userActivities() : userPush(result)
                }
            })
            .catch(error => {
                console.log(error)
                setOtpview(false)
                // props.history.push("/")
                Alert.error(error, {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 3000
                });
            })
        } 

        function userPush(result) {
            localStorage.setItem('token',result.token)
            localStorage.setItem('role',result.role)
            props.history.push("/")
        }

function userActivities(){
    setOtpview(false)
    setAdditionalview(true)
}
function onUpdateUser() {
        setLoader3(true)
        var bodyFormData = new FormData();
        bodyFormData.set('phone', phonenumber);
        bodyFormData.set('email', email);
        bodyFormData.set('name', name);
        console.log(phonenumber,email,name)
        axios({
            method: 'post',
            url: data.baseurl + 'updateUserEmail',
            data: bodyFormData,
            config: { headers: {'ContenonSubmitt-Type': 'multipart/form-data' }}
            })
            .then(function (response) {
                var result = response.data
                setLoader3(false)
                localStorage.setItem('token',result.token)
                localStorage.setItem('role',result.role)
                props.history.push("/")
                // console.log("push from after update",response)
                webReload()
              
            })
            .catch(error => {
                setLoader3(false)
                console.log("push errot update",error)
                // props.history.push("/")
                // console.log(error)
                Alert.error(error, {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 3000
                });
            })
    }

function webReload(){
    window.location.href = data.fronturl + '/'
    window.location.reload();
}

function registerWithFb () {
            // console.log(fbuser)
        var bodyFormData = new FormData();
        bodyFormData.set('email', fbuser.email ? fbuser.email : fbuseredit.email);
        bodyFormData.set('name', fbuser.displayName);
        bodyFormData.set('phone',fbuser.phoneNumber ? fbuser.phoneNumber : fbuseredit.phoneNumber);
        axios({
            method: 'post',
            url: data.baseurl + 'registerWithGmail',
            data: bodyFormData,
            config: { headers: {'Content-Type': 'multipart/form-data' }}
            })
            .then(function (response) {
                var result = response.data
                localStorage.setItem('token',result.token)
                localStorage.setItem('role',result.role)
               
                if(result.role == "admin") {
                    auth.login(() => {
                    props.history.push("/Dashboard")
                })
                }else if(result.role == 'user') {
                    props.history.push("/")
                }else {
                    props.history.push("/")
                }
                setModalIsOpenFb(false)
                console.log("result",result)
                setPhonenumber('')
            })
            .catch(error => {
                props.history.push("/")
                alert(error)
                setPhonenumber('')
            })
     }


    function facebookSignIn(){
        // console.log("clicked")
        var provider = new firebase.auth.FacebookAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            // This gives you a Facebook Access Token. You can use it to access the Facebook API.
            var token = result.credential.accessToken;
            // The signed-in user info.
            var user = result.user;
            console.log("from facebook",user)
            var bodyFormData = new FormData();
            bodyFormData.set('email', user.email);
            apiRequest('checkEmailExist',bodyFormData)
            .then(function (response) {
                var result = response.data

                localStorage.setItem('token',result.token)
                localStorage.setItem('role',result.role)
                // console.log(result)
                if(result.role == "admin") {
                    auth.login(() => {
                    props.history.push("/Dashboard")
                })
                }else if(result.role == 'user') {
                    props.history.push("/")
                }else {
                    props.history.push("/")
                }
                
            })
            .catch(error => {
                setModalIsOpen(true)
                setGmailUser((prevState) => ({
                    ...prevState,
                    email:user.email,
                    given_name:user.displayName
                  }));
            })
            // ...
          }).catch(function(error) {
            // Handle Errors here.
            var errorCode = error.code;
            var errorMessage = error.message;
            // The email of the user's account used.
            var email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            var credential = error.credential;
            // ...
            console.log('error set',error)
            if (error.code == 'auth/account-exists-with-different-credential') {
                // var user = result.user;
                // console.log("from facebook",user)
                // var bodyFormData = new FormData();
                // bodyFormData.set('email', error.email);
                // apiRequest('checkEmailExist',bodyFormData)
                // .then(function (response) {
                //     var result = response.data
    
                //     localStorage.setItem('token',result.token)
                //     localStorage.setItem('role',result.role)
                //     // console.log(result)
                //     if(result.role == "admin") {
                //         auth.login(() => {
                //         props.history.push("/Dashboard")
                //     })
                //     }else if(result.role == 'user') {
                //         props.history.push("/")
                //     }else {
                //         props.history.push("/")
                //     }
                    
                // })
                // .catch(err => {
                //     setModalIsOpen(true)
                //     let user = {email:error.email}
                //     setGmailUser(user)
                // })
                Alert.error('This user already exist with different signin method.', {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 3000
                });
            }else{
                Alert.error(errorMessage, {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 3000
                });
            }
       
          });
    }

function verifyfbuser() {
        registerWithFb()
}

function backbuttonPress() {
    setOtpview(false)
    setFirstview(true)
}

function setUpOtp(otpcode){
    if (otpcode.length <= 6) {
        setOtpCode(otpcode)
    }

    console.log(otpcode)
    
}
    return (
        <div id="container">
          <Header />
           <div class="login">
            <div class="flex_row">
                <div class="image"></div>
                <div class="login_section">
                    <div class="header">Login to your account</div>
                    <div class="social_media_box">
                        <div class="box facebook" onClick={()=>facebookSignIn()}>
                            <div class="icon facebook" ></div>
                            <div class="text">Login with Facebook</div>
                        </div>
                        <div class="box google" onClick={()=>googleSignIn()}>
                            <div class="icon google"></div>
                            <div class="text">Login with Google+</div>
                        </div>
                    </div>
                    <div class="center">OR</div>



                    <div style={{display: firstview ? 'block' : 'none'}}>
                        <div class="input_box">
                            <input type="number" placeholder={'phone number'} value={phonenumber} onChange={e => setUpPhonenumber(e.target.value)} />
                        </div>
                        <div class="button_container">
                            <div style={{display:'flex',flexDirection:'row',flex:1,justifyContent:'center'}} >
                              
                                <button class="superbutton button2" style={{padding:loader && 0}} disabled={loader} id="recaptcha-container" onClick={()=>LoginorRegister()}>
                                   {loader ? <img src={require("../../assets/rolling.svg")} style={{width:30,height:30}}/> : "Login/Register" }
                                </button>
                                
                            </div>
                        
                        </div>
                    </div>


{/* otp enter screen */}

                    <div style={{display: otpview ? 'block' : 'none'}}>
                        <div class="button_container">
                            <div class="input_box">
                                <div class="text">OTP send to particular number</div>
                                <input type="number" value={otpcode} onChange={e => setUpOtp(e.target.value)} placeholder="Enter otp"/> 
                                <div style={{display:'flex',flexDirection:'row',flex:1,justifyContent:'center'}} >

                                    <button disabled={loader2} class="superbutton button2" style={{marginTop:30}} onClick={()=>backbuttonPress()}>
                                     back
                                    </button>


                                    <button class="superbutton button2" style={{padding:loader2 && 0,marginTop:30}} disabled={loader2} id="recaptcha-container" onClick={()=>onSubmit()}>
                                    {loader2 ? <img src={require("../../assets/rolling.svg")} style={{width:30,height:30}}/> : "Submit" }
                                    </button>
                                </div>
                            </div>
                        </div> 
                    </div>

{/* email and name */}

            <div style={{display: additional ? 'block' : 'none'}}>
                        <div class="button_container">
                            <div class="input_box">
                                <div class="text">Email</div>
                                <input type="text" value={email} onChange={e => setEmail(e.target.value)} placeholder="email"/> 
                                <div class="text" style={{marginTop:15}} >Name</div>
                                <input type="text" value={name} onChange={e => setName(e.target.value)} placeholder="name"/> 
                                <div style={{display:'flex',flexDirection:'row',flex:1,justifyContent:'center'}} >
                                    <button class="superbutton button2" style={{padding:loader3 && 0,marginTop:30}} disabled={loader3} onClick={()=>onUpdateUser()}>
                                    {loader3 ? <img src={require("../../assets/rolling.svg")} style={{width:30,height:30}}/> : "Register" }
                                    </button>
                                </div>
                            </div>
                        </div> 
                    </div>



                </div>
            </div>
        </div>


       <Footer />
       <Alert stack={{limit: 3}} timeout={3000}  />
       {/* <div class="loadingme" style={{display:localStorage.getItem('gloader') ? 'block' : 'none'}}>Loading</div> */}
       <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          contentLabel="Custom Quote"
        >
          <div style={{width:500}}>
                 <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:2,justifyContent:'space-around'}}>
                    <div style={{fontWeight:'bold'}}>User Registration</div>
                    <div className="button-round" onClick={()=>setModalIsOpen(false)}>cancel</div>
                </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Gmail* :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={gmailuser.email}  name="name"  className="input_text" type="text" />
                    </div>
        </div>
        <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Name* :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={gmailuser.given_name}  name="name"  className="input_text" type="text" />
                    </div>
        </div>
          <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Phonenumber* :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={phonenumber} onChange={e => setPhonenumber(e.target.value)} name="name" className="input_text" type="number" />
                    </div>
        </div>
        <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30,justifyContent:'center'}}>
                        <div className="button-round" onClick={()=>validatePhonenumber()}>Register</div>
        </div>
        </Modal>


        <Modal
          isOpen={modalIsOpenFb}
          style={customStyles}
          contentLabel="Custom Quote"
        >
          <div style={{width:500}}>
                 <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:60,justifyContent:'space-around'}}>
                    <div style={{fontWeight:'bold'}}>User Registration</div>/
                    <div className="button-round" onClick={()=>setModalIsOpenFb(false)}>cancel</div>
                </div>
          </div>
        {fbuser.email ? 
         <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
            <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                <div className="text">Email* :</div>
            </div>
            <div style={{flex:1}}>
            <input value={fbuser.email}  name="name"  className="input_text" type="text" />
            </div>
        </div>    
        :
        <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
            <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                <div className="text">Email* :</div>
            </div>
            <div style={{flex:1}}>
            <input value={fbuseredit.email} onChange={e => setFbUseredi(prevState => ({ ...prevState, email: e.target.value }))} className="input_text" type="text" />
            </div>
        </div>  
    }
        <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
            <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                <div className="text">Name* :</div>
            </div>
            <div style={{flex:1}}>
            <input value={fbuser.displayName}  name="name"  className="input_text" type="text" />
            </div>
        </div>
        {fbuser.phoneNumber ?
          <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
            <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                <div className="text">Phonenumber* :</div>
            </div>
            <div style={{flex:1}}>
            <input value={fbuser.phoneNumber} name="name"  className="input_text" type="number" />
            </div>
        </div>
        :
        <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
            <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                <div className="text">Phonenumber* :</div>
            </div>
            <div style={{flex:1}}>
            <input value={fbuseredit.phoneNumber} onChange={e => setFbUseredi(prevState => ({ ...prevState, phoneNumber: e.target.value }))} name="name" className="input_text" type="number" />
            </div>
        </div>
        } 
        <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30,justifyContent:'center'}}>
            <div className="button-round" onClick={()=>verifyfbuser()}>Register</div>
        </div>
        </Modal>
      </div>
    )
}

export default  withRouter(App);
