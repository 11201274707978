import React, { Component,useState,useEffect } from 'react'
import './App.scss'
import Collapse from '../../components/Collapse'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Modal from 'react-modal';
import apiRequest from '../../apiRequest'
import {data} from '../../constant'
import Resizer from 'react-image-file-resizer'


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    height:'500px',
    overlfow: 'scroll' // <-- This tells the modal to scrol
  }
};


function App (){
  const [modal,setModal] = useState(false)
  const [bg,setBg] = useState([])
  const initialbg = {title:'',image:'',color:'white'}
  const [bgData,setBgData] = useState(initialbg)

  const onChange = e => {
    const { name, value } = e.target;
    setBgData(prevState => ({ ...prevState, [name]: value }));
  }

  useEffect(()=>{
    getBackground()
 }, [])



 function getBackground() {
    var bodyFormData = new FormData();
    apiRequest('getBackground',bodyFormData)
    .then(function (response) {
          console.log(response.data)
          // var item={title:response.data[0].text,image:response.data[0].image}
          setBg(response.data)
    })
    .catch(error => {
        console.log(error.response.data)
    })
}

function removeBackground(id) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('id', id);
  apiRequest('removeBackground',bodyFormData)
  .then(function (response) {
        console.log(response.data)
        getBackground()
  })
  .catch(error => {
      console.log(error.response.data)
  })
}


 function fileChangedHandler (event) {
    var that = this
    var fileInput = false
    if(event.target.files[0]) {
        fileInput = true
    }
    if(fileInput) {
        Resizer.imageFileResizer(
            event.target.files[0],
            1200,
            1200,
            'JPEG',
            100,
            0,
            uri => {
                console.log(uri)
                productImageUpload(setBgData,uri)
            },
            'blob'
        );
      }

    }


    function AddBackground() {
        if(bgData.title.length > 0 && bgData.image.length > 0) {
            AddToServer()
        }else{
            alert("Please fill required fields")
        }
    } 

    function AddToServer() {
      console.log("data",bgData.title,bgData.image)
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('title', bgData.title);
        bodyFormData.set('image', bgData.image);
        bodyFormData.set('color', bgData.color);
        apiRequest('addBackground',bodyFormData)
        .then(function (response) {
                console.log("sucees")
                setModal(false)
                getBackground()
        })
        .catch(error => {
            
            alert(error.response.data)
        })
    }

  function modalPress(){
    setModal(true)
    setBgData(initialbg)
  }

    return(
        <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">Background Image management</div>
            <div class="button-round" onClick={()=>modalPress()}>Add Background</div>
          </div>
          <ReactCSSTransitionGroup
          transitionName="example"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnter={false}
          transitionLeave={false}>
          
          <table class="main-table">
   

   {bg.map((item)=>(
        <div class="user-list-container">
            <div class="name-section">
                <div class="icon"><img alt="" src={data.baseurl + item.image} style={{width:28,height:28,borderRadius:28/2}} ></img></div>
                <div class="text">{item.text}</div> 
            </div>
            <div class="action-container">
                <div class="button button-round" onClick={()=>removeBackground(item.id)}>delete</div>
            </div>
          </div>
   ))}
          
    
            
          </table>

          {/* add package */}

        <Modal
          isOpen={modal}
          onRequestClose={()=>onRequestCloseModal(setModal)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Add backgriund Image</div>
                    <div className="button-round" onClick={()=>onRequestCloseModal(setModal)}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">image :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                                bgData.image 
                            ?  
                            data.baseurl + bgData.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                      </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Title :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={bgData.title} onChange={onChange} name="title" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Color :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={bgData.color} onChange={onChange} name="color" className="input_text" type="text" />
                    </div>
                </div>

                 <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30}}>
                        <div className="button-round" onClick={()=>AddBackground()}>Add Background Image</div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
 }




 function onRequestCloseModal (setModal) {
  setModal(false)
}

function productImageUpload (setBgData,uriimage) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('singleImage', uriimage);
    apiRequest('uploadImage',bodyFormData)
    .then(function (response) {
        setBgData(prevState => ({ ...prevState, image: response.data }));
    })
    .catch(error => {
      console.log(error.response.data)
    })
  }

 

 
 export default App