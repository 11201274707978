
import React, { Component,useState} from 'react'
import {Header,Footer} from '../../components'
import GoogleMapReact from 'google-map-react'
import apiRequest from '../../apiRequest'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';

const AnyReactComponent = ({ text }) => 
<div style={{marginTop:-27}}>
    <img style={{height:30,width:30}} alt="lightandsound" src={require('../../assets/placeholder.png')}></img>
    <div style={{width:100,marginLeft:-21,fontFamily:"Quicksand",fontWeight:600}}>Light & Sound</div>
</div>;

function App (props){
  
      const [state,setState] = useState()
      const [center,setCenter] = useState({lat:9.81666,lng:76.2999})
      const initialStatevstData = {name:'',email:'',subject:'',message:'',place:'',mobile:''}
      const [data,setData] = useState(initialStatevstData)

      const onChange = e => {
        const { name, value } = e.target;
        setData(prevState => ({ ...prevState, [name]: value }));
      };

      function checkData () {
        if(data.name.length > 1 && data.email.length > 2 ) {
            addtoServer()
        }else{
            Alert.warning('Enter valid data', {
                position: 'top-right',
                effect: 'bouncyflip',
                timeout: 3000
            });
        }
      }

      function addtoServer() {
    
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('name', data.name);
        bodyFormData.set('email', data.email);
        bodyFormData.set('subject',data.subject);
        bodyFormData.set('message', data.message);
        bodyFormData.set('place',data.place);
        bodyFormData.set('mobile', data.mobile);
        apiRequest('addEnquiry',bodyFormData)
        .then(function (response) {
            // setModal(false)
            // se(initialStatevstData)
            setData(initialStatevstData)
            Alert.success('Message sent Success', {
                position: 'top-right',
                effect: 'bouncyflip',
                timeout: 3000
            });
            
        })
        .catch(error => {
            
            alert(error.response.data)
        })
      
      }
      

    return(
        <div id="container">>
            <Header {...props}/>
            <div class="contact">
            <div class="welcome_container">
                <div class="box">
                    <div class="text">contact us</div>
                </div>
            </div>

            <div class="heading_container">
                <div class="main">Send Us a Message</div>
                <div class="sub">Contact us or give us a call to discover how we can help.</div>
            </div>

            <div class="body_container">
                <div class="l_section">
                    <div class="input_container">
                        <div class="text">name</div>
                        <input value={data.name} onChange={onChange} name="name" className="input" type="text" />
                    </div>
                    <div class="input_container">
                        <div class="text">e-mail</div>
                        <input value={data.email} onChange={onChange} name="email" className="input" type="email" />
                    </div>
                    {/* <div class="input_container">
                        <div class="text">subject</div>
                        <input value={data.subject} onChange={onChange} name="subject" className="input" type="text" />
                    </div> */}
                    {/* <div class="input_container">
                        <div class="text">message</div>
                        <input value={data.message} onChange={onChange} className="input" name="message"></input>
                    </div> */}

                    {/* <div class="input_container">
                        <div class="text">place</div>
                        <input value={data.place} onChange={onChange} className="input" name="place"></input>
                    </div> */}

                    <div class="input_container">
                        <div class="text">mobile</div>
                        <input value={data.mobile} onChange={onChange} className="input" name="mobile" type="number"></input>
                    </div>

                    <div class="input_container">
                    {/* <div class="text">message</div> */}
                    {/* <input value={data.message} onChange={onChange} className="input" name="message"></input> */}
                    </div> 
                    <div class="input_container">
                    {/* <div class="text">message</div> */}
                    {/* <input value={data.message} onChange={onChange} className="input" name="message"></input> */}
                    </div> 

                    <div class="button" onClick={()=>checkData()}>send</div>
                    
                </div>
                <div class="l_section map">
                    <div class="map_box" style={{ height: '400px', width: '100%' }}>
                    {/* <GoogleMapReact
                        bootstrapURLKeys={{ key:'AIzaSyBvdGtRvUQ3sl4l2fua0FGcZLiqjlnnyvg'}}
                        defaultCenter={center}
                        defaultZoom={11}
                        >
                        <AnyReactComponent
                        style={{borderColor:'red',borderWidth:1,Color:'red'}}
                            lat={9.9831352649263}
                            lng={76.283979149299}
                            text=""
                        />
                    </GoogleMapReact> */}
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3929.407774357491!2d76.283979149299!3d9.9831352649263!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080d4f0a697cb9%3A0xd94270b88d641e2e!2sAVL%20Marketing!5e0!3m2!1sen!2sin!4v1579634083308!5m2!1sen!2sin" width="100%" height="100%" frameborder="0" allowfullscreen=""></iframe> 
                    </div>                    
                </div>
            </div>
        </div>
        <Footer />
        <Alert stack={{limit: 3}} timeout={3000}  />
        </div>
    )
}


export default App
