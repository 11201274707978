import React, { Component,createContext,useState,useEffect } from 'react'

export const CheckoutContext = createContext();

function CheckoutContextProvider (props) {
    
    useEffect(()=>{
    

    }, [])

    const intial =[]
    const [checkout, setCheckout] = useState(intial);
   
    return(
            <CheckoutContext.Provider value={{checkout,setCheckout}}>
                {props.children}
            </CheckoutContext.Provider>
    )
}
export default CheckoutContextProvider