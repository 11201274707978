class Auth {
    constructor() {
      if(localStorage.getItem('role') == 'admin'){
        this.authenticated = 'admin'
      }else if(localStorage.getItem('role') == 'vendor') {
        this.authenticated = 'vendor'
      }else if(localStorage.getItem('role') == 'user') {
        console.log("i am a user")
        this.authenticated = 'user'
      }else{
        this.authenticated = 'visitor'
      }
    }
  
    login(cb) {
      if(localStorage.getItem('role') == 'admin'){
        this.authenticated = 'admin'
      }else if(localStorage.getItem('role') == 'vendor') {
        this.authenticated = 'vendor'
      }else if(localStorage.getItem('role') == 'user') {
        this.authenticated = 'user'
      }else{
        this.authenticated = 'visitor'
      }
      cb();
    }
  
    logout(cb) {
      localStorage.removeItem('token')
      localStorage.removeItem('role')
      localStorage.removeItem('cartdata')
      this.authenticated = 'visitor';
      // this.props.history('/login')
      // cb();
    }
  
    isAuthenticated() {
      return this.authenticated;
    }
  }
  
  export default new Auth();