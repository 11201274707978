import React, { Component,useState,useEffect } from 'react'
import {Header, Footer} from '../../../components'
import { Link, Route,withRouter } from 'react-router-dom'
import Packages1 from '../../../components/Package1'
import Addon from './Addon'
import Modal from 'react-modal';
// import '../main.css'
// import './section.scss'
import apiRequest from '../../../apiRequest'
// import 'url-search-params-polyfill';
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};
  
const App = ({props, match,history,location }) =>{

    const [packages,setPackages] = useState([])
    const [themes,setThemes] = useState([])
    const [themeid,setThemeid] = useState(0)
    const intialQuote = {name:'',email:'',phone:'',requirements:'',place:''}
    const [quotedata,setQuotedata] = useState(intialQuote)
    const [modalIsOpen,setModalIsOpen] = useState(false)
    const [packagedown,setPagedown] = useState(false)

    useEffect(()=>{
      window.scrollTo(0, 0)
      const search = location.search; // could be '?foo=bar'
      const params = new URLSearchParams(search);
      const mytheme = params.get('theme'); // bar
      console.log("data",mytheme)
  
      setThemeid(mytheme)
      getAllthemes(setThemes)
      getAllPackagesbyId(mytheme,setThemeid,setPackages)
   }, [])


   const onChange = e => {
    const { name, value } = e.target;
    setQuotedata(prevState => ({ ...prevState, [name]: value }));
  }
   function changeTheme(id) {
    setThemeid(id)
    getAllPackagesbyId(id,setThemeid,setPackages)
   }


   function getAllthemes () {

    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('page', 0);
    bodyFormData.set('limit', 10);
    apiRequest('listThemes',bodyFormData)
    .then(function (response) {
      console.log("mythemes",response.data)
       setThemes(response.data)
      //  changeTheme(response.data[0].id)
        
    })
    .catch(error => {
        
      console.log(error.response)
    })
  
  }
  
   function openModal() {
    setModalIsOpen(!modalIsOpen)
  }
 
  function sendQuotation(){
    console.log(quotedata)
    if(quotedata.name.length > 0 && quotedata.email.length > 0 && quotedata.requirements.length > 0 && quotedata.phone.length >0 && quotedata.place.length >0 ) {
        //send to server
        var bodyFormData = new FormData();
        bodyFormData.set('name',quotedata.name);
        bodyFormData.set('phone',quotedata.phone);
        bodyFormData.set('email',quotedata.email);
        bodyFormData.set('requirements',quotedata.requirements);
        bodyFormData.set('place',quotedata.place);
        apiRequest('createQuotation',bodyFormData)
        .then(function (response) {
          //  setThemes(response.data)
          console.log(response.data)
           openModal()
       
           Alert.success('Send Success', {
            position: 'top-right',
            effect: 'bouncyflip',
            timeout: 3000
           })
            
        })
        .catch(error => {
            
          console.log(error.response)
        })
      

    }
  }

    return(
        <div id="container">>
        <Header {...props} />
        <div className="detail">


    <div className="welcome_container">
        <div className="decor left"></div>
        <div className="decor right"></div>
        <div className="box">
            <div className="circle"></div>
            <div className="text">Welcome </div>
        </div>
    </div>
    <div className="header">SELECT YOUR <span>PACKAGE</span></div>
    <div className="body">
        <div className="taskbar">
            <div className="header">PACKAGES BY THEME <div className="icon"></div></div>
            <div className="item_box">
            {themes.map((data,index)=>(
                <div key={index} className="item" onClick={()=>changeTheme(data.id)} >{data.title}</div>
            ))}
            </div>
        </div>


        <div className="taskbar mob">
          <div className="header" onClick={()=>setPagedown(!packagedown)}>THEMES<div className="icon"></div></div>
          {packagedown ? 
              <div className="item_box">
                    {themes.map((data,index)=>(
                      <div key={index} className="item" onClick={()=>changeTheme(data.id)}>{data.title}</div>
                  ))}
              </div>
              :
              ''
          }
        </div>


        <div className="data_box">
            <div className="top_section">
                <div className="package_details">
                   <>
                   {packages.length > 0 ? packages.map((data,index) =>(
                      <Packages1 key={index} image={data.feature_image} name={data.package_name} index={index+1} count={data.people_count} desc={data.package_description} price={data.customer_price} themeid={themeid == 'rentals' || themeid == '' ? 3 : themeid} packageid={data.id} {...props}/>
                  )):
                  <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    No Packages Found This Theme
                  </div>
                  } 
                   </>

                  {}
                </div>
                <div className="box_container">
                    <div className="box" style={{textAlign:'center',display:'flex',alignItems:'center'}}>
                        <div className="icon"></div>
                        <div className="content">need custom quote</div>
                        
                       <div className="button" onClick={()=>openModal()}>submit</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
       
      <Modal
          isOpen={modalIsOpen}
          style={customStyles}
          contentLabel="Custom Quote"
        >
          <div className="modalset">
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Quick Quote</div>
                    <div className="button-round" onClick={()=>setModalIsOpen()}>cancel</div>
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:30}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Name* :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={quotedata.name} onChange={onChange} name="name" className="input_text" type="text" />
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Email* :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={quotedata.email} onChange={onChange} name="email" className="input_text" type="text" />
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Phone*:</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={quotedata.phone} onChange={onChange} name="phone" className="input_text" type="text" />
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Place*:</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={quotedata.place} onChange={onChange} name="place" className="input_text" type="text"  />
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Requirements*:</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={quotedata.requirements} multiple={true} onChange={onChange} name="requirements" className="input_text" type="text"  style={{height:50}}/>
                    </div>
                </div>

             

                <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30}}>
                        <div className="button-round" onClick={()=>sendQuotation()}>Send Quote</div>
                </div>
          </div>
        </Modal>
        <Footer />
        <Alert stack={{limit: 3}} timeout={3000}  />
        </div>
    )

}


function MenuLink({ label, to, activeOnlyWhenExact }) {
    return (
      <Route
        path={to}
        exact={activeOnlyWhenExact}
        children={({ match }) => (
            <Link to={to} class="flex-row item" style={match ? {textDecoration:'none',color:'black',backgroundColor:'#eaeaea'} : {textDecoration:'none',color:'black'}}>
               <div class="item">{label}</div>
              </Link>
        )}
      />
    );
  }
  

 


  function getAllPackagesbyId (themeid,setThemeid,setPackages) {
    console.log('this is my theme id',themeid)
 
    var bodyFormData = new FormData()
    bodyFormData.set('token', localStorage.getItem('token'))
    bodyFormData.set('page', 0)
    bodyFormData.set('limit', 10)
    bodyFormData.set('themeid',themeid)
    apiRequest('listPackagesbyThemeid',bodyFormData)
    .then(function (response) {
      console.log("packages get",response.data)
      setPackages(response.data)
    })
    .catch(error => {
        
        console.log(error.response)
    })
  
  }


export default App