import React, { useEffect, useState } from 'react'
import apiRequest from '../../apiRequest'
import Modal from 'react-modal';
import './App.scss'
import { data } from '../../constant'
import moment from 'moment'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)'
    }
};

const App = () => { 

    const [users, setUsers] = useState([])
    const [modal, setModal] = useState(false)
    const [editmodal, setEditModal] = useState(false)
    const [viewmodal, setViewModal] = useState(false)
    const [editUserdata, setEditUserdata] = useState({ email: '', Name: '', phonenumber: '', address: '', sphone: '', location: '', status: '', proof1: '', proof2: '', pincode: '', id: '' })
    // const [viewmodal,setViewModal] = useState(false)
    const [viewUserdata, setViewUserdata] = useState({ email: '', Name: '', updated:'',phonenumber: '', address: '', sphone: '', location: '', status: '', proof1: '', proof2: '', pincode: '', id: '' })
    const [userdata, setUserdata] = useState({ email: '', phonenumber: '', Name: '' })
    const [userid, setUserid] = useState(0)
    const [addloader, setAddloader] = useState(false)

    const onChange = e => {
        const { name, value } = e.target;
        setEditUserdata(prevState => ({ ...prevState, [name]: value }));
    };
    const onChangeUserAdd = e => {
        const { name, value } = e.target;
        setUserdata(prevState => ({ ...prevState, [name]: value }));
    };

    useEffect(() => {
        getUsers(setUsers)
    }, [])

    function checkAddData() {

        if (userdata.phonenumber.length > 9 || validateEmail(userdata.email) == true) {
            addtoServer()
        } else {
            alert("enter valid data")
        }

    }

    function addtoServer() {
        setAddloader(true)
        var bodyFormData = new FormData();
        bodyFormData.set('email', userdata.email);
        bodyFormData.set('name', userdata.name);
        bodyFormData.set('phonenumber', userdata.phonenumber);
        apiRequest('registerUser', bodyFormData)
            .then(function (response) {
                // console.log(response.data)
                setAddloader(false)
                setModal(false)
                setUserdata({ email: '', phonenumber: '' })
                getUsers(setUsers)
            })
            .catch(error => {
                setAddloader(false)
                alert(error.response.data)
            })
    }

    function deleteUser(item) {
        var confirmbox = window.confirm("confirm delete user!");
        if (confirmbox == true) {
            var bodyFormData = new FormData();
            bodyFormData.set('token', localStorage.getItem('token'));
            bodyFormData.set('id', item);
            apiRequest('deleteUser', bodyFormData)
                .then(function (response) {
                    getUsers(setUsers)
                })
                .catch(error => {
                    setAddloader(true)
                    alert(error.response)
                })
        }

    }

    function viewButtonPress(item) {
        setUserid(item)
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('id', item);
        apiRequest('getUser', bodyFormData)
            .then(function (response) {
                setViewModal(true)
                console.log("user main", response.data)
                let res = response.data
                let statusres = res.status == true ? 'active' : 'deactive'
                console.log("addresssss", res.address)

            
                const obj = JSON.parse(res.address);

                console.log(obj.count);
                // expected output: 42

                console.log(obj.result);
                // expected output: true

                // let addressset = res.address ? JSON.parse(res.address) : {address:'',landmark:'',pincode:''}
                setViewUserdata({
                    email: res.email,
                    name: res.name,
                    updated: res.updated,
                    phonenumber: res.phone_number,
                    address: obj.address+" , "+obj.landmark+" , "+obj.state,
                    sphone: res.secondary_number,
                    // location:addressset.landmark,
                    // pincode:addressset.pincode,
                    status: statusres,
                    proof1: res.proof1_image,
                    proof2: res.proof2_image
                })
            })
            .catch(error => {
                console.log(error)
            })
    }

    return (
        <div class="body-container">
            <div class="table-container flex-col">
                <div class="header-container flex-row">
                    <div class="text">user management</div>
                    {/* <div class="button-round" onClick={()=>setModal(true)}>add user</div> */}
                </div>

                {users.map((item) => (
                    <div class="user-list-container">
                        <div class="name-section">
                            <div class="icon"></div>
                            <div class="text">{item.phone_number}</div>
                        </div>
                        <div class="action-container">
                            <div class="button button-round" onClick={() => viewButtonPress(item.id)}>view</div>
                            <div class="button button-round" onClick={() => editButtonPress(setEditUserdata, setEditModal, item.id, setUserid)}>edit</div>
                            <div class="button button-round" onClick={() => deleteUser(item.id)}>delete</div>
                        </div>
                    </div>
                ))}

                {/* add user */}
                <Modal
                    isOpen={modal}
                    onRequestClose={() => onRequestCloseModal(setModal, setUserdata)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div style={{ width: 500 }}>
                        <div className="modalmain" style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                            <div style={{ fontWeight: 'bold' }}>Add user</div>
                            <div className="button-round" onClick={() => onRequestCloseModal(setModal, setUserdata)}>cancel</div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 60 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Name :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <input value={userdata.name} onChange={onChangeUserAdd} name="name" className="input_text" type="text" />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 60 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Email :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <input value={userdata.email} onChange={onChangeUserAdd} name="email" className="input_text" type="email" />
                            </div>
                        </div>


                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Phonenumber :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <input value={userdata.phonenumber} onChange={onChangeUserAdd} name="phonenumber" className="input_text" type="number" />
                            </div>
                        </div>

                        <div style={{ display: 'flex', marginTop: 40, justifyContent: 'center' }}>
                            {!addloader ?
                                <div className="input_box_container flex_row flex_end" style={{ justifyContent: 'flex-end' }}>
                                    <div className="button-round" onClick={() => checkAddData()}>Add user</div>
                                </div>
                                :
                                <div className="input_box_container flex_row flex_end" style={{ justifyContent: 'flex-end' }}>
                                    <div className="button-round"><img src={require('../../assets/loader.svg')} alt=""></img></div>
                                </div>
                            }

                        </div>


                        <div>
                        </div>
                    </div>

                </Modal>

                {/* edit user */}
                <Modal
                    isOpen={editmodal}
                    onRequestClose={() => onRequestCloseEditModal(setEditModal, setEditUserdata)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div style={{ width: 500 }}>
                        <div className="modalmain" style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                            <div style={{ fontWeight: 'bold' }}>Edit user</div>
                            <div className="button-round" onClick={() => onRequestCloseEditModal(setEditModal, setEditUserdata)}>cancel</div>
                        </div>
                        {/* 
                <div style={{display:'flex',flexDirection:'row',marginTop:40}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editUserdata.name} onChange={onChange} name="name" className="input_text" type="text" />
                    </div>
                </div> */}

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 40 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Email :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <input value={editUserdata.email} onChange={onChange} name="email" className="input_text" type="email" />
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Phonenumber :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <input value={editUserdata.phonenumber} onChange={onChange} name="phonenumber" className="input_text" type="text" />
                            </div>
                        </div>

                        {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Alternative Phonenumber :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editUserdata.sphone} onChange={onChange} name="sphone" className="input_text" type="text" />
                    </div>
                </div> */}

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Address :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <input value={editUserdata.address} onChange={onChange} name="address" className="input_text" type="text" />
                            </div>
                        </div>

                        {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Pincode :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editUserdata.pincode} onChange={onChange} name="pincode" className="input_text" type="number" />
                    </div>
                </div>  */}

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Status :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <select value={editUserdata.status} onChange={onChange} name="status">
                                    <option value={'active'}> active</option>
                                    <option value={'deactive'}>deactivate</option>
                                </select>
                            </div>
                        </div>
                        {/* 
                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Location :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editUserdata.location} onChange={onChange} name="location" className="input_text" type="text" />
                    </div>
                </div> */}

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, flex: 1, justifyContent: 'center' }}>
                            <div style={{ marginRight: 20 }}>
                                {editUserdata.proof1 ? <a href={data.baseurl + editUserdata.proof1} target="_blank"> <img src={data.baseurl + editUserdata.proof1} style={{ width: 50, height: 50, borderRadius: 5 }} /></a>
                                    :
                                    <img src={require('../../assets/noimage.svg')} style={{ width: 50, height: 50 }} />
                                }
                            </div>
                            <div>
                                {editUserdata.proof2 ? <a href={data.baseurl + editUserdata.proof2} target="_blank"> <img src={data.baseurl + editUserdata.proof2} style={{ width: 50, height: 50, borderRadius: 5 }} /></a>
                                    :
                                    <img src={require('../../assets/noimage.svg')} style={{ width: 50, height: 50 }} />
                                }
                            </div>
                        </div>


                        <div style={{ display: 'flex', marginTop: 40, justifyContent: 'center' }}>
                            <div className="input_box_container flex_row flex_end" style={{ justifyContent: 'flex-end' }}>
                                <div className="button-round" onClick={() => updateUserEdit(editUserdata, setEditModal, userid, setUsers)}>Update user</div>
                            </div>
                        </div>


                        <div>
                        </div>
                    </div>

                </Modal>

                {/* view user */}
                <Modal
                    isOpen={viewmodal}
                    onRequestClose={() => onRequestCloseViewModal(setViewModal, setViewUserdata)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div style={{ width: 500 }}>
                        <div className="modalmain" style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
                            <div style={{ fontWeight: 'bold' }}>View user</div>
                            <div className="button-round" onClick={() => onRequestCloseViewModal(setViewModal, setViewUserdata)}>cancel</div>
                        </div>

                        <div style={{display:'flex',flexDirection:'row',marginTop:40}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <div className="text">{viewUserdata.name} </div>
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:40}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Date :</div>
                    </div>
                    <div style={{flex:1}}>
                    <div className="text">{moment(viewUserdata.updated).utc().format('DD-MM-YYYY')}</div>
                    </div>
                </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 40 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Email :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <div className="text">{viewUserdata.email} </div>
                                {/* <input value={editUserdata.email} onChange={onChange} name="email" className="input_text" type="email" /> */}
                            </div>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Phonenumber :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <div className="text">{viewUserdata.phonenumber} </div>
                                {/* <input value={editUserdata.phonenumber} onChange={onChange} name="phonenumber" className="input_text" type="text" /> */}
                            </div>
                        </div>

                        {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Alternative Phonenumber :</div>
                    </div>
                    <div style={{flex:1}}>
                    <div className="text">{viewUserdata.sphone} </div>
                    </div>
                </div> */}

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Address :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                <div className="text">{viewUserdata.address} </div>
                                {/* <input value={editUserdata.address} onChange={onChange} name="address" className="input_text" type="text" /> */}
                            </div>
                        </div>
                        {/* 
                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Pincode :</div>
                    </div>
                    <div style={{flex:1}}>
                    <div className="text">{viewUserdata.pincode} </div>
                    </div>
                </div> */}

                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
                            <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                                <div className="text">Status :</div>
                            </div>
                            <div style={{ flex: 1 }}>
                                {/* <select value={editUserdata.status} onChange={onChange} name="status">
                            <option value={'active'}> active</option>
                            <option value={'deactive'}>deactivate</option>
                        </select> */}
                                <div className="text">{viewUserdata.status} </div>
                            </div>
                        </div>



                        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, flex: 1, justifyContent: 'center' }}>
                            <div style={{ marginRight: 20 }}>
                                {viewUserdata.proof1 ? <a href={data.baseurl + viewUserdata.proof1} target="_blank"> <img src={data.baseurl + viewUserdata.proof1} style={{ width: 50, height: 50, borderRadius: 5 }} /></a>
                                    :
                                    <img src={require('../../assets/noimage.svg')} style={{ width: 50, height: 50 }} />
                                }
                            </div>
                            <div>
                                {viewUserdata.proof2 ? <a href={data.baseurl + viewUserdata.proof2} target="_blank"> <img src={data.baseurl + viewUserdata.proof2} style={{ width: 50, height: 50, borderRadius: 5 }} /></a>
                                    :
                                    <img src={require('../../assets/noimage.svg')} style={{ width: 50, height: 50 }} />
                                }
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>

                </Modal>


            </div>
        </div>
    )
}



function getUsers(setUsers) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('page', 0);
    bodyFormData.set('limit', 200);
    apiRequest('listUsers', bodyFormData)
        .then(function (response) {
            // console.log(response.data)
            setUsers(response.data)
        })
        .catch(error => {
            console.log(error)
        })
}


function onRequestCloseModal(setModal, setUserdata) {
    setModal(false)
    setUserdata({ email: '', password: '', phonenumber: '', status: 'active' })
}

function onRequestCloseEditModal(setEditmodal, setEditUserdata) {
    setEditmodal(false)
    setEditUserdata({ name: '', email: '', phonenumber: '', address: '', sphone: '', location: '', status: '', proof1: '', proof2: '', pincode: '', password: '' })
}
function onRequestCloseViewModal(setViewmodal, setViewUserdata) {
    setViewmodal(false)
    setViewUserdata({ name: '', email: '', phonenumber: '', address: '', sphone: '', location: '', status: '', proof1: '', proof2: '', pincode: '', password: '' })
}

function editButtonPress(setEditUserdata, setEditModal, item, setUserid) {
    setUserid(item)
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('id', item);
    apiRequest('getUser', bodyFormData)
        .then(function (response) {
            setEditModal(true)
            console.log(response.data)
            let res = response.data
            let statusres = res.status == true ? 'active' : 'deactive'
            // let addressset = JSON.parse(response.data.address)
            setEditUserdata({
                name: res.name,
                email: res.email,
                name: res.name,
                phonenumber: res.phone_number,
                address: res.address,
                sphone: res.secondary_number,
                // location:addressset.landmark,
                // pincode:addressset.pincode,
                status: statusres,
                proof1: res.proof1_image,
                proof2: res.proof2_image
            })
        })
        .catch(error => {
            console.log(error)
        })
}


function updateUserEdit(editUserdata, setEditModal, userid, setUsers) {
    console.log(userid)

    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('id', userid);
    bodyFormData.set('name', editUserdata.name);
    bodyFormData.set('email', editUserdata.email);
    bodyFormData.set('phonenumber', editUserdata.phonenumber);
    bodyFormData.set('pincode', editUserdata.pincode);
    bodyFormData.set('password', editUserdata.password);
    bodyFormData.set('address', editUserdata.address);
    bodyFormData.set('secondarynumber', editUserdata.sphone);
    bodyFormData.set('location', editUserdata.location);
    bodyFormData.set('status', editUserdata.status == 'active' ? true : false);
    bodyFormData.set('proof1', editUserdata.proof1);
    bodyFormData.set('proof2', editUserdata.proof2);
    apiRequest('updateUser', bodyFormData)
        .then(function (response) {
            setEditModal(false)
            getUsers(setUsers)
        })
        .catch(error => {
            console.log(error.response)
        })
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default App
