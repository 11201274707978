import React, { useState,useEffect } from 'react'
import apiRequest from '../../apiRequest'
import Modal from 'react-modal';
import './App.scss'
import {data} from '../../constant'
import moment from 'moment'


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

const App  = () =>{

  const [vendors,setVendors] = useState([])
  const [modal,setModal] = useState(false)
  const [editmodal,setEditModal] = useState(false)
  const [viewmodal,setViewModal] = useState(false)
  const initial ={shopname:'',password:'',address:'',date:'', location:'',phonenumber:'',mail:'',gstn:'',status:true,proof1:'',proof2:'',id:''}
  const [editVendordata,setEditVendordata] = useState(initial)
  const [vendordata,setVendordata] = useState(initial)
  const [vendorid,setVendorid] = useState(0)
  const [addloader,setAddloader] = useState(false)

  useEffect(()=>{
    getVendors(setVendors)
 }, [])

 const onChangeVendorAdd = e => {
  const { name, value } = e.target;
  setVendordata(prevState => ({ ...prevState, [name]: value }));
};

const onChangeVendorEdit = e => {
    const { name, value } = e.target;
    setEditVendordata(prevState => ({ ...prevState, [name]: value }));
};

 function deleteVendor(item) {
     console.log(item)
  var confirmbox = window.confirm("confirm delete vendor!");
  if(confirmbox == true) {
      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      bodyFormData.set('id', item);
      apiRequest('deleteVendor',bodyFormData)
      .then(function (response) {
          getVendors(setVendors)
      })
      .catch(error => {
          // setAddloader(true)
          alert(error.response.data)
      })
  }

}

function onRequestCloseViewModal () {
    setViewModal(false)
    setEditVendordata(initial)
}
 
function checkAddVendor () {
  // shopname:'',password:'',address:'',location:'',phonenumber:'',mail:'',gstn:'',status:true,proof1:'',proof2:''}
  
        if(vendordata.shopname.length > 0 && vendordata.phonenumber.length > 9 && validateEmail(vendordata.mail) == true && vendordata.location.length > 0) {
            setAddloader(true)
            addtoServer()
        }else{
            alert("enter valid data")
        }
}


function addtoServer() {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('shopname', vendordata.shopname);
  bodyFormData.set('location', vendordata.shopname);
  bodyFormData.set('email', vendordata.mail);
  bodyFormData.set('vendorstatus',"success");
//   bodyFormData.set('password', vendordata.password);
  bodyFormData.set('phonenumber',vendordata.phonenumber);
  apiRequest('registerVendor',bodyFormData)
  .then(function (response) {
      // console.log(response.data)
      setAddloader(false)
      setModal(false)
      setVendordata(initial)
      getVendors(setVendors)
  })
  .catch(error => {
       setAddloader(false)
      alert(error.response.data)
  })
}

function editButtonClick (item) {
   console.log("id",item)
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('id', item);
    apiRequest('getVendor',bodyFormData)
    .then(function (response) {
        setEditModal(true)
        console.log("get single",response.data)
        let res = response.data
        let statusres = res.status == true ? 'active' : 'deactive'
        setEditVendordata({
         shopname:res.companyname,
         address:res.address,
         gstn:res.gstn,
         mail:res.email,
         status:statusres,
         phonenumber:res.phone_number,
         proof1:res.vendor1_image,
         proof2:res.vendor2_image,
         location:res.location,
         id:res.id
        })
    })
    .catch(error => {
        console.log(error.response.data)
    })
}

function viewButtonClick (item) {
    console.log("id",item)
     var bodyFormData = new FormData();
     bodyFormData.set('token', localStorage.getItem('token'));
     bodyFormData.set('id', item);
     apiRequest('getVendor',bodyFormData)
     .then(function (response) {
         setViewModal(true)
         console.log("get single",response.data)
         let res = response.data
         let statusres = res.status == true ? 'active' : 'deactive'
         console.log(res.address)
         setEditVendordata({
          shopname:res.companyname,
          address:res.address,
          created:res.created,
          gstn:res.gstn,
          mail:res.email,
          status:statusres,
          phonenumber:res.phone_number,
          proof1:res.vendor1_image,
          proof2:res.vendor2_image,
          location:res.location,
          id:res.id
         })
     })
     .catch(error => {
         console.log(error.response)
     })
 }
 

function updateVendorEdit () {
    console.log(editVendordata.id)
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('id', editVendordata.id);
    bodyFormData.set('shopname', editVendordata.shopname);
    bodyFormData.set('email', editVendordata.mail);
    bodyFormData.set('gstn', editVendordata.gstn);
    bodyFormData.set('created', editVendordata.created);

    // bodyFormData.set('phonenumber', editVendordata.phonenumber);
    bodyFormData.set('password', editVendordata.password);
    bodyFormData.set('address', editVendordata.address);
    bodyFormData.set('location', editVendordata.location);
    bodyFormData.set('status',  editVendordata.status == 'active' ? true : false);
    bodyFormData.set('proof1', editVendordata.proof1);
    bodyFormData.set('proof2', editVendordata.proof2);

    apiRequest('updateVendor',bodyFormData)
    .then(function (response) {
        setEditModal(false)
        getVendors(setVendors)
    })
    .catch(error => {
        console.log(error.response.data)
    })
}




   return(
    <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">Vendors</div>
            {/* <div class="button-round" onClick={()=>setModal(true)}>add vendor</div> */}
          </div>

          {vendors.map((item)=>(
            <div class="user-list-container">
            <div class="name-section">
                <div class="icon"></div>
                <div class="text">{item.phone_number}</div>
            </div>
            <div class="action-container">
                <div class="button button-round" onClick={()=>viewButtonClick(item.id)}>view</div>
                <div class="button button-round" onClick={()=>editButtonClick(item.id)}>edit</div>
                <div class="button button-round" onClick={()=>deleteVendor(item.id)}>delete</div>
            </div>
            </div>
        ))}

        {/* add vendor */}
     <Modal
          isOpen={modal}
          onRequestClose={()=>onRequestCloseModal(setModal,setVendordata,initial)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Add vendor</div>
                    <div className="button-round" onClick={()=>onRequestCloseModal(setModal,setVendordata,initial)}>cancel</div>
                </div>


                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Shop Name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={vendordata.shopname} onChange={onChangeVendorAdd} name="shopname" className="input_text" type="text" />
                    </div>
                </div>


                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Phonenumber :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={vendordata.phonenumber} onChange={onChangeVendorAdd} name="phonenumber" className="input_text" type="number" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Location :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={vendordata.location} onChange={onChangeVendorAdd} name="location" className="input_text" type="test" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Email :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={vendordata.mail} onChange={onChangeVendorAdd} name="mail" className="input_text" type="mail" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">gstn :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={vendordata.gstn} onChange={onChangeVendorAdd} name="gstn" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',marginTop:40,justifyContent:'center'}}>
                    {!addloader ?
                    <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end'}}>
                        <div className="button-round" onClick={()=>checkAddVendor()}>Add vendor</div>
                    </div>
                    :
                    <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end'}}>
                        <div className="button-round"><img src={require('../../assets/loader.svg')} alt=""></img></div> 
                    </div>
                    }

                </div>
             

                <div>
                </div>
            </div>

        </Modal>

        {/* view user */}
     <Modal
          isOpen={viewmodal}
          onRequestClose={()=>onRequestCloseViewModal()}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>View vendor</div>
                    <div className="button-round" onClick={()=>onRequestCloseViewModal()}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Date :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={moment(editVendordata.created).utc().format('DD-MM-YYYY')} disabled={true} onChange={onChangeVendorEdit} name="shopname" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Shop Name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.shopname} disabled={true} onChange={onChangeVendorEdit} name="shopname" className="input_text" type="text" />
                    </div>
                </div>

                {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Password :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.password} onChange={onChangeVendorEdit} name="password" className="input_text" type="text" />
                    </div>
                </div> */}

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Phonenumber :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.phonenumber} disabled={true} onChange={onChangeVendorEdit} name="phonenumber" className="input_text" type="number" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Address :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.address} disabled={true} onChange={onChangeVendorEdit} name="location" className="input_text" type="test" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Mail :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.mail} disabled={true} onChange={onChangeVendorEdit} name="mail" className="input_text" type="mail" />
                    </div>
                </div>

                {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">gstn :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.gstn} disabled={true} onChange={onChangeVendorEdit} name="gstn" className="input_text" type="text" />
                    </div>
                </div> */}



                <div style={{display:'flex',flexDirection:'row',marginTop:20,flex:1,justifyContent:'center'}}>
                    <div style={{marginRight:20}}>
                       {editVendordata.proof1 ? <a href={data.baseurl + editVendordata.proof1} target="_blank"> <img src={data.baseurl + editVendordata.proof1} style={{width:50,height:50,borderRadius:5}} /></a>
                       :
                       <img src={require('../../assets/noimage.svg')} style={{width:50,height:50}} />
                       }
                    </div>
                    <div>
                       {editVendordata.proof2 ? <a href={data.baseurl + editVendordata.proof2} target="_blank"> <img src={data.baseurl + editVendordata.proof2} style={{width:50,height:50,borderRadius:5}} /></a>
                       :
                       <img src={require('../../assets/noimage.svg')} style={{width:50,height:50}} />
                       }
                    </div>
                </div>


                {/* <div style={{display:'flex',marginTop:40,justifyContent:'center'}}>
                    <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end'}}>
                        <div className="button-round" onClick={()=>updateVendorEdit()}>Update vendor</div>
                    </div>
                </div> */}
             

                <div>
                </div>
            </div>

        </Modal>

         
              {/* edit user */}
     <Modal
          isOpen={editmodal}
          onRequestClose={()=>onRequestCloseEditModal(setEditModal,setEditVendordata,initial)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Edit vendor</div>
                    <div className="button-round" onClick={()=>onRequestCloseEditModal(setEditModal,setEditVendordata,initial)}>cancel</div>
                </div>

             
                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Shop Name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.shopname} onChange={onChangeVendorEdit} name="shopname" className="input_text" type="text" />
                    </div>
                </div>

                {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Password :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.password} onChange={onChangeVendorEdit} name="password" className="input_text" type="text" />
                    </div>
                </div> */}

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Phonenumber :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.phonenumber} onChange={onChangeVendorEdit} name="phonenumber" className="input_text" type="number" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Address :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.address} onChange={onChangeVendorEdit} name="address" className="input_text" type="test" />
                    </div>
                </div>

                {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Mail :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.mail} onChange={onChangeVendorEdit} name="mail" className="input_text" type="mail" />
                    </div>
                </div> */}

                {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">gstn :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={editVendordata.gstn} onChange={onChangeVendorEdit} name="gstn" className="input_text" type="text" />
                    </div>
                </div> */}



                <div style={{display:'flex',flexDirection:'row',marginTop:20,flex:1,justifyContent:'center'}}>
                    <div style={{marginRight:20}}>
                       {editVendordata.proof1 ? <a href={data.baseurl + editVendordata.proof1} target="_blank"> <img src={data.baseurl + editVendordata.proof1} style={{width:50,height:50,borderRadius:5}} /></a>
                       :
                       <img src={require('../../assets/noimage.svg')} style={{width:50,height:50}} />
                       }
                    </div>
                    <div>
                       {editVendordata.proof2 ? <a href={data.baseurl + editVendordata.proof2} target="_blank"> <img src={data.baseurl + editVendordata.proof2} style={{width:50,height:50,borderRadius:5}} /></a>
                       :
                       <img src={require('../../assets/noimage.svg')} style={{width:50,height:50}} />
                       }
                    </div>
                </div>


                <div style={{display:'flex',marginTop:40,justifyContent:'center'}}>
                    <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end'}}>
                        <div className="button-round" onClick={()=>updateVendorEdit()}>Update vendor</div>
                    </div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>
        </div>
      </div>
   )
}


function onRequestCloseEditModal (setEditmodal,setEditVendordata,initial) {
    setEditmodal(false)
    setEditVendordata(initial)
}



function getVendors(setVendors) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('page', 0);
  bodyFormData.set('limit', 10);
  apiRequest('listVendors',bodyFormData)
  .then(function (response) {
      console.log(response.data)
      setVendors(response.data)
  })
  .catch(error => {
      console.log(error)
  })
}

function onRequestCloseModal (setModal,setVendordata,initial) {
  setModal(false)
  setVendordata(initial)
}

function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}


export default App
