import React, { Component, useState, useEffect, useContext } from 'react'
import { Header, Footer } from '../../../components'
import '../main.css'
import '../rentals/section.scss'
import apiRequest from '../../../apiRequest'
import { data } from '../../../constant';
import { CartContext } from '../../../contexts/CartContext'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import _ from 'lodash'
// import { addMonths } from 'date-fns/esm'


const App = ({ props, match, history }) => {

    const { cart, setCart,productsset,setProductsset } = useContext(CartContext)
    const [categories, setCategories] = useState([])
    // const [catdata, setCatdata] = useState({})
    // const [products, setProducts] = useState([])
    const [cartprice, setCartPrice] = useState(0)
    const [packagedown, setPagedown] = useState(false)

    const [myData, setmyData] = useState([])

    useEffect(() => {
        var url = window.location.href
        var endpoint = url.split("/")
        // var themeident = endpoint[endpoint.length - 2]
        var catid = endpoint[endpoint.length - 1]
        getAllCategories()
        getProductbyCatId(catid)
    }, [])




function minusProductCount(brandid, proid) {
    const data = [...myData]
    var item = data.find(item => item.id === brandid)
    var product = item.products.find(setli => setli.id === proid)

    if (product.count > 0) {
        product.count = product.count - 1

        addtoCentralStore(product)
    }
    var dataleo =  cart.filter(function( obj ) {return obj.id !== proid})
    setCart(dataleo)
    getTotalPrice()
    setmyData(data)
}

function addProductCount(brandid, proid) {
    const data = [...myData]
    var item = data.find(item => item.id === brandid)
    var product = item.products.find(setli => setli.id === proid)
    product.count = product.count >= 1 ? product.count + 1 : 1
    addtoCentralStore(product)
    getTotalPrice()
    setmyData(data)
}


function addtoCentralStore(product) {
    var products = [...cart]
    var item = products.find(pro =>pro.id === product.id)
    if (item) {
       item.count = product.count
    }else{
        products.push(product)
    }
    setCart(products)
    console.log("set",products)
}

function changeCategory(data) {
    // addedToCart()
    getProductbyCatId(data.id)
}


function getProductbyCatId(catid) {
    // console.log("catid",catid)
    var bodyFormData = new FormData()
    bodyFormData.set('catid', catid)
    apiRequest('listProductbyCat', bodyFormData)
        .then(function (response) {
            // setmyData(response.data)
            // setCartPrice(0)
            // console.log("this is the data ho", response.data)
            matchWithStore(response.data)
    
        })
        .catch(error => {
            console.log(error)
        })
}


function matchWithStore(datasetme){
    var set = [...datasetme]
    set.map((item)=>{
        item.products.map((single)=>{
            cart.map((pro)=>{
                if(single.id === pro.id) {
                    single.count = pro.count
                }
            })
        })
    })

    setmyData(set)
}


function getProductlist(minid, singlebrand) {
    return (
        <table>
            <tr>
                <th></th>
                <th></th>
            </tr>
            {singlebrand.map((item, index) => (

                <>
                    <tr>
                        <td  width="70%">{index + 1}. {item.product_name}</td>
                        <td width="30%">₹ {item.vendor_price}</td>
                        <td style={{ display: 'flex' }}>
                            <div className="">QTY</div>
                            <div className="mybtn" onClick={() => minusProductCount(minid, item.id)}>-</div>
                            <div className="text">{item.count}</div>
                            <div className="mybtn" onClick={() => addProductCount(minid, item.id)}>+</div>
                        </td>
                    </tr>
                </>

            ))}
        </table>
    )
}


    

function getTotalPrice() {
    // console.log(datame)
    let price = 0
    myData.map((mapme) => {
        // console.log(mapme)
        mapme.products.map((prome) => {
            // console.log("single",prome)
            price = price + (prome.vendor_price * prome.count)
        })
    })
    setCartPrice(price)
    Alert.info(`Total Price ${price}`, {
        position: 'top-right',
        effect: 'bouncyflip',
        timeout: 1000
    });
    // console.log("total price",price)

}

function getAllCategories() {
    var bodyFormData = new FormData()
    bodyFormData.set('token', localStorage.getItem('token'))
    bodyFormData.set('page', 0)
    bodyFormData.set('limit', 10)
    apiRequest('listCategories', bodyFormData)
    .then(function (response) {
        setCategories(response.data)
        changeCategory(response.data[0])
    })
    .catch(error => {
        console.log(error.response)
    })
}

function getCartPrice(){
    var price = 0
    cart.map((item)=>{
        let single = item.count * item.vendor_price
        price = price + single
    })
    return price
}

    return (
        <div id="container">
            <Header {...props} />
            <div className="detail">
                <div className="welcome_container">
                    <div className="decor left"></div>
                    <div className="decor right"></div>
                    <div className="box">
                        <div className="circle"></div>
                        <div className="text">Welcome </div>
                    </div>
                </div>
                <div className="header">PURCHASE <span>PRODUCT</span></div>
                <div className="body">
                    <div className="taskbar">
                        <div className="header">CATEGORIES</div>
                        <div className="item_box">
                            {categories.map((data, index) => (
                                <div key={index} className="item" onClick={() => changeCategory(data)}>{data.name}</div>
                            ))}
                        </div>
                    </div>
                    <div className="taskbar mob">
                        <div className="header" onClick={() => setPagedown(!packagedown)}>CATEGORIES <div className="icon"></div></div>
                           {packagedown ? 
                                <div className="item_box">
                                      {categories.map((data,index)=>(
                                        <div key={index} className="item" onClick={()=>changeCategory(data)}>{data.name}</div>
                                    ))}
                                </div>
                                :
                                ''
                            }
                    </div>
                   <div class="packagemainbox">
                    <div class="add_extra_products_container">
                        <div class="heading">PRODUCTS BY BRAND</div>
                        <div class="data_box_container">
                            {/* list brands */}
                            {myData.map((midata, index) => (
                                
                                <div class="data_box" style={{ height: 'auto', marginTop: 20, marginBottom: 20 }}>
                                <div class="image" style={{ backgroundImage: "url(" + data.baseurl + midata.image + ")" ,backgroundSize:'contain'}}></div>
                                <div class="details_box">
                                    <div class="heading_box">
                                        <div class="content">
                                            <div class="main" style={{ fontWeight: 'bold' }}> {midata.name}</div>
                                        </div>
                                    </div>

                                    {getProductlist(midata.id, midata.products)}

                                </div>
                            </div>
                            
                            ))}

                        </div>

                        </div>

                        <div class="box_container" style={{marginTop:40}} >
                            <div class="boxme">
                                <div class="header">total cost</div>
                                <div class="content" style={{marginBottom:10}}>₹ {getCartPrice()}</div>

                                <div style={{ textDecoration: 'none', cursor: 'pointer' }}>
                                    <div class="button">Add To Cart</div>
                                </div>

                            </div>
                        </div>
                   </div>
                </div>
            </div>


            <Footer />
            <Alert stack={{ limit: 3 }} timeout={3000} />
        </div>
    )

}





export default App