import React,{useState,useEffect} from 'react'
import {Header, Footer} from '../../../components'
import Resizer from 'react-image-file-resizer'
import apiRequest from '../../../apiRequest'
import {data} from '../../../constant'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';

function App (props) {
    const [proofs, setProofs] = useState({proof1:null,proof2:null});

    useEffect(() => {
        !localStorage.getItem('cartdata') &&( window.location.href = data.fronturl + '/#/rentals' )
        updateUserdata()
    }, [])


    function updateUserdata () {
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        apiRequest('getMe',bodyFormData)
        .then(function (response) {
            console.log("datauser",response.data)
            if (response.data.proof1_image != null) {
                let item = {proof1:response.data.proof1_image,proof2:response.data.proof2_image}
                setProofs(item)
            }
          
        })
        .catch(error => {
          console.log(error.response)
        })
      }

    function fileChangedHandler (event,file) {
        var that = this
        var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                300,
                300,
                'PNG',
                50,
                0,
                uri => {
                    // console.log(uri)
                        proofImageUpload(uri,file)
                },
                'blob'
            );
            }
        
        }
    
    function proofImageUpload (uriimage,file) {
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('singleImage', uriimage);
        apiRequest('uploadImage',bodyFormData)
        .then(function (response) {
            // console.log("filetype",file)
            if(proofs.proof1) {
                setProofs(prevState => ({ ...prevState, proof2: response.data }))
            }else{
                setProofs(prevState => ({ ...prevState, proof1: response.data }))
            }
        })
        .catch(error => {
            console.log(error.response.data)
        })
        }

    function submitClick () {
    
            if(proofs.proof1 != null && proofs.proof2 != null) {
                    updateToPending()
                    // setOption(2)
                    props.history.push('/address')
            }else{
                Alert.error('please upload your proofs!', {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 3000
                });
            }
        }
    function updateToPending() {
            var bodyFormData = new FormData();
            bodyFormData.set('token', localStorage.getItem('token'));
            bodyFormData.set('proof1', proofs.proof1);
            bodyFormData.set('proof2', proofs.proof2);
            apiRequest('userVerification',bodyFormData)
            .then(function (response) {
            //    setVerification(false)
            
              
            })
            .catch(error => {
                console.log(error)
            })
          }
        
            
    return (
        <>
        <Header  {...props}/>
        <div class="checkout1">
                <div class="welcome_container">
                    <div class="decor left"></div>
                    <div class="decor right"></div>
                    <div class="box">
                        <div class="circle"></div>
                        <div class="text">Welcome user</div>
                    </div>
                </div>
                <div class="checkout_container">
                    <div class="text">CHECKOUT</div>
                    <div class="circle_container">
                        <div class="circle _1">1</div>
                        <div class="circle _2">2</div>
                        <div class="circle _3">3</div>
                    </div>
                </div>
                <div class="payment_container">
                    <div class="text">Upload proofs</div>
                    <div class="container_box">
                        <div class="box" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        
                        <div class="inputbox-proof">
                            {proofs.proof1 ? <img src={data.baseurl + proofs.proof1}  style={{width:70,height:80,borderRadius:8}}/> : 
                                <div>
                                    <button class="btnproof">Address Proof</button>
                                    <input type="file"  accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e,true)} />
                                </div>
                            }
                        
                        </div>
                        </div>
                        <div class="box" style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                        <div class="inputbox-proof">
                            {proofs.proof2 ? <img src={data.baseurl + proofs.proof2}  style={{width:70,height:80,borderRadius:8}}/> : 
                                <div>
                                    <button class="btnproof">ID Proof</button>
                                    <input type="file"  accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e,true)} />
                                </div>
                            }
                        
                        </div>
                        </div>
                    </div>
                </div>
                <div class="buy_button_container">
                    <div class="button borderreup" onClick={()=>setProofs({proof1:null,proof2:null})}>Reupload</div>
                    <div class="button" onClick={()=>submitClick()} style={{marginLeft:8}}>NEXT</div>
                </div>
                <Alert stack={{limit: 3}} timeout={3000}  />
            </div>
            <Footer />
            </>
    )
}

export default App