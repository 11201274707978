import React, { Component,useState,useEffect } from 'react'
import apiRequest from '../../apiRequest'
import { data } from '../../constant';


const App = () => {

  useEffect(()=>{
    getVendorPending()
 }, [])

 const [userdata,setUserdata] = useState([])

function getVendorPending () {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    apiRequest('getPendingVerification',bodyFormData)
    .then(function (response) {
        console.log("data",response.data)
        setUserdata(response.data)
        
    })
    .catch(error => {
      console.log(error.response.data)
    })
} 


function approveVendor (id) {
  // approveVendor
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('id', id);
  apiRequest('approveVendor',bodyFormData)
  .then(function (response) {
      console.log("data",response.data)
      // setUserdata(response.data)
      getVendorPending()
      
  })
  .catch(error => {
    console.log(error.response.data)
  })
}

function rejectVendor (id) {
  // approveVendor
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('id', id);
  apiRequest('rejectVendor',bodyFormData)
  .then(function (response) {
      console.log("data",response.data)
      // setUserdata(response.data)
      getVendorPending()
      
  })
  .catch(error => {
    console.log(error.response.data)
  })
}

    return(
        <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">vendor verification</div>
          </div>
          <table class="main-table">
            <tr class="heading">
              <td>UserID</td>
              <td>Phonenumber</td>
              <td>Proof 1</td>
              <td>Proof 2</td>
              <td></td>
            </tr>



            {userdata.map((item)=>(
              <tr class="content">
                          <td>{item.id}</td>
                          <td class="package-name-click">{item.phone_number}</td>
                          <td class="proof-box"><img src={data.baseurl + item.vendor1_image} style={{width:40,height:40,borderRadius:8}}/></td>
                          <td class="proof-box"><img src={data.baseurl + item.vendor2_image} style={{width:40,height:40,borderRadius:8}}/></td>
                          <td class="action-box flex-row">
                            <div class="button-round" onClick={()=>approveVendor(item.id)} >approve</div>
                            <div class="button-round" onClick={()=>rejectVendor(item.id)} >reject</div>
                          </td>
                        </tr>
            ))}
            
     
            
          </table>
        </div>
      </div>
    )
 }
 
 export default App