import React, { Component,useState,useEffect } from 'react'
import './App.scss'
import Collapse from '../../components/Collapse'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Modal from 'react-modal';
import apiRequest from '../../apiRequest'
import {data} from '../../constant'
import Resizer from 'react-image-file-resizer'


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    height:'500px',
    overlfow: 'scroll' // <-- This tells the modal to scrol
  }
};


function App (){
  const [modal,setModal] = useState(false)
  const [brand,setBrand] = useState([])
  const [editmodal,setEditModal] = useState(false)
  const initialpackage = {name:'',image:''}
  const initialStatevstData = {name:'',image:'',id:''}
  const [branddata,setBrandData] = useState(initialpackage)
  const [brandEditdata,setBrandEditdata] = useState(initialStatevstData)

  const onChange = e => {
    const { name, value } = e.target;
    setBrandData(prevState => ({ ...prevState, [name]: value }));
  }

  useEffect(()=>{
    getBrands()
 }, [])

const onChangeedit = e => {
  const { name, value } = e.target;
  setBrandEditdata(prevState => ({ ...prevState, [name]: value }));
};

function getBrands() {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    apiRequest('listBrands',bodyFormData)
    .then(function (response) {
          console.log(response.data)
          setBrand(response.data)
    })
    .catch(error => {
        console.log(error.response.data)
    })
}

function removeBrand(id) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('id', id);
  apiRequest('deleteBrand',bodyFormData)
  .then(function (response) {
        console.log(response.data)
        getBrands()
  })
  .catch(error => {
      console.log(error.response.data)
  })
}


 function fileChangedHandler (event) {
    var that = this
    var fileInput = false
    if(event.target.files[0]) {
        fileInput = true
    }
    if(fileInput) {
        Resizer.imageFileResizer(
            event.target.files[0],
            300,
            300,
            'PNG',
            50,
            0,
            uri => {
                console.log(uri)
                productImageUpload(setBrandData,uri,setBrandEditdata)
            },
            'blob'
        );
      }

    }


    function AddBrands() {
        if(branddata.name.length > 0 && branddata.image.length > 0 ) {
            AddToServer()
        }else{
            alert("Please fill required fields")
        }
    } 

    function AddToServer() {
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('name', branddata.name);
        bodyFormData.set('image', branddata.image);
        apiRequest('addBrand',bodyFormData)
        .then(function (response) {
                setModal(false)
                getBrands()
                // console.log(response.data)
        })
        .catch(error => {
            
            alert(error.response.data)
        })
    }
function modalPress(){
  setModal(true)
  setBrandData(initialpackage)
}


function editButtonPress(item) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('id', item);
  apiRequest('getBrand',bodyFormData)
  .then(function (response) {
      setEditModal(true)
      // console.log(response.data)
      let res = response.data
 
      setBrandEditdata({
        name:res.name,
        image:res.image,
        id:res.id
      })
  })
  .catch(error => {
      console.log(error.response.data)
  })
}

function checkEditData () {
 
  if(brandEditdata.name.length > 0 && brandEditdata.image.length > 0) {

      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      bodyFormData.set('id', brandEditdata.id);
      bodyFormData.set('name', brandEditdata.name);
      bodyFormData.set('image', brandEditdata.image);
      apiRequest('updateBrand',bodyFormData)
      .then(function (response) {
          setEditModal(false)
          setBrandEditdata(initialStatevstData)
          getBrands()
      })

      .catch(error => {
          
          alert(error.response.data)
      })
  }else{
      alert("Please fill required fields")
  }
}

    return(
        <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">Brands management</div>
            <div class="button-round" onClick={()=>modalPress()}>Add Brand</div>
          </div>
          <ReactCSSTransitionGroup
          transitionName="example"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnter={false}
          transitionLeave={false}>
          
          <table class="main-table">
   

   {brand.map((item)=>(
        <div class="user-list-container">
            <div class="name-section">
                <div class="icon"><img alt="" src={data.baseurl + item.image} style={{width:28,height:28,borderRadius:28/2}} ></img></div>
                <div class="text">{item.name}</div> 
            </div>
            <div class="action-container">
                <div class="button button-round" onClick={()=>editButtonPress(item.id)}>edit</div>
                <div class="button button-round" onClick={()=>removeBrand(item.id)}>delete</div>
            </div>
          </div>
   ))}
          
    
            
          </table>

          {/* add brand */}

        <Modal
          isOpen={modal}
          onRequestClose={()=>onRequestCloseModal(setModal)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Add Brand</div>
                    <div className="button-round" onClick={()=>onRequestCloseModal(setModal)}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">image :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                                branddata.image 
                            ?  
                            data.baseurl + branddata.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                      </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={branddata.name} onChange={onChange} name="name" className="input_text" type="text" />
                    </div>
                </div>

                 <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30}}>
                        <div className="button-round" onClick={()=>AddBrands()}>Add Brand</div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>

        <Modal
          isOpen={editmodal}
          onRequestClose={()=>onRequestCloseEditModal(setEditModal,setBrandData,initialStatevstData)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Edit brand</div>
                    <div className="button-round" onClick={()=>onRequestCloseEditModal(setEditModal,setBrandData,initialStatevstData)}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={brandEditdata.name} onChange={onChangeedit} name="name" className="input_text" type="text" />
                    </div>
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">image :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                              brandEditdata.image 
                            ?  
                            data.baseurl + brandEditdata.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                 </div>

                 <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end'}}>
                        <div className="button-round" onClick={()=>checkEditData(brandEditdata.id)}>update brand</div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>

          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
 }




 function onRequestCloseModal (setModal) {
  setModal(false)
}

function productImageUpload (setBrand,uriimage,setBrandEditdata) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('singleImage', uriimage);
    apiRequest('uploadImage',bodyFormData)
    .then(function (response) {
        setBrand(prevState => ({ ...prevState, image: response.data }));
        setBrandEditdata(prevState => ({ ...prevState, image: response.data }));
    })
    .catch(error => {
      console.log(error.response.data)
    })
  }

function onRequestCloseEditModal (setEditModal) {
    setEditModal(false)
   
  }

 
 export default App