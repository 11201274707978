import React, { Component,useState,useEffect,useContext } from 'react'
import {Header, Footer} from '../../../components'
import { Link, Route,withRouter } from 'react-router-dom'
import Packages1 from '../../../components/Package1'
import Addon from './Addon'
import '../main.css'
import './section.scss'
import apiRequest from '../../../apiRequest'
import { data } from '../../../constant';
import { CartContext } from '../../../contexts/CartContext'
import { DateRange } from 'react-date-range'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
import moment from 'moment'


const App = ({props, match,history,location }) =>{

    const {cart,setCart} = useContext(CartContext)
    const [packages,setPackages] = useState([])
    const [packagedata,setPackageData] = useState({})
    const [products,setProducts] = useState([])
    const [price,setPrice] = useState(0)
    const [assistance,setAssistance] = useState(false)
    const [startDate,setstartDate] = useState(moment().format("DD-MM-YYYY"))
    const [endDate,setendDate] = useState(moment().format("DD-MM-YYYY"))
    const [pincode,setPincode] = useState('')
    const [calender,setCalender] = useState(false)
    const [days,setDays] = useState(1)
    const [included,setIncluded] = useState([])
    const [differece,setDifference]= useState(1)
    const [packagedown,setPagedown]= useState(false)
    const [extraproducts,setExtraproducts]= useState([])
    const [pincodem,setPincodem]= useState(false)
    const [pincodemprice,setPincodemprice]= useState(0)
    // 
    useEffect(()=>{
        window.scrollTo(0, 0)
        var url = window.location.href
        if(!localStorage.getItem('role')) {
            // Alert.warning('please login first!', {
            //     position: 'top-right',
            //     effect: 'bouncyflip',
            //     timeout: 3000
            // });

            window.location.href = data.fronturl + '#login'
        }else{
            const search = location.search;
            const params = new URLSearchParams(search);
            const mytheme = params.get('theme'); 
            const mypackage = params.get('package')
            console.log("theme",mytheme)
            console.log("package",mypackage)
            getAllPackagesbyId(mytheme,setPackages,setPackageData,mypackage,setProducts,setPrice)
            getIncluded(mypackage)
            getExtraProducts()
        }
    
     }, [])


function getExtraProducts(){
    let search = location.search;
    let params = new URLSearchParams(search); 
    let mypackage = params.get('package')
    var bodyFormData = new FormData()
    bodyFormData.set('packageid',mypackage)
    apiRequest('listExtraproductbyPackage',bodyFormData)
    .then(function (response) {
        console.log("success",response.data)
        var result = response.data.map(function(el) {
            var o = Object.assign({}, el);
            o.singleprice = 0;
            return o;
          })
        setExtraproducts(result)
    })
    .catch(error => {
        console.log(error.response)
    })
}

function setUpPincode(pincode){
    if (pincode.length <= 6) {
        setPincode(pincode)
    }

    console.log(pincode)
    
}

function minusProductCount(itemme) {
        const data =  [...extraproducts]
        // var item = data.find(item => item.id === main);
        // const mainproducts = item.products

        var single = data.find(item => item.id === itemme);
        // single.count = single.count > 0  ? single.count - 1 : single.count
       if(single.count > 0) {
             single.count = single.count - 1
             single.singleprice = single.count >= 0  ? parseInt(single.singleprice) - single.customer_price : single.singleprice
             console.log("Single price",single)
             setPrice(price=> price - single.customer_price)
       }
        setExtraproducts(data)
        
    }

function addProductCount(itemme) {
        const data =  [...extraproducts]
        // var item = data.find(item => item.id === main);
        // const mainproducts = item.products

        var single = data.find(item => item.id === itemme);
        single.count = single.count >= 0  ? single.count + 1 : 0
        console.log("Single price",single)
        single.singleprice = single.count >= 0  ? parseInt(single.singleprice) + single.customer_price : single.singleprice
        setPrice(price =>single.count >= 0 ? price + single.customer_price : price)
        setExtraproducts(data)
    }

function assistanceClicked() {
    setAssistance(!assistance)
    setPrice(assistance ? price - 1000 : price + 1000)
}


function onCheckoutPress(){
    // console.log("alert")
  
    if (localStorage.getItem('role') == null) {
        Alert.error('Please Login First', {
            position: 'top-right',
            effect: 'slide',
            timeout: 3000
        });
    }else {
        console.log(price)
        if(price > 0 && pincodem) {
            addedToCart()
            history.push('/proof')
            addToanalytics()
        }else{
            Alert.error('Please select Pincode', {
                position: 'top-right',
                effect: 'slide',
                timeout: 3000
            });
        }
    }
}

function addToanalytics(){
    console.log("package id",packagedata.id)
    var bodyFormData = new FormData()
    bodyFormData.set('token', localStorage.getItem('token'))
    bodyFormData.set('packagedata',JSON.stringify(packagedata))
    bodyFormData.set('packageid',packagedata.id)
    apiRequest('adduserAnalytics',bodyFormData)
    .then(function (response) {
        console.log("success",response.data)
        // setIncluded(response.data)
    })
    .catch(error => {
       console.log(error.response)
    })
}

function getIncluded(pid){
    console.log("pid",pid)
    var bodyFormData = new FormData()
    bodyFormData.set('pid',pid)
    apiRequest('getAllproductsbypackage',bodyFormData)
    .then(function (response) {
        console.log("included",response.data)
        setIncluded(response.data)
    })
    .catch(error => {
       console.log(error.response)
    })
}

function addedToCart () {

    var data = []
   
    extraproducts.map((item)=>{
       
        if(item.count > 0){
            data.push(item)
        }
        
    })
    console.log("setproduts",data)


    var cartme = {id:packagedata.id,pincodeprice:pincodemprice,assistance:assistance,name:packagedata.package_name,customer_price:(price * differece) + parseInt(pincodemprice) ,items:data,date:startDate,enddate:endDate,packageprice:packagedata.customer_price,packageinclude:included}
    // setCart(cart.concat(cartme));
    // var dataset =[]
    console.log("local",localStorage.removeItem('cartdata'))
    // var dataset =[]
    // dataset.push(cartme)
    localStorage.setItem('cartdata',JSON.stringify(cartme))
    // console.log("local added",dataset)
 
}


// function handleChange (date) {
//     setDate(date)
//   }

function changePackage(data) {
    let newurl = window.location.href
    for(var i = newurl.length; i--;) {
        if(newurl.substr(newurl.length - 1) != '=') {
            console.log('working')
            newurl = newurl.substring(0, newurl.length - 1)
        }else{
            console.log(newurl)
            window.location.href = newurl + data
            window.location.reload();
            break;
        }
    }
 
}

function checkAvailability() {
    // console.log(pincode)
     var bodyFormData = new FormData()
     bodyFormData.set('pincode',pincode)
     apiRequest('checkPincodeAvail',bodyFormData)
     .then(function (response) {
         console.log("success",response)
        setPincodem(true)
        setPincodemprice(response.data.price)
        //  alert("available")
        Alert.success('available', {
            position: 'top-right',
            effect: 'bouncyflip',
            timeout: 3000
        });
     })
     .catch(error => {
         setPincode('')
        console.log(error.response)
        //  alert("not available")
        Alert.error('not available', {
            position: 'top-right',
            effect: 'bouncyflip',
            timeout: 3000
        });
     })
 }

 const onChange = e => {
    const { name, value } = e.target
    setPincode(value)
  }

const handleSelect =(range)=>{
    var Difference_In_Time = range.endDate._d.getTime() - range.startDate._d.getTime(); 
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24); 
    setDifference(Difference_In_Days.toFixed())
    setDays(Difference_In_Days.toFixed() * price)
    setstartDate(range.startDate.format("DD-MM-YYYY"))
    setendDate(range.endDate.format("DD=MM-YYYY"))
}



    return(
    <div id="container">
    <Header {...props} />
    <div className="detail">
        <div className="welcome_container">
        <div className="decor left"></div>
        <div className="decor right"></div>
        <div className="box">
            <div className="circle"></div>
            <div className="text">Welcome </div>
        </div>
    </div>
    <div className="header">SELECT YOUR <span>PACKAGE</span></div>
    <div className="body">
        <div className="taskbar">
            <div className="header"><div className="icon" onClick={()=> history.push('/rentals')}></div>PACKAGES</div>
            <div className="item_box">
            {packages.map((data,index)=>(
                <div key={index} className="item" onClick={()=>changePackage(data.id)}>{data.package_name}</div>
            ))}
            </div>
        </div>


        <div className="taskbar mob">
                            <div className="header" onClick={()=>setPagedown(!packagedown)}>PACKAGES<div className="icon"></div></div>
                            {packagedown ? 
                                <div className="item_box">
                                      {packages.map((data,index)=>(
                                        <div key={index} className="item" onClick={()=>changePackage(data.id)}>{data.package_name}</div>
                                    ))}
                                </div>
                                :
                                ''
                            }
        </div>


        <div className="data_box">
            <div className="top_section">
            <div className="package_details">
                                   
                    
            <div className="package_container">
                <div className="imagesetme" style={{backgroundImage:"url(" + data.baseurl + packagedata.feature_image + ")",backgroundSize:'cover',backgroundRepeat:'no-repeat'}} ></div>
                <div className="data_container">
                    <div className="top_section">
                        <div className="button">
                            <div className="icon"></div>
                            <div className="text">Upto {packagedata.people_count} People</div>
                        </div>
                        <div className="text">&#x20B9; {packagedata.customer_price}</div>
                    </div>
                    <div className="content">{packagedata.package_description}</div>

                   {calender&& <DateRange
                        onInit={handleSelect}
                        onChange={handleSelect}
                    />
                   }
                    <div style={{display:'flex',flexDirection:'row'}}>
                    <div style={{height:30,maxWidth:200,flex:1,backgroundColor:'#FF5722',marginTop:20,borderRadius:20,alignItems:'center',justifyContent:'center',display:'flex'}} onClick={()=>setCalender(!calender)}>
                        {calender ? <span style={{fontSize:12,color:'white'}}>submit</span> : <span style={{fontSize:12,color:'white'}}> {startDate} - {endDate}</span>}
                    </div>
                    <div style={{marginTop:25,marginLeft:20}}>
                        {differece} Day{differece > 1 && "(s)"}
                    </div>

                    </div>
                    {pincodem && 
                     <div style={{marginTop:15,marginLeft:20,color:'green'}}>
                        <img src={require('../../../assets/success.png')} style={{width:20,height:20}} />
                        <span style={{marginLeft:10}}>Delivery Available</span> 
                     </div>
                    }
                   
                    {pincodem ? 
                      <div style={{marginTop:15,marginLeft:20}}>
                         Shipping Charge : {pincodemprice}
                      </div>: null}
                    <div className="button_box">
                    
                        <input type="number" className="sq_button"  value={pincode} onChange={e => setUpPincode(e.target.value)}  placeholder="Type your pin code"  style={{borderRadius:20}}/>
                        <div className="rd_button" onClick={()=>checkAvailability()}>check availability</div>
                    </div>
                </div>
                
            </div>
            <div class="package_include_section">
                <div class="header">package includes</div>
                <div className="boxsu">
                    {included.map((item,index)=>
                           <div style={{display:'flex',flexDirection:'row',marginTop:5}}>
                                <div>
                                    <img src={data.baseurl+item.feature_image} style={{height:60,width:60}}/>
                                </div>
                                <div style={{marginLeft:8}}>
                                    <div><strong>{item.product_name}</strong></div>
                                    <div>QTY : {item.count}</div>
                                    <div class="text">{item.product_description}</div>
                                </div>
                           </div>
                    )}
                </div>
               
            </div>

            <div class="add_extra_products_container">
                               {extraproducts.length > 0 ? <div class="heading">add extra products</div> : null} 
                                <div class="data_box_container">

            {extraproducts.map((item,index)=>(

                    <div class="data_box" style={{height:120}}>
                        <div class="image" style={{backgroundImage:"url(" + data.baseurl + item.feature_image + ")",backgroundSize:'cover'}}>
                            <div style={{marginTop:0,marginLeft:0,color:'white',borderRadius:5,height:30,width:'auto',backgroundColor:'#FF5722',display:'flex',alignItems:'center',justifyContent:'center'}}>
                            ₹ {item.singleprice }
                            </div>
                        </div>
                        <div class="details_box">
                            <div class="heading_box">
                                <div class="content">
                                    <div class="main" style={{fontWeight:'bold'}}>{item.product_name}</div>
                                    <div class="sub">{item.product_description}</div>
                                </div>
                            </div>

                            <div class="varient_container">
                            <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',marginTop:10}}>
                                <div className="name">{item.bcname ? item.bcname : item.product_name} </div>
                                <div>
                                ₹ {item.customer_price}
                                </div>
                                <div className="" style={{flexDirection:'row',display:'flex'}}>
                                    <div className="">qty</div>
                                    <div className="mybtn" onClick={()=>minusProductCount(item.id)} >-</div>
                                    <div className="text">{item.count}</div>
                                    <div className="mybtn" onClick={()=>addProductCount(item.id)}>+</div>
                                </div>
                                
                            </div>
                        </div>

                        </div>
                    </div>
                    
                    ))}
                    </div>
                                  
                </div>
   
            </div>
                <div className="box_container">
                <div className="box">
                    <div className="header" style={{marginTop:20,marginBottom:20}}>Price Detail ({differece} Day{differece > 1 && "(s)"})</div>
                    {included.map((item,index)=>(
                        <div className="content">{index+1}. {item.product_name} X {item.count} </div>
                    ))}
                    <div className="content"> <strong>&#x20B9; {(price * differece) + parseInt(pincodemprice)}</strong></div>

                 
                       
                    <div className="button" onClick={(e)=>onCheckoutPress(e)}>proceed</div>
                                        
                </div>
                <div className="box second" style={{marginTop:20,display:'flex',alignItems:'center'}}>
                    <div className="icon" style={{backgroundImage:"url(" + require('../../../assets/engineerICON.svg') + ")",backgroundSize:'cover'}}></div>
                    <div className="container">
                        <div className="text" style={{textAlign:'center'}}>Need on-site assistance for &#x20B9;&nbsp;1000</div>
                        <input type="checkbox" className="checkbox" id="xyz" />
                        <label htmlFor="xyz" style={{textAlign:'center'}} onClick={()=>assistanceClicked()}>Yes</label>
                    </div>
            </div>
                </div>
            </div>
        </div>
    </div>
</div>
       
       
        <Footer />
        <Alert stack={{limit: 3}} timeout={3000}  />
        </div>
    )

}


function getAllPackagesbyId (themeid,setPackages,setPackageData,packageid,setProducts,setPrice) {
    console.log(themeid)
    var bodyFormData = new FormData()
    bodyFormData.set('page', 0)
    bodyFormData.set('limit', 10)
    bodyFormData.set('themeid',themeid)
    apiRequest('listPackagesbyThemeid',bodyFormData)
    .then(function (response) {
    
      setPackages(response.data)
      var single = response.data.filter(x => x.id === parseInt(packageid))[0]
      setPackageData(single)
      setPrice(single.customer_price)
      console.log('length categories',single)
        
    })
    .catch(error => {
        console.log(error.response)
    })
  
  }

 


export default App