import React from "react";
import { Route, Redirect } from "react-router-dom";
import auth from "./auth";

export const ProtectedRouteAdmin = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated() == 'admin' ) {
          return <Component {...props} />;
        } else {
          return (<Redirect to={{pathname: "/Login",state: {from: props.location}}}/>);
          
        }
      }}
    />
  );
};

export const ProtectedRouteUser = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (localStorage.getItem('role') == 'user' || localStorage.getItem('role') == 'vendor') {
          return <Component {...props} />;
        } else {
          return (<Redirect to={{pathname: "/Login",state: {from: props.location}}}/>);
          
        }
      }}
    />
  );
};

export const ProtectedRouteVendor = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated() == 'admin' || auth.isAuthenticated() == 'vendor' ) {
          return <Component {...props} />;
        } else {
          return (<Redirect to={{pathname: "/Login",state: {from: props.location}}}/>);
        }
      }}
    />
  );
};

export const AccessOnlyForUser = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated() == 'admin' || auth.isAuthenticated() == 'user' ) {
          return <Component {...props} />;
        } else {
          return (<Redirect to={{pathname: "/Login",state: {from: props.location}}}/>);
        }
      }}
    />
  );
};

export const ProtectedRouteVendorSection = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated() == 'user' ) {
          return <Component {...props} />;
        } else {
          return (<Redirect to={{pathname: "/",state: {from: props.location}}}/>);
        }
      }}
    />
  );
};

export const NoUser = ({
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isAuthenticated() != 'user' || auth.isAuthenticated() != 'vendor' ) {
          return <Component {...props} />;
        } else {
          return (<Redirect to={{pathname: "/",state: {from: props.location}}}/>);
        }
      }}
    />
  );
};


