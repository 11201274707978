
import React, { Component,useEffect,useState} from 'react'
import {Header, Footer} from '../../components'
import apiRequest from '../../apiRequest'



function App (props){

    useEffect(()=>{
       setTimeout(()=>{
            props.history.push('/profile')
       },2000)
     }, [])

      return(
          <div>
          {/* <Header {...props} /> */}
          <div class="about">
          {/* <div class="welcome_container">
              <div class="box">
                  <div class="text">Payment Success</div>
              </div>
          </div> */}
          </div>
          <div style={{marginTop:60,width:'60%',transform:'translate(30%,0%)',marginBottom:60}}>
           <div style={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
                <img src={require('../../assets/checked.png')} style={{width:150,height:150}}/> 
                <div style={{marginTop:15,fontSize:22,marginBottom:20}}>
                    Transaction successful
                </div>
                <div>
                    Redirecting to Your Orders ......
                </div>
           </div>
            </div>
          
          {/* <Footer /> */}
          </div>
      )
  }
  
  
  export default App