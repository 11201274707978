import React,{useState} from 'react'


function Collapse (props) {
    const [open,setOpen] = useState(false)

    function togglePanel(e) {
        setOpen(prev => !prev)
        // console.log(open)
    }

    return (
        <span>
          
            <tr class="content" onClick={(e)=>togglePanel(e)}>
              <td>{props.sno}</td>
              <td class="package-name-click">{props.theme}</td>
              <td>{props.include}</td>
              <td>{props.status}</td>
              <td class="action-box flex-row">
                <div class="button-round" style={{marginRight:20}} >add package</div>
                <div class="button-round" style={{marginRight:20}}>edit</div>
                <div class="button-round" style={{marginRight:20}}>delete</div>
              </td>
            </tr>
            
           
           
            {open ? (
            <div className="content">
            {props.children}
            </div>
            ) : null}
        </span>
    )
}

export default Collapse