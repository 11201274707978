import React, { Component,useEffect } from 'react'
import { Switch, Route,Router,HashRouter,Link} from 'react-router-dom'
import {Login,Rental,About,Contact,Cart,Profile,Vendor,Checkout, Landing} from './pages/frontend'
import DashboardRouter from './pages/admin/DashRouter'
import Response from './pages/frontend/Response'
// import Checkout1 from './pages/frontend/checkout'
import { ProtectedRouteAdmin,ProtectedRouteVendor,ProtectedRouteUser,ProtectedRouteVendorSection,AccessOnlyForUser,NoUser } from "./Protected";
import apiRequest from './apiRequest'
import CartContextProvider from './contexts/CartContext';
import CheckoutContextProvider from './contexts/CheckoutContext';
import  Packages  from './pages/frontend/rentals/Packages';
import Categories from './pages/frontend/categories/products'

import Payment from './pages/frontend/checkouts/Payment'
import Checkout1 from './pages/frontend/checkouts/checkOut1'
import Checkout2 from './pages/frontend/checkouts/checkOut2'
import Checkout3 from './pages/frontend/checkouts/checkout3'
import PaymentSuccess from './pages/frontend/paymentSucess'

const  App =()=> {

      useEffect(()=>{
        // console.log("token",localStorage.getItem('token'))
        localStorage.getItem('token') && updateUserdata()
    }, [])

    function updateUserdata () {
      console.log("getting user data")
      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      apiRequest('getMe',bodyFormData)
      .then(function (response) {
          // console.log("data",response.data.role)
          localStorage.setItem('role',response.data.role)
      })
      .catch(error => {
        console.log(error.response)
      })
    }
  
    return (
      <CartContextProvider>
      <CheckoutContextProvider>
      <HashRouter>
      <div>
        <Route exact path="/" component={Landing} />
        {/* <Link to="/search?q=react">Search</Link> */}
        <NoUser path="/login" component={Login} />
       
        <Route path="/rentals" component={Rental}/>
        <Route path="/packages" component={Packages}/>
        <Route path="/about" component={About}/>
        <Route path="/contact" component={Contact}/>
        <Route path="/categories" component={Categories}/>
        <Route path="/proof" component={Checkout1}/>
        <Route path="/address" component={Checkout2}/>
        <Route path="/checkout" component={Checkout3}/>
         <Route path="/paymentSuccess" component={PaymentSuccess}/>
        {/* <Route path="/paymentFailed" component={PaymentFailed}/>  */}
        {/* <Route path="/response" component={Response}/> */}
        <Route path="/vendor" component={Vendor}/>
        
        <ProtectedRouteUser path="/profile" component={Profile}/>
        <AccessOnlyForUser path="/payment" component={Payment}/>
        <ProtectedRouteAdmin path="/Dashboard" component={DashboardRouter} />
        <ProtectedRouteUser path="/Cart" component={Cart} />
      </div>
    </HashRouter>
    </CheckoutContextProvider>
    </CartContextProvider>
   
    );
  }

  export default App
