
import React, { Component,useState,useRef,useEffect } from 'react'
import {Header,Footer} from '../../components'
import axios from 'axios'
import auth from '../../auth'
import {data} from '../../constant'
import { withRouter } from "react-router-dom";
import Resizer from 'react-image-file-resizer'
import apiRequest from '../../apiRequest'
import Lottie from 'react-lottie'
import animationData from '../../lottie/clock.json'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';
var helper = require('sendgrid').mail;

const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


const App =(props)=>{
    useEffect(()=>{
        // sendMail()
        if (localStorage.getItem("role") == "user") {
            checkUserfromServer()
            setUser(true)
        }
        
  
     }, [])

    const [uploadview, setUploadview] = useState(false);
    const [proofs, setProofs] = useState({proof1:null,proof2:null});
    const [review, setReview] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [user,setUser] = useState(false)
    const [vendorname,setVendorname] = useState("")
    const [companyname,setCompanyname] = useState("")
    const [address,setAddress] = useState("")


    function fileChangedHandler (event,file) {
        var that = this
        var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                300,
                300,
                'PNG',
                50,
                0,
                uri => {
                    // console.log(uri)
                     proofImageUpload(uri,file)
                },
                'blob'
            );
          }
    
        }

        function checkUserfromServer() {
            var bodyFormData = new FormData();
            bodyFormData.set('token', localStorage.getItem('token'));
            apiRequest('getMe',bodyFormData)
            .then(function (response) {
                console.log("data",response.data)
                if(response.data.vendor == "pending") {
                    setReview(true)
                    // setUploadview(false)
                }else if(response.data.vendor == "rejected") {
                    setRejected(true)
                }else if(response.data.vendor == "success"){
                  props.history.push('/')
                }

            })
            .catch(error => {
              console.log(error.response)
            })
        } 

        function proofImageUpload (uriimage,file) {
            var bodyFormData = new FormData();
            bodyFormData.set('token', localStorage.getItem('token'));
            bodyFormData.set('singleImage', uriimage);
            apiRequest('uploadImage',bodyFormData)
            .then(function (response) {
                console.log("filetype",file)
                if(file) {
                    setProofs(prevState => ({ ...prevState, proof1: response.data }))
                }else{
                    setProofs(prevState => ({ ...prevState, proof2: response.data }))
                }
            })
            .catch(error => {
              console.log(error.response.data)
            })
          }

        function submitClick () {
           if(proofs.proof1 != null && proofs.proof2 != null) {
                updateToPending()
           }else{
               alert("please upload 2 proofs")
           }
        }

        function updateToPending() {
            console.log("mytoken",localStorage.getItem('token'))
            var bodyFormData = new FormData();
            bodyFormData.set('token', localStorage.getItem('token'));
            bodyFormData.set('vproof1', proofs.proof1);
            bodyFormData.set('vproof2', proofs.proof2);
            bodyFormData.set('cname', companyname);
            bodyFormData.set('address', address);
            apiRequest('vendorToPending',bodyFormData)
            .then(function (response) {
                setReview(true)
                // sendMail()
            })
            .catch(error => {
                console.log(error)
            })
        }

        function sendMail(){
            var fromEmail = new helper.Email('anas.melepeediakkal@gmail.com');
            var toEmail = new helper.Email('admin@lightandsound.com');
            var subject = 'Sending with SendGrid is Fun';
            var content = new helper.Content('text/plain', 'and easy to do anywhere, even with Node.js');
            var mail = new helper.Mail(fromEmail, subject, toEmail, content);
             
            var sg = require('sendgrid')('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');
            var request = sg.emptyRequest({
              method: 'POST',
              path: '/v3/mail/send',
              body: mail.toJSON()
            });
             
            sg.API(request, function (error, response) {
              if (error) {
                console.log('Error response received');
              }
              console.log(response.statusCode);
              console.log(response.body);
              console.log(response.headers);
            });
        }

        function reapplyClick () {
            setUploadview(true)
            setRejected(false)
        }

        function applyforVendor (){

            if (localStorage.getItem("role") == null) {
                // const r = window.confirm("Pleasse login first"); if(r == true){ props.history.push("/login") }
                Alert.error('Please Login First', {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 3000
                });
               
            }else{
                setUploadview(true)
            }
           
        }

        function ReloadPage() {
                window.location.reload();
        
        }
        function reuploadPress(){
            setProofs({proof1:null,proof2:null})
        }
          
    return(
        <div id="container">>
        <Header {...props} />
        <div class="login">
            <div class="flex_row">
                <div class="image2"></div>
                <div class="login_section">
  


            <div></div>

                     <div style={{display: uploadview || review || rejected ? 'none' : 'block'}}>
                       
                        <div class="button_container" onClick={()=>applyforVendor()}>
                           
                            <div class="button log">Apply for Vendor</div>
                        
                        </div>
                    </div>

                    <div style={{display:!rejected ? 'none' : 'block'}}>
                       
                       <div class="button_container" style={{display:'flex',alignItems:'center',flexDirection:'column'}} onClick={()=>reapplyClick()}>
                           <div >Your Application Rejected</div>
                           <div class="button log" style={{marginTop:30}}>Re-Apply for Vendor</div>
                       
                       </div>
                   </div>
{/* if review */}
                <div style={{display: review ? 'none' : 'block'}}>
                    
                    <div style={{display: !uploadview ? 'none' : 'block'}}>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                Upload your 2 valid proofs
                            </div>
                            <div style={{display:'flex',justifyContent:'space-around',marginTop:40}}>
                                <div class="inputbox-proof">
                                    {proofs.proof1 ? <img src={data.baseurl + proofs.proof1}  style={{width:70,height:80,borderRadius:8}}/> : 
                                        <div>
                                            <button class="btnproof">Address Proof</button>
                                            <input type="file"  accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e,true)} />
                                        </div>
                                    }
                                   
                                </div>
                                <div class="inputbox-proof">
                                    {proofs.proof2 ?   <img src={data.baseurl + proofs.proof2}  style={{width:70,height:80,borderRadius:8}}/> :
                                       <div>
                                            <button class="btnproof">ID Proof</button>
                                            <input type="file"  accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e,false)} />
                                        </div>
                                    }
                                </div>
                            </div>
                            <div class="button_container" style={{marginTop:30}}>
                                <div class="button log" onClick={()=>reuploadPress()}>Reupload</div>
                            </div>
                            <div style={{display:'flex',justifyContent:'center'}}>
                                    {/* <input type="number" value="sdf"  placeholder="Enter otp"/>  */}
                            <div class="input_box" style={{marginTop:20}}>
                                {/* <input type="text" value={vendorname} onChange={e => setVendorname(e.target.value)} placeholder="Vendor name"/>  */}
                                <input type="text"value={companyname} onChange={e => setCompanyname(e.target.value)} placeholder="Company name"/> 
                                <textarea name="Address" value={address} onChange={e => setAddress(e.target.value)} cols="40" rows="5" placeholder="Address" style={{marginTop:10,borderRadius:20,fontSize:16,paddingLeft:10,paddingTop:10}} > </textarea>
                            </div>
                            </div>
                            <div class="button_container" style={{marginTop:30}}>
                                <div class="button log" onClick={()=>submitClick()}>submit for review</div>
                            </div>
                    </div>

                </div>

                <div style={{display:review ? 'block' : 'none'}}>
                    <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
                        <Lottie options={defaultOptions}
                            height={150}
                            width={150}
                        />
                        <div style={{display:'flex',justifyContent:'center',marginTop:30}}>
                            your review is under processing
                        </div>
                        <div class="button_container" style={{marginTop:30}}>
                                <div class="button log" onClick={()=>ReloadPage()}>Reload</div>
                        </div>
                    </div>
                </div>
                   
                </div>
            </div>
        </div>
        <Footer />
        <Alert stack={{limit: 3}} timeout={3000}  />
        </div>
    )
}


export default App