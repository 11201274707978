import React from 'react';
import ReactDOM from 'react-dom';
import App from './Router';
import * as serviceWorker from './serviceWorker';
import * as firebase from 'firebase'
import Alert from 'react-s-alert';

import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

const config = {
  apiKey: "AIzaSyDkelmh9tHvcKpxMIc_5xDCD7KmStT4eBs",
  authDomain: "hellocheck.in",
  databaseURL: "https://lightandsound-1d525.firebaseio.com",
  projectId: "lightandsound-1d525",
  storageBucket: "lightandsound-1d525.appspot.com",
  messagingSenderId: "835683117232",
  appId: "1:835683117232:web:f01e12c63d670d0dc13375",
  measurementId: "G-ZE27EWZSLH"
  }
 firebase.initializeApp(config)

 

ReactDOM.render(<App />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
