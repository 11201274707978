import axios from 'axios'
import {data} from './constant'

export default async (url, bodyFormData) => {
    return await  axios({
        method: 'post',
        url: data.baseurl + url,
        data: bodyFormData,
        config: { headers: {'Content-Type': 'multipart/form-data','Access-Control-Allow-Origin' : '*'}}
        })
}

