import React,{useState,useEffect} from 'react'
import {Header, Footer} from '../../../components'
import Resizer from 'react-image-file-resizer'
import apiRequest from '../../../apiRequest'
import {data} from '../../../constant'
import Alert from 'react-s-alert'
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/bouncyflip.css';

function App (props) {

    // const initialAddress = {bfname:'',bmob:'',bcode:'',baddress:'',bland:'',bstate:''}
    const initialAddress = {fullname:'',mobile:'',pincode:'',address:'',landmark:'',state:''}
    const [SuserAddress,setSUserAdress] = useState(initialAddress)
    const [userAddress,setUserAdress] = useState(initialAddress)
    const [box,setBox] = useState(false)
    const [loader,setLoader] = useState(false)

    const onChange = e => {
        const { name, value } = e.target;
        setUserAdress(prevState => ({ ...prevState, [name]: value }));
      }
      const onChanges = e => {
        const { name, value } = e.target;
        setSUserAdress(prevState => ({ ...prevState, [name]: value }));
      }

    useEffect(() => {
        !localStorage.getItem('cartdata') &&( window.location.href = data.fronturl + '/#/rentals' )
        updateUserData()
    }, [])

    const updateUserData=()=>{
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        apiRequest('getMe',bodyFormData)
        .then(function (response) {
            console.log(response.data)
            if (response.data.address != null) {
                setUserAdress(JSON.parse(response.data.address))
            }
        })
        .catch(error => {
          console.log(error.response)
        })
    }

    const onNextClick =()=>{
        setLoader(true)
       validateuserdata()
    // console.log(userAddress.fname.length)
    }

    function validateuserdata(){
        console.log(userAddress.bfname)
        if(userAddress.fullname == undefined || userAddress.fullname < 1){
            Alert.error('Please enter First Name', {
                position: 'top-right',
                effect: 'bouncyflip',
                timeout: 1000
            }); 
            setLoader(false)
        }else if(userAddress.mobile.toString().length < 10 || userAddress.mobile.toString() == undefined) {
            Alert.error('Please enter your Mobile number', {
                position: 'top-right',
                effect: 'bouncyflip',
                timeout: 1000
            }); 
            setLoader(false) 
        }
        else if(userAddress.pincode.length<1 || userAddress.pincode == undefined){
            Alert.error('Please enter your pincode', {
                position: 'top-right',
                effect: 'bouncyflip',
                timeout: 1000
            });  
            setLoader(false)
        } else if(userAddress.address.length<1 || userAddress.address == undefined){
            Alert.error('Please enter your town', {
                position: 'top-right',
                effect: 'bouncyflip',
                timeout: 1000
            });  
            setLoader(false)
        }else if(!box){
            if(SuserAddress.fullname.length<1 || userAddress.fullname == undefined ){
                Alert.error('Please enter Shipping Name', {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 1000
                }); 
                setLoader(false)
            }else if(SuserAddress.mobile.toString().length < 10 || userAddress.mobile.toString() == undefined ){
                Alert.error('Please enter your  shipping Mobile number', {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 1000
                });  
                setLoader(false)
            }
            else if(SuserAddress.pincode.length<1 || userAddress.pincode == undefined ){
                Alert.error('Please enter your shipping pincode', {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 1000
                }); 
                setLoader(false) 
            } else if(SuserAddress.address.length<1 || userAddress.address == undefined) {
                Alert.error('Please enter your shipping address', {
                    position: 'top-right',
                    effect: 'bouncyflip',
                    timeout: 1000
                }); 
                setLoader(false) 
            }else{
                updateUserAddress()
             }
        }
        else{
           updateUserAddress()
        }
    }

    const updateUserAddress =()=>{
        // console.log('working')
        // let data = JSON.parse(localStorage.getItem('cartdata'))
        //     console.log("cartdata",data)
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('address', JSON.stringify(userAddress));
        apiRequest('updateUserAddress',bodyFormData)
        .then(function (response) {
            setLoader(false)
            localStorage.setItem('address',box ? JSON.stringify(userAddress) : JSON.stringify(SuserAddress))

            console.log("data",JSON.stringify(userAddress))
            props.history.push('/checkout')
        })
        .catch(error => {
            Alert.error(error.data, {
                position: 'top-right',
                effect: 'bouncyflip',
                timeout: 1000
            }); 
        })
    }

    function onBoxClick () {

       setBox(!box)
       if(!box){
           userAddress.state="Kerala"
        SuserAddress.fullname=userAddress.fullname;
        SuserAddress.mobile=userAddress.mobile;
        SuserAddress.pincode=userAddress.pincode;
        SuserAddress.address=userAddress.address;
        SuserAddress.landmark=userAddress.landmark;
        SuserAddress.state=userAddress.state;
       }else{
        SuserAddress.fullname="";
        SuserAddress.mobile="";
        SuserAddress.pincode="";
        SuserAddress.address="";
        SuserAddress.landmark="";
        SuserAddress.state="";
       }
       
    }
    return (
        <>
        <Header  {...props}/>
        <div>
            <div>
            </div>
        <div class="checkout2">
            <div class="welcome_container">
                <div class="decor left"></div>
                <div class="decor right"></div>
                <div class="box">
                    <div class="circle"></div>
                    <div class="text">Welcome Username</div>
                </div>
            </div>
            <div class="checkout_container">
                <div class="text">CHECKOUT</div>
                <div class="circle_container">
                    <div class="circle _1">1</div>
                    <div class="circle _2">2</div>
                    <div class="circle _3">3</div>
                </div>
            </div>
            <div class="payment_container">
                <div class="text" >ADD&nbsp;YOUR&nbsp;ADDRESS</div>
                <div class="address_box">
                    <div class="left_section">
                    <span style={{fontSize:18,fontWeight:'bold',marginBottom:8}}>BILLING ADDRESS</span> 
                        <div class="input_box" >
                            <div class="text">Full name* :</div>
                            <input type="text" class="input" value={userAddress.fullname} onChange={onChange} name="fullname"/>
                        </div>
                        <div class="input_box">
                            <div class="text">Mobile number* :</div>
                            <input type="number"   class="input" value={userAddress.mobile} onChange={onChange} name="mobile"/>
                        </div>
                        <div class="input_box">
                            <div class="text">Pincode* :</div>
                            <input type="number"  class="input" value={userAddress.pincode} onChange={onChange} name="pincode"/>
                        </div>
                        <div class="input_box">
                            <div class="text">Address :</div>
                            <input type="text"  class="input" value={userAddress.address} onChange={onChange} name="address"/>
                        </div>
                        <div class="input_box">
                            <div class="text">Landmark :</div>
                            <input type="text"   class="input" value={userAddress.landmark} onChange={onChange} name="landmark"/>
                        </div>
                        <div class="input_box">
                            <div class="text">State :</div>
                            <input type="text"   class="input" value="Kerala" onChange={onChange} name="state"/>
                        </div>
                    </div>
                    <div class="left_section">
                                <div style={{marginBottom:8}}>
                                    <input  id={238} type="checkbox" defaultChecked={box} className="checkbox" onClick={()=>onBoxClick()} />
                                    <label htmlFor={238}>Same as Billing Address</label>    
                                </div>
                       <span style={{fontSize:18,fontWeight:'bold',marginBottom:8}}>SHIPPING ADDRESS</span> 
                    <div class="input_box" >
                            <div class="text">Full name* :</div>
                            <input type="text" class="input" value={SuserAddress.fullname} onChange={onChanges} name="fullname"/>
                        </div>
                        <div class="input_box">
                            <div class="text">Mobile number* :</div>
                            <input type="number"   class="input" value={SuserAddress.mobile} onChange={onChanges} name="mobile"/>
                        </div>
                        <div class="input_box">
                            <div class="text">Pincode* :</div>
                            <input type="number"  class="input" value={SuserAddress.pincode} onChange={onChanges} name="pincode"/>
                        </div>
                        <div class="input_box">
                            <div class="text">Address :</div>
                            <input type="text"  class="input" value={SuserAddress.address} onChange={onChanges} name="address"/>
                        </div>
                        <div class="input_box">
                            <div class="text">Landmark :</div>
                            <input type="text"   class="input" value={SuserAddress.landmark} onChange={onChanges} name="landmark"/>
                        </div>
                        <div class="input_box">
                            <div class="text">State :</div>
                            <input type="text"   class="input" value={SuserAddress.state} onChange={onChanges} name="state"/>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div class="buy_button_container" style={{cursor:'pointer'}} onClick={()=>onNextClick()}>
                <div class="button">NEXT</div>
            </div> */}
            <div class="buy_button_container"  style={{cursor:'pointer'}}  disabled={loader} onClick={()=>onNextClick()}>
                    <div class="button">{loader ? <img src={require("../../../assets/rolling.svg")} style={{width:20,height:20}}/> : "NEXT" }</div>
             </div>
        </div>
        <Alert stack={{limit: 3}} timeout={3000}  />
        </div>
        <Footer />
        </>
    )
}

export default App