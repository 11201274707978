import React, { Component,useEffect,useState } from 'react'
import apiRequest from '../../apiRequest'
import Modal from 'react-modal';
import Resizer from 'react-image-file-resizer'
import './App.scss'
import {data} from '../../constant'


const customStyles = {
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      marginRight           : '-50%',
      transform             : 'translate(-50%, -50%)'
    }
  };


const  App = () =>{

    const [categories,setCategories] = useState([])
    const [modal,setModal] = useState(false)
    const [editmodal,setEditModal] = useState(false)
    const initialStatevstData = {name:'',image:'',id:''}
    const [catdata,setCatdata] = useState(initialStatevstData)
    const [catEditdata,setCatEditdata] = useState(initialStatevstData)

    useEffect(()=>{
        getCategories(setCategories)
     }, [])

     const onChange = e => {
        const { name, value } = e.target;
        setCatdata(prevState => ({ ...prevState, [name]: value }));
      };
      const onChangeedit = e => {
        const { name, value } = e.target;
        setCatEditdata(prevState => ({ ...prevState, [name]: value }));
      };

      function fileChangedHandler (event) {
        var that = this
        var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                300,
                300,
                'PNG',
                50,
                0,
                uri => {
                    console.log(uri)
                    productImageUpload(setCatdata,uri,setCatEditdata)
                },
                'blob'
            );
          }
    
        }

    function checkAddData () {
        if(catdata.name.length > 0 && catdata.image.length > 0) {
            addtoServer()
        }else{
            alert("Please fill required fields")
        }
    }
    function addtoServer() {
        console.log(catdata)
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('name', catdata.name);
        bodyFormData.set('image', catdata.image);
        apiRequest('addCategory',bodyFormData)
        .then(function (response) {
            setModal(false)
            setCatdata(initialStatevstData)
            getCategories(setCategories)
        })
        .catch(error => {
            
            alert(error.response.data)
        })
     
    }

    function checkEditData () {
        console.log(catEditdata.id)
        if(catEditdata.name.length > 0 && catEditdata.image.length > 0) {

            var bodyFormData = new FormData();
            bodyFormData.set('token', localStorage.getItem('token'));
            bodyFormData.set('id', catEditdata.id);
            bodyFormData.set('name', catEditdata.name);
            bodyFormData.set('image', catEditdata.image);
            apiRequest('updateCategory',bodyFormData)
            .then(function (response) {
                setEditModal(false)
                setCatEditdata(initialStatevstData)
                getCategories(setCategories)
            })

            .catch(error => {
                
                alert(error.response.data)
            })
        }else{
            alert("Please fill required fields")
        }
    }

    function deleteCategory(item) {
        var confirmbox = window.confirm("confirm delete category!");
        if(confirmbox == true) {
            var bodyFormData = new FormData();
            bodyFormData.set('token', localStorage.getItem('token'));
            bodyFormData.set('id', item);
            apiRequest('deleteCategory',bodyFormData)
            .then(function (response) {
                getCategories(setCategories)
            })
            .catch(error => {
                alert(error.response.data)
            })
        }
      
    }

    function editButtonPress(item) {
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('id', item);
        apiRequest('getCategory',bodyFormData)
        .then(function (response) {
            setEditModal(true)
            // console.log(response.data)
            let res = response.data
       
            setCatEditdata({
              name:res.name,
              image:res.image,
              id:res.id
            })
        })
        .catch(error => {
            console.log(error.response.data)
        })
    }

    function modalPress() {
        setModal(true)
        setCatdata(initialStatevstData)
    }

   return(
    <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">Categories</div>
            <div class="button-round" onClick={()=>modalPress()}>add category</div>
          </div>

          {categories.map((item)=>(

          <div class="user-list-container">
              <div class="name-section">
                  <div class="icon">
                  <img alt="" src={data.baseurl + item.image} style={{width:28,height:28,borderRadius:28/2}} ></img>
                  </div>
                  <div class="text">{item.name}</div>
              </div>
              <div class="action-container">
                  <div class="button button-round" onClick={()=>editButtonPress(item.id)} >edit</div>
                  <div class="button button-round" onClick={()=>deleteCategory(item.id)}>delete</div>
              </div>
            </div>
          ))}
{/* add category */}
<Modal
          isOpen={modal}
          onRequestClose={()=>onRequestCloseModal(setModal,setCategories,initialStatevstData)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Add category</div>
                    <div className="button-round" onClick={()=>onRequestCloseModal(setModal,setCategories,initialStatevstData)}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={catdata.name} onChange={onChange} name="name" className="input_text" type="text" />
                    </div>
                </div>


                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">image :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                              catdata.image 
                            ?  
                            data.baseurl + catdata.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                 </div>

                 <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end'}}>
                        <div className="button-round" onClick={()=>checkAddData()}>Add Category</div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>

{/* edit category */}
<Modal
          isOpen={editmodal}
          onRequestClose={()=>onRequestCloseEditModal(setEditModal,setCategories,initialStatevstData)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Edit category</div>
                    <div className="button-round" onClick={()=>onRequestCloseEditModal(setEditModal,setCategories,initialStatevstData)}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={catEditdata.name} onChange={onChangeedit} name="name" className="input_text" type="text" />
                    </div>
                </div>


                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">image :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                                catEditdata.image 
                            ?  
                            data.baseurl + catEditdata.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                 </div>

                 <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end'}}>
                        <div className="button-round" onClick={()=>checkEditData(catEditdata.id)}>update Category</div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>
        </div>
      </div>
   )
}

function getCategories(setCategories) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('page', 0);
    bodyFormData.set('limit', 200);
    apiRequest('listCategories',bodyFormData)
    .then(function (response) {
        console.log(response.data)
        setCategories(response.data)
    })
    .catch(error => {
        console.log(error.response.data)
    })
}

function productImageUpload (setCategories,uriimage,setCatEditdata) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('singleImage', uriimage);
    apiRequest('uploadImage',bodyFormData)
    .then(function (response) {
        setCategories(prevState => ({ ...prevState, image: response.data }));
        setCatEditdata(prevState => ({ ...prevState, image: response.data }));
    })
    .catch(error => {
      console.log(error)
    })
  }
  
function onRequestCloseModal (setModal,setCatdata,initial) {
    setModal(false)
    // setCatdata(initial)
}

function onRequestCloseEditModal (setEditModal) {
    setEditModal(false)
   
}



export default App