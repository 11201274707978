import React, { Component,useEffect } from 'react'
import './App.scss';
import { Link, Route,withRouter } from 'react-router-dom'

import {Vendors,Products,Banners,Packages,Orders,Verification,Users,Categories,Brands,VendorOrders,AvailablePincodes,Enquries, Themes,Quotations} from './'
import auth from '../../auth'
import Analytics from '../admin/Analytics'
const DashboardRouter = ({ match,history }) => {      
    useEffect(()=>{
        console.log("sdfsdfsdf")
     }, [])
    return (
      <div>
      <nav class="flex-row-center top-nav-bar">
      <div class="flex-row-center logo-container">
        <div class="logo bg" style={{backgroundImage: "url(" + require('../../assets/log.png') + ")",width:35}}></div>
        <div class="logo-text">Light and Sound</div>
      </div>
      <Link to={'/login'} style={{textDecoration:'none'}}>
        <div class="button-round" onClick={()=>auth.logout()}>LOGOUT</div>
      </Link>
    </nav>
  
    <div class="body-section flex-row-center">
      <div class="taskbar flex-col">

      <MenuLink activeOnlyWhenExact={true} to={`${match.url}`} label="Dashboard" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/users`} label="Users" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/vendors`} label="Vendors" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/products`} label="Products" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/brands`} label="Brands" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/categories`} label="Categories" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/themes`} label="Themes" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/packages`} label="Packages" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/orders`} label="Customer Orders" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/vendororders`} label="Vendor Orders" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/banners`} label="Banner" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/verification`} label="Vendor Verification" />
      {/* <MenuLink activeOnlyWhenExact={true} to={`${match.url}/userverification`} label="Order Verification" /> */}
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/availablelocations`} label="Available Pincodes" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/enquries`} label="Enquiries" />
      <MenuLink activeOnlyWhenExact={true} to={`${match.url}/quotations`} label="Quotations" />
      </div>
    
      <Route exact path={match.url} component={Analytics}/>
      <Route path={`${match.url}/users`} component={Users}/>
      <Route path={`${match.url}/vendors`} component={Vendors}/>
      <Route path={`${match.url}/products`} component={Products}/>
      <Route path={`${match.url}/brands`} component={Brands}/>
      <Route path={`${match.url}/categories`} component={Categories}/>
      <Route path={`${match.url}/themes`} component={Themes}/>
      <Route path={`${match.url}/packages`} component={Packages}/>
      <Route path={`${match.url}/orders`} component={Orders}/>
      <Route path={`${match.url}/banners`} component={Banners}/>
      <Route path={`${match.url}/verification`} component={Verification}/>
      <Route path={`${match.url}/vendororders`} component={VendorOrders} />
      {/* <Route path={`${match.url}/userverification`} component={Userverification}/> */}
      <Route path={`${match.url}/availablelocations`} component={AvailablePincodes}/>
      <Route path={`${match.url}/enquries`} component={Enquries}/>
      <Route path={`${match.url}/quotations`} component={Quotations}/>
    </div>
    </div>
    )
  };

  function MenuLink({ label, to, activeOnlyWhenExact }) {
    return (
      <Route
        path={to}
        exact={activeOnlyWhenExact}
        children={({ match }) => (
            <Link to={to} class="flex-row item" style={match ? {textDecoration:'none',color:'black',backgroundColor:'#eaeaea'} : {textDecoration:'none',color:'black'}}>
                  <Iconset label={label} match={match}/>
                  <div class="text">{label}</div>
              </Link>
        )}
      />
    );
  }
  
  function Iconset({ label ,match}) {
    switch(label) {
      case 'Dashboard':
        return <img class="icon bg" src={require('../../backassets/dashboard.svg')} ></img> ;
      case 'Users':
        return <img class="icon bg" src={require('../../backassets/avatar.svg')}></img>;
      case 'Vendors':
        return <img class="icon bg" src={require('../../backassets/avatar.svg')}></img>;
      case 'Products':
        return <img class="icon bg" src={require('../../backassets/mic.svg')}></img>;
      case 'Packages':
        return <img class="icon bg" src={require('../../backassets/gift.svg')}></img>;
      case 'Orders':
        return <img class="icon bg" src={require('../../backassets/truck.svg')}></img>;
      case 'Vendor Verification':
        return <img class="icon bg" src={require('../../backassets/verification.svg')}></img>;
      default:
        return <img class="icon bg" src={require('../../backassets/dashboard.svg')} ></img> ;
    }
  }
  export default DashboardRouter

