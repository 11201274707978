import React, { Component,createContext,useState,useEffect } from 'react'

export const CartContext = createContext();

function CartContextProvider (props) {


  useEffect(()=>{
    localStorage.setItem('cartdata',[])

    if(localStorage.getItem('cartdata').length > 0)  {
        setCart(JSON.parse(localStorage.getItem('cartdata')))
        console.log("this my data",localStorage.getItem('cartdata'))
    }

 }, [])

    const intial =[]
    const [cart, setCart] = useState(intial);
    const [productsset, setProductsset] = useState(intial);

    return(
            <CartContext.Provider value={{cart,setCart,productsset,setProductsset}}>
                {props.children}
            </CartContext.Provider>
    )
}
export default CartContextProvider