import React, { useEffect, useState, useCallback } from 'react'
import apiRequest from '../../apiRequest'
import Modal from 'react-modal';
import Resizer from 'react-image-file-resizer'
import './App.scss'
import { data } from '../../constant'
import moment from 'moment';


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};


const App = () => {

  const [quotatios, setQuotations] = useState([])
  const [, updateState] = React.useState();
  const forceUpdate = useCallback(() => updateState({}), []);

  useEffect(() => {
    getQuotations(setQuotations)
  }, [])

  function OnPendingButtonClick(id){
    // alert(id)

    // quotatios.map((item)=>{
    //     if(item.id === id) {
    //        if(item.mystatus === 'pending') {
    //           item.mystatus = 'done'
    //         }else{
    //           item.mystatus = 'pending'
    //         }
    //       // console.log(item)
    //     }
    // })
    if(window.confirm("Are you sure?"))
    updateServerPending(id)
   
    // forceUpdate()
    console.log(quotatios)
    
  }

  function updateServerPending(id){
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('quoteid',id );
    apiRequest('updateQuotationStatus',bodyFormData)
    .then(function (response) {
      console.log("success")
      getQuotations(setQuotations,forceUpdate)
      // forceUpdate()
    
    })
    .catch(error => {
        console.log(error.response)
    })
  }
  
  return (
    <div class="body-container">
      <div class="table-container flex-col">
        <div class="header-container flex-row">
          <div class="text">Quotations</div>
        </div>
        <table class="main-table">
          <tr class="heading">
            <td>ID</td>
            <td>Name</td>
            <td>Email</td>
            <td>Phone</td>
            <td>Requirement</td>
            <td>Place</td>
            <td>Date</td>
            <td>Status</td>
            <td></td>
          </tr>

          {quotatios.map((item) => (
            <tr class="content">
              <td>{item.id}</td>
              <td class="package-name-click">{item.name}</td>
              <td class="package-name-click">{item.email}</td>
              <td class="package-name-click">{item.phone}</td>
              <td class="package-name-click">{item.requirements}</td>
              <td class="package-name-click">{item.place}</td>
          <td class="package-name-click">{moment(item.created).utc().format('DD-MM-YYYY')}</td>
              <td>
                <div class="button-round" onClick={() => OnPendingButtonClick(item.id)}>{item.status == 'pending' ? 'pending' : 'done'}</div>
              </td>
              {/* <td class="action-box flex-row">
                <div class="button-round">delete</div>
              </td> */}
            </tr>
          ))}
        </table>
      </div>
    </div>
  )
}


function getQuotations(setQuotations,forceUpdate) {
  var bodyFormData = new FormData();
  bodyFormData.set('page', 0);
  bodyFormData.set('limit', 200);
  apiRequest('listQuotation', bodyFormData)
    .then(function (response) {
      let quotatios = response.data
      // quotatios.map((item) => {
      //   item.mystatus = "pending"
      // })

      setQuotations(response.data)
      forceUpdate()
      console.log(response.data)
    })
    .catch(error => {
      console.log(error.response)
    })
}


export default App