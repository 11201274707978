import React, { useEffect, useState, useReducer } from 'react'
import apiRequest from '../../apiRequest'
import Modal from 'react-modal';
import './style.scss'
import { data } from '../../constant'
import Resizer from 'react-image-file-resizer'
import _ from 'lodash'

const KeyCodes = {
  comma: 188,
  enter: 13,
};



const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    height: '500px',
    overlfow: 'scroll'
  }
};

const App = () => {
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [products, setProducts] = useState([])
  const [modal, setModal] = useState(false)
  const initialStateProductAdd = { name: '', description: '',bcname:'', vendorprice: '', customerprice: '', stock: '0', image: '', categories: [], productimages: [], categoriesArray: [], id: '', brand: '' }
  const [productdata, setProductdata] = useState(initialStateProductAdd)
  const [productImages, setProductImages] = useState([])
  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [editModal, setEditModal] = useState(false)
  const [viewModal, setViewModal] = useState(false)

  const onChangeProductAdd = e => {
    const { name, value } = e.target;
    setProductdata(prevState => ({ ...prevState, [name]: value }));
  };


  function deleteProduct(item) {
    var confirmbox = window.confirm("confirm delete product!");
    if (confirmbox == true) {
      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      bodyFormData.set('id', item);
      apiRequest('deleteProduct', bodyFormData)
        .then(function (response) {
          getProducts(setProducts)
        })
        .catch(error => {
          alert(error.response.data)
        })
    }

  }

  function editSingleProduct(item) {
    setEditModal(true)
    getSingleProduct(item)

  }


  function viewSingleProduct(item) {
    setViewModal(true)
    getSingleProduct(item)

  }
  function getSingleProduct(item) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('id', item);

    apiRequest('getProduct', bodyFormData)
      .then(function (response) {
          console.log('recived item',response.data)
        // setData(response.data)
        let datacat = []
        let dataitem = response.data
        dataitem.categories.map((single) => {
          categories.map((catitem) => {
            if (single.id == catitem.id) {
              catitem.checked = true
              // console.log("matached",categories)
            }


          })

        })

        console.log("new list", categories)
        setProductdata(prevState => ({
          ...prevState,
          name: dataitem.product_name,
          brand:dataitem.brand,
          bcname:dataitem.bcname,
          description: dataitem.product_description,
          vendorprice: dataitem.vendor_price,
          stock: dataitem.stock,
          customerprice: dataitem.customer_price,
          image: dataitem.feature_image,
          // categories:dataitem.categories,
          id: dataitem.id
        }));
      })
      .catch(error => {
        console.log(error)
      })
  }

  useEffect(() => {
    getProducts(setProducts)
    listAllCategories(setCategories)
    getBrands(setBrands)
  }, [])

  function fileChangedHandler(event) {
    var that = this
    var fileInput = false
    if (event.target.files[0]) {
      fileInput = true
    }
    if (fileInput) {
      Resizer.imageFileResizer(
        event.target.files[0],
        300,
        300,
        'PNG',
        50,
        0,
        uri => {
          console.log(uri)
          productImageUpload(setProductdata, uri)
        },
        'blob'
      );
    }

  }

  function productAddToServer() {
    if (productdata.name.length > 0 && productdata.bcname.length > 0&& productdata.vendorprice.length > 0 && productdata.customerprice.length > 0 && productdata.image.length > 0 && productdata.categories.length > 0 && productdata.brand > 0) {
      AddToServer(productdata)
    } else {
      alert('please fill required fields')
    }
  }

  function AddToServer() {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('name', productdata.name);
    bodyFormData.set('bcname', productdata.bcname);
    bodyFormData.set('brand', productdata.brand);
    bodyFormData.set('description', productdata.description);
    bodyFormData.set('stock', productdata.stock);
    bodyFormData.set('vendorprice', productdata.vendorprice);
    bodyFormData.set('customerprice', productdata.customerprice);
    bodyFormData.set('image', productdata.image);
    bodyFormData.set('categories', JSON.stringify(productdata.categories.map(String)));
    bodyFormData.set('productImages', JSON.stringify(productdata.productImages));

    apiRequest('addProduct', bodyFormData)
      .then(function (response) {
        // console.log(response.data)
        setModal(false)
        getProducts(setProducts)
        setProductdata(initialStateProductAdd)
      })
      .catch(error => {
        console.log(error)
      })
  }
  function addButtonPress() {
    setModal(true)
  }


  function updateToServer() {
    console.log('product data',productdata)
    let categorylist = []
    categories.map((item) => {
      if (item.checked == true) {
        categorylist.push(item.id)
      }
    })
    if (productdata.name.length > 0 && productdata.bcname.length > 0 && productdata.vendorprice > 0 && productdata.customerprice > 0 && productdata.image.length > 0 && categorylist.length > 0) {

      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      bodyFormData.set('id', productdata.id);
      bodyFormData.set('name', productdata.name);
      bodyFormData.set('brand', productdata.brand);
      bodyFormData.set('bcname', productdata.bcname);
      bodyFormData.set('description', productdata.description);
      bodyFormData.set('vendorprice', productdata.vendorprice);
      bodyFormData.set('stock', productdata.stock);
      bodyFormData.set('customerprice', productdata.customerprice);
      bodyFormData.set('image', productdata.image);
      bodyFormData.set('categories', JSON.stringify(categorylist.map(String)));
      // bodyFormData.set('brands', JSON.stringify(brandslist.map(String)));
      bodyFormData.set('productImages', JSON.stringify(productdata.productImages));
      console.log("arraylist", productdata.categories)
      apiRequest('updateProduct', bodyFormData)
        .then(function (response) {
          // console.log(response.data)
          setEditModal(false)
          getProducts(setProducts)
          setProductdata(initialStateProductAdd)
          listAllCategories(setCategories)

        })
        .catch(error => {
          console.log(error)
        })
    } else {
      alert('please fill required fields')
    }
  }

  function categorySelector(id) {
    categories.map((item) => {
      if (item.id == id) {
        item.checked = !item.checked
      }
    })
    forceUpdate()
  }

  return (
    <div class="body-container">
      <div class="table-container flex-col">
        <div class="header-container flex-row">
          <div class="text">Products</div>
          <div class="button-round" onClick={() => addButtonPress()}>add product</div>
        </div>

        {products.map((item) => (
          <div class="user-list-container">
            <div class="name-section">
              <div class="icon">
                <img alt="" src={data.baseurl + item.feature_image} style={{ width: 28, height: 28, borderRadius: 28 / 2 }} ></img>
              </div>
              <div class="text">{item.product_name}</div>
            </div>
            <div class="action-container">
              <div class="button button-round" onClick={() => viewSingleProduct(item.id)}>view</div>
              <div class="button button-round" onClick={() => editSingleProduct(item.id)}>edit</div>
              <div class="button button-round" onClick={() => deleteProduct(item.id)}>delete</div>
            </div>
          </div>
        ))}

        {/* add product */}
        <Modal
          isOpen={modal}
          onRequestClose={() => onRequestCloseModal(setModal, setProductdata, initialStateProductAdd)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div style={{ width: 500 }}>

            <div className="modalmain" style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
              <div style={{ fontWeight: 'bold' }}>Add product</div>
              <div className="button-round" onClick={() => onRequestCloseModal(setModal, setProductdata, initialStateProductAdd)}>cancel</div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 60 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">Name :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.name} onChange={onChangeProductAdd} name="name" className="input_text" type="text" />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 60 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">B-C Name :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.bcname} onChange={onChangeProductAdd} name="bcname" className="input_text" type="text" />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                  <div className="text">Description :</div>
              </div>
              <div style={{ flex: 1 }}>
              <textarea value={productdata.description} onChange={onChangeProductAdd} name="description" className="input_text" type="text"  cols="20" rows="5"></textarea>
                {/* <input value={productdata.description} onChange={onChangeProductAdd} name="description" className="input_text" type="text" /> */}
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">stock :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.stock} onChange={onChangeProductAdd} name="stock" className="input_text" type="number" />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">vendorprice :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.vendorprice} onChange={onChangeProductAdd} name="vendorprice" className="input_text" type="number" />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">customerprice :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.customerprice} onChange={onChangeProductAdd} name="customerprice" className="input_text" type="number" />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">productimage :*</div>
              </div>
              <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                <div className="add_image_box" style={{ display: 'block' }}>
                  <div className="add_button">
                    <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => fileChangedHandler(e)} />
                  </div>
                </div>
                <div className="image-upload">
                  <label htmlFor="file-input">

                    <img alt="" src=
                      {
                        productdata.image
                          ?
                          data.baseurl + productdata.image
                          :
                          data.baseurl + "assets/noimage.svg"
                      } style={{ borderRadius: 10, width: 80, height: 60, marginLeft: 10 }} />
                  </label>

                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">categories :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <div className="category_main_box" style={{ height: 200, overflow: 'scroll' }}>

                  {/* <input type="text" className="search_bar" placeholder="search" /> */}
                  {categories.map((item, index) => (

                    <div key={index}>
                      <input id={index} type="checkbox" className="checkbox" onClick={() => catSelection(item.id, setProductdata, productdata, categories)} />
                      <label htmlFor={index}>{item.name}</label>
                    </div>
                  ))}

                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">brands :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <div className="category_main_box" style={{ height: 150, overflow: 'scroll' }}>
                  {brands.map((item, index) => (
                    <div key={index} className="cat_box" onClick={() => setProductdata(prevState => ({ ...prevState, brand: item.id }))}>
                      <div className="cat">{item.name}</div>
                      <div >
                        <input id={index + 231} type="checkbox" className="checkbox"
                          checked={productdata.brand === item.id}
                        />
                        <label htmlFor={index + 231}></label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div style={{ display: 'flex', marginTop: 20, justifyContent: 'center' }}>
              <div className="input_box_container flex_row flex_end" style={{ justifyContent: 'flex-end' }}>
                <div className="button-round" onClick={() => productAddToServer()}>Add product</div>
              </div>
            </div>

            <div>
            </div>
          </div>

        </Modal>


        {/* editProduct */}

        <Modal
          isOpen={editModal}
          onRequestClose={() => onRequestCloseEditModal(setEditModal, setProductdata, initialStateProductAdd)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div style={{ width: 500 }}>

            <div className="modalmain" style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
              <div style={{ fontWeight: 'bold' }}>Edit product</div>
              <div className="button-round" onClick={() => onRequestCloseEditModal(setEditModal, setProductdata, initialStateProductAdd)}>cancel</div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 60 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">Name :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.name} onChange={onChangeProductAdd} name="name" className="input_text" type="text" />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 60 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">B-C name :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.bcname} onChange={onChangeProductAdd} name="bcname" className="input_text" type="text" />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">Description :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.description} onChange={onChangeProductAdd} name="description" className="input_text" type="text" />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">stock :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.stock} onChange={onChangeProductAdd} name="stock" className="input_text" type="number" />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">vendorprice :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.vendorprice} onChange={onChangeProductAdd} name="vendorprice" className="input_text" type="number" />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">customerprice :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.customerprice} onChange={onChangeProductAdd} name="customerprice" className="input_text" type="number" />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">productimage :*</div>
              </div>
              <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                <div className="add_image_box" style={{ display: 'block' }}>
                  <div className="add_button">
                    <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => fileChangedHandler(e)} />
                  </div>
                </div>
                <div className="image-upload">
                  <label htmlFor="file-input">

                    <img alt="" src=
                      {
                        productdata.image
                          ?
                          data.baseurl + productdata.image
                          :
                          data.baseurl + "assets/noimage.svg"
                      } style={{ borderRadius: 10, width: 80, height: 60, marginLeft: 10 }} />
                  </label>

                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">categories :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <div className="category_main_box" style={{ height: 200, overflow: 'scroll' }}>

                  {/* <input type="text" className="search_bar" placeholder="search" /> */}
                  {categories.map((item, index) => (
                    <div key={index}>
                      <input id={index} checked={false} type="checkbox" checked={item.checked} className="checkbox" onClick={() => categorySelector(item.id)} />
                      <label htmlFor={index}>{item.name}</label>
                    </div>
                  ))}

                </div>
              </div>
            </div>


            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">brands :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <div className="category_main_box" style={{ height: 150, overflow: 'scroll' }}>
                  {brands.map((item, index) => (
                    <div key={index} className="cat_box" onClick={() => setProductdata(prevState => ({ ...prevState, brand: item.id }))}>
                      <div className="cat">{item.name}</div>
                      <div >
                        <input id={index + 231} type="checkbox" className="checkbox"
                          checked={productdata.brand === item.id}
                        />
                        <label htmlFor={index + 231}></label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>



            <div style={{ display: 'flex', marginTop: 40, justifyContent: 'center' }}>
              <div className="input_box_container flex_row flex_end" style={{ justifyContent: 'flex-end' }}>
                <div className="button-round" onClick={() => updateToServer()} >update product</div>
              </div>
            </div>

            <div>
            </div>
          </div>

        </Modal>



        {/* viewProduct */}

        <Modal
          isOpen={viewModal}
          onRequestClose={() => onRequestCloseViewModal(setViewModal, setProductdata, initialStateProductAdd)}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div style={{ width: 500 }}>

            <div className="modalmain" style={{ marginTop: 20, paddingLeft: 20, paddingRight: 20 }}>
              <div style={{ fontWeight: 'bold' }}>View product</div>
              <div className="button-round" onClick={() => onRequestCloseViewModal(setViewModal, setProductdata, initialStateProductAdd)}>cancel</div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 60 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">Name :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.name} disabled onChange={onChangeProductAdd} name="name" className="input_text" type="text" />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">Description :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.description} disabled onChange={onChangeProductAdd} name="description" className="input_text" type="text" />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">stock :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.stock} disabled onChange={onChangeProductAdd} name="stock" className="input_text" type="number" />
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">vendorprice :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.vendorprice} disabled onChange={onChangeProductAdd} name="vendorprice" className="input_text" type="number" />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">customerprice :</div>
              </div>
              <div style={{ flex: 1 }}>
                <input value={productdata.customerprice} disabled onChange={onChangeProductAdd} name="customerprice" className="input_text" type="number" />
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">productimage :*</div>
              </div>
              <div style={{ flex: 1, flexDirection: 'row', display: 'flex' }}>
                <div className="add_image_box" style={{ display: 'block' }}>
                  <div className="add_button">
                    <input id="file-input" disabled className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e) => fileChangedHandler(e)} />
                  </div>
                </div>
                <div className="image-upload">
                  <label htmlFor="file-input">

                    <img alt="" src=
                      {
                        productdata.image
                          ?
                          data.baseurl + productdata.image
                          :
                          data.baseurl + "assets/noimage.svg"
                      } style={{ borderRadius: 10, width: 80, height: 60, marginLeft: 10 }} />
                  </label>

                </div>
              </div>
            </div>

            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
                <div className="text">categories :*</div>
              </div>
              <div style={{ flex: 1 }}>
                <div className="category_main_box" style={{ height: 200, overflow: 'scroll' }}>

                  {categories.map((item, index) => (
                    <div key={index}>
                      <input id={index} disabled checked={false} type="checkbox" checked={item.checked} className="checkbox" onClick={() => categorySelector(item.id)} />
                      <label htmlFor={index}>{item.name}</label>
                    </div>
                  ))}

                </div>
              </div>


            </div>

            <div>
            </div>
          </div>

        </Modal>


      </div>
    </div>
  )
}


function catSelection(item, setProductdata, productdata, categories) {
  var array = productdata.categories
  const isInArray = array.includes(item);
  if (!isInArray == true) {

    array.push(item);
    setProductdata(prevState => ({ ...prevState, categories: array }));
  } else {

    for (var i = 0; i < array.length; i++) {
      if (array[i] === item) {
        array.splice(i, 1);
      }
    }
    setProductdata(prevState => ({ ...prevState, categories: array }));
  }

}

function getBrands(getBrands) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  apiRequest('listBrands', bodyFormData)
    .then(function (response) {
      console.log(response.data)
      // setBrand(response.data)
      getBrands(response.data);
    })
    .catch(error => {
      console.log(error.response.data)
    })
}

function getProducts(setProducts) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('page', 0);
  bodyFormData.set('limit', 200);
  apiRequest('listProducts', bodyFormData)
    .then(function (response) {
      console.log(response.data)
      setProducts(response.data)
    })
    .catch(error => {
      console.log(error)
    })
}

function listAllCategories(setCategories) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('page', 0);
  bodyFormData.set('limit', 200);

  apiRequest('listCategories', bodyFormData)
    .then(function (response) {
      // console.log(response.data)
      var result = response.data.map(function (el) {
        var o = Object.assign({}, el);
        o.checked = false;
        return o;
      })
      setCategories(result)

    })
    .catch(error => {
      console.log(error.response.data)
    })
}


function onRequestCloseModal(setModal, setProductdata, initialStateProductAdd) {
  setModal(false)
  setProductdata(initialStateProductAdd)
}
function onRequestCloseEditModal(setEditModal, setProductdata, initialStateProductAdd) {
  setEditModal(false)
  setProductdata(initialStateProductAdd)
}

function onRequestCloseViewModal(setViewModal, setProductdata, initialStateProductAdd) {
  setViewModal(false)
  setProductdata(initialStateProductAdd)
}

function productImageUpload(setProductdata, uriimage) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('singleImage', uriimage);
  apiRequest('uploadImage', bodyFormData)
    .then(function (response) {
      setProductdata(prevState => ({ ...prevState, image: response.data }));
    })
    .catch(error => {
      console.log(error.response.data)
    })
}


function productimagegallery(e, productdata, setProductdata, productImages, setProductImages) {
  e.preventDefault();
  var that = this

  var si = e.target.files.length
  if (e.target.files.length + productdata.productimages.length <= 3) {
    for (var i = 0; i < e.target.files.length; i++) {
      var fileInput = false
      if (e.target.files[i]) {
        fileInput = true
      }
      if (fileInput) {
        Resizer.imageFileResizer(
          e.target.files[i],
          300,
          300,
          'PNG',
          50,
          0,
          uri => {
            console.log(productImages)
            var newData = [];
            let dataset = productImages
            newData = dataset.push(uri)
            setProductImages(newData)
            // setProductImages(productImages.push({uri}));
          },
          'blob'
        );
      }

    }
    setTimeout(
      function () {
        // console.log(si)
        uploadgalleryImages(si, productImages, setProductdata, productdata)
      }
        .bind(),
      200
    );

  } else {
    alert("maximum limit reached")
  }

}

function uploadgalleryImages(si, productImages, setProductdata, productdata) {

  console.log(productImages)
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));

  for (var i = 0; i < si; i++) {
    bodyFormData.append('images', productImages[i]);
  }
  apiRequest('uploadMultipleImage', bodyFormData)
    .then(function (response) {
      console.log(response.data)
      setProductdata(prevState => ({ ...prevState, productimages: response.data }));
    })
    .catch(error => {
      console.log(error.response.data)
    })

}

export default App
