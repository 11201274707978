import React, { Component,useState,useEffect,useReducer } from 'react'
import './App.scss'
import Collapse from '../../components/Collapse'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Modal from 'react-modal';
import apiRequest from '../../apiRequest'
import {data} from '../../constant'
import Resizer from 'react-image-file-resizer'

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};


function App (){
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [modal,setModal] = useState(false)
  const [editmodal,setEditModal] = useState(false)
  const [themes,setThemes] = useState([])
  const initialtheme = {themetitle:'',packageinclude:'',stock:true,packages:[],image:'',id:''}
  const [themedata,setThemeData] = useState(initialtheme)
  const [packages,setPackages] = useState([])
  const [themeEditdata,setThemeEditdata] = useState(initialtheme)

  const onChange = e => {
    const { name, value } = e.target;
    setThemeData(prevState => ({ ...prevState, [name]: value }));
  }

  const onChangeedit = e => {
    const { name, value } = e.target;
    setThemeEditdata(prevState => ({ ...prevState, [name]: value }));
  };
  

  useEffect(()=>{
    getPackages(setPackages)
    getThemes(setThemes)
 }, [])

function fileChangedHandler (event) {
  var fileInput = false
  if(event.target.files[0]) {
      fileInput = true
  }
  if(fileInput) {
      Resizer.imageFileResizer(
          event.target.files[0],
          300,
          300,
          'PNG',
          50,
          0,
          uri => {
              // console.log(uri)
              productImageUpload(uri)
          },
          'blob'
      );
    }

  }

  function productImageUpload (uri) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('singleImage', uri);
    apiRequest('uploadImage',bodyFormData)
    .then(function (response) {
      setThemeData(prevState => ({ ...prevState, image: response.data }));
      setThemeEditdata(prevState => ({ ...prevState, image: response.data }));
    })
    .catch(error => {
      console.log(error.response.data)
    })
  }
  

function packageSelection (item) {
  var array = themedata.packages
  const isInArray = array.includes(item);
  if(!isInArray == true ) {
      array.push(item.toString());
      setThemeData(prevState => ({ ...prevState, packages: array }));
  }else{

    for( var i = 0; i < array.length; i++){ 
      if ( array[i] === item) {
        array.splice(i, 1); 
      }
   }
   setThemeData(prevState => ({ ...prevState, packages: array }));
  }

}
  function AddTheme() {

      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      bodyFormData.set('title', themedata.themetitle);
      bodyFormData.set('image', themedata.image);
      bodyFormData.set('description', themedata.packageinclude);
      bodyFormData.set('packages', JSON.stringify(themedata.packages));
      bodyFormData.set('stock', themedata.stock);
      apiRequest('addTheme',bodyFormData)
      .then(function (response) {
        console.log(response.data)
          setModal(false)
          getThemes(setThemes)
      })
      .catch(error => {
          
          alert(error.response.data)
      })
   
  
  }

  function deleteTheme(item) {
    var confirmbox = window.confirm("confirm delete theme!");
    if(confirmbox == true) {
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('id', item);
        apiRequest('deleteTheme',bodyFormData)
        .then(function (response) {
            getThemes(setThemes)
        })
        .catch(error => {
            alert(error.response.data)
        })
    }
  
}
function modalPres(){
  setModal(true)
  setThemeData(initialtheme)
}

function editTheme(item) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('id', item);
  apiRequest('getTheme',bodyFormData)
  .then(function (response) {
      setEditModal(true)
      // console.log("get theme from server",response.data)
      let res = response.data
      res.packages.map((single)=>{
        packages.map((packitem)=>{
            if(single.id == packitem.id){
              packitem.checked = true
              // console.log("matached",categories)
            }
          })
        })

        console.log("matached",res.packages)  
  
      setThemeEditdata({
        themetitle:res.title,
        stock:res.stock,
        // packages:[],
        image:res.image,
        packageinclude:res.description,
        id:res.id,
      })
      
  })
  .catch(error => {
      console.log(error.response.data)
  })
}

function checkEditData () {
  let packagelist =[]
  packages.map((item)=>{
      if(item.checked == true) {
        packagelist.push(item.id)
      }
  })
  if(themeEditdata.themetitle.length > 0 && themeEditdata.image.length > 0) {
      // console.log("theme",themeEditdata)
      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      bodyFormData.set('id', themeEditdata.id);
      bodyFormData.set('name', themeEditdata.themetitle);
      bodyFormData.set('description', themeEditdata.packageinclude);
      bodyFormData.set('stock', themeEditdata.stock)
      bodyFormData.set('packages', JSON.stringify(packagelist.map(String)));
      bodyFormData.set('image', themeEditdata.image);

      apiRequest('editTheme',bodyFormData)
      .then(function (response) {
          setEditModal(false)
          setThemeEditdata(initialtheme)
          getThemes(setThemes)
          getPackages(setPackages)
      })
      .catch(error => {
          alert(error.response.data)
      })
  }else{
      alert("Please fill required fields")
  }
}

function packageSelector (id){
  packages.map((item)=>{
      if(item.id == id) {
        item.checked = !item.checked
      }
    })
    forceUpdate()
  }
    return(
        <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">theme management</div>
            <div class="button-round" onClick={()=>modalPres()}>add theme</div>
          </div>
          <ReactCSSTransitionGroup
          transitionName="example"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnter={false}
          transitionLeave={false}>
          
          <table class="main-table">
                    
        {themes.map((item)=>(
          <div class="user-list-container">
            <div class="name-section">
                <div class="icon">
                <img alt="" src={data.baseurl + item.image} style={{width:28,height:28,borderRadius:28/2}} ></img>
                </div>
                <div class="text">{item.title}</div> 
                {/* <div class="text" style={{marginLeft:30}}>{item.description}</div>  */}
            </div>
            <div class="action-container">
                <div class="button button-round" onClick={()=>editTheme(item.id)} >edit</div>
                <div class="button button-round" onClick={()=>deleteTheme(item.id)} >delete</div>
            </div>
            </div>
        ))}
            
          </table>

          {/* add theme */}

        <Modal
          isOpen={modal}
          onRequestClose={()=>onRequestCloseModal(setModal)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Add theme</div>
                    <div className="button-round" onClick={()=>onRequestCloseModal(setModal)}>cancel</div>
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">themeimage :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                              themedata.image 
                            ?  
                            data.baseurl + themedata.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                      </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Theme Title :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={themedata.themetitle} onChange={onChange} name="themetitle" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Package description :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={themedata.packageinclude} onChange={onChange} name="packageinclude" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Stock :</div>
                    </div>
                    <div style={{flex:1}}>
                        <select value={themedata.stock} onChange={onChange} name="stock">
                            <option value={true}>true</option>
                            <option value={false}>false</option>
                        </select>
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">packages :*</div>
                        </div>
                        <div style={{flex:1}}>
                        <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {packages.map((item,index)=>(
                                <div key={index}>
                                  <input id={index+20}  type="checkbox"   className="checkbox"  onClick={()=>packageSelection(item.id)}/>
                                  <label htmlFor={index+20}>{item.package_name}</label>    
                                </div>
                              ))}
                            
                              </div>
                        </div>
                </div>

                 <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30}}>
                        <div className="button-round" onClick={()=>AddTheme()}>Add theme</div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>
{/* edit theme */}
        <Modal
          isOpen={editmodal}
          onRequestClose={()=>onRequestCloseEditModal(setEditModal,setThemeEditdata,initialtheme)}
          style={customStyles}
          contentLabel="Example Modal"
          >
            <div style={{width:500}}>
            <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Edit theme</div>
                    <div className="button-round" onClick={()=>onRequestCloseEditModal(setEditModal)}>cancel</div>
                </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">themeimage :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                              themeEditdata.image 
                            ?  
                            data.baseurl + themeEditdata.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                      </div>
                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Theme Title :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={themeEditdata.themetitle} onChange={onChangeedit} name="themetitle" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Package description :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={themeEditdata.packageinclude} onChange={onChangeedit} name="packageinclude" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Stock :</div>
                    </div>
                    <div style={{flex:1}}>
                        <select value={themeEditdata.stock} onChange={onChangeedit} name="stock">
                            <option value={true}>true</option>
                            <option value={false}>false</option>
                        </select>
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">packages :*</div>
                        </div>
                        <div style={{flex:1}}>
                        <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {packages.map((item,index)=>(
                                <div key={index}>
                                  <input id={index+20} checked={item.checked} type="checkbox" className="checkbox"  onClick={()=>packageSelector(item.id)}/>
                                  <label htmlFor={index+20}>{item.package_name}</label>    
                                </div>
                              ))}
                            
                              </div>
                        </div>
                </div>

                <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30}}>
                        <div className="button-round" onClick={()=>checkEditData()} >Update theme</div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
 }



 function getThemes(setThemes) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('page', 0);
  bodyFormData.set('limit', 10);
  apiRequest('listThemes',bodyFormData)
  .then(function (response) {
      console.log(response.data)
      setThemes(response.data)
  })
  .catch(error => {
      // console.log(error.response.data)
  })
}

function onRequestCloseEditModal (setEditModal) {
  console.log('workign')
  setEditModal(false)

}


function getPackages(setPackages) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('page', 0);
  bodyFormData.set('limit', 10);
  apiRequest('listPackages',bodyFormData)
  .then(function (response) {
       
        var data = response.data
        data.map((item) =>{
          item.checked = false
        })
      // console.log("sdfdf",data)
      setPackages(data)
        
  })
  .catch(error => {
      console.log(error.response.data)
  })
}

 function onRequestCloseModal (setModal) {
  setModal(false)
}
 
 export default App