import React, { Component,useEffect,useState } from 'react'
import apiRequest from '../../apiRequest'
import './App.scss'

function App (){
  const [user,setUser] =useState({})
  useEffect(()=>{
    getUsersCount()
 }, [])

 function getUsersCount() {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  apiRequest('getUsersCount',bodyFormData)
  .then(function (response) {
     console.log("userscount",response.data)
    setUser(response.data)
  })
  .catch(error => {
     console.log(error)
  })

}
   return(
    <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">Analytics</div>
          </div>
    <div class="dashboard_container">
     <div className="mytop_flex" style={{marginLeft:10,paddingBottom:30}}>
      <div className="item_box">
       
        <div className="header"></div>
        <div className="content">
          <div className="count">{user.allusers}</div>
          <div className="text">TOTAL USERS</div>
        </div>
        {/* <div className="footer">
          <div className="box">
            <div className="text">NEW</div>
            <div className="count">5</div>
          </div>
          <div className="box">
            <div className="text">ACTIVE</div>
            <div className="count">{user.active}</div>
          </div>
          <div className="box">
            <div className="text">INACTIVE</div>
            <div className="count">{user.inactive}</div>
          </div>
        </div> */}
        
      </div>


  </div>
  </div>
         
        </div>
      </div>
   )
}

export default App
