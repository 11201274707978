import React, { useEffect,useState } from 'react'
import apiRequest from '../../apiRequest'
import Modal from 'react-modal';
import Resizer from 'react-image-file-resizer'
import './App.scss'
import {data} from '../../constant'


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};


const  App = () =>{

  const [enquiries,setEnquiries] = useState([])

  useEffect(()=>{
    getEnquiries(setEnquiries)
 }, [])

    return(
        <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">Enquiries</div>
          </div>
          <table class="main-table">
            <tr class="heading">
              <td>ID</td>
              <td>Name</td>
              <td>Email</td>
              {/* <td>Subject</td>
              <td>Message</td>
              <td>Place</td> */}
              <td>Mobile</td>
              <td></td>
            </tr>

          {enquiries.map((item)=>(
           <tr class="content">
              <td>{item.id}</td>
              <td class="package-name-click">{item.name}</td>
              <td class="package-name-click">{item.email}</td>
              {/* <td class="package-name-click">{item.subject}</td>
              <td class="package-name-click">{item.message}</td>
              <td class="package-name-click">{item.place}</td> */}
              <td class="package-name-click">{item.mobile}</td>
              {/* <td class="action-box flex-row">
                <div class="button-round" onClick={()=>deleteItem(item.id)}>delete</div>
              </td> */}
            </tr>  
          ))}
          </table>
        </div>
      </div>
    )
 }


 function getEnquiries(setEnquiries) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('page', 0);
  bodyFormData.set('limit', 200);
  apiRequest('listEnquiries',bodyFormData)
  .then(function (response) {
    console.log(response.data)
      setEnquiries(response.data)
  })
  .catch(error => {
      console.log(error.response.data)
  })
}

 
 export default App