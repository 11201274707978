import React, { useState,useEffect } from 'react'
import apiRequest from '../../apiRequest'
import Modal from 'react-modal';
import Resizer from 'react-image-file-resizer'
import './App.scss'
import {data} from '../../constant'


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};


const App =() =>{
  const [pincodes,setPincodeData] = useState([])
  const [modal,setModal] = useState(false)
  const initialStatevstData = {pincode:'',id:'',price:''}
  const [pindata,setPindata] = useState(initialStatevstData)

  useEffect(()=>{
    getPincodes(setPincodeData)
 }, [])

 const onChange = e => {
  const { name, value } = e.target;
  setPindata(prevState => ({ ...prevState, [name]: value }));
};

function checkAddData () {
  if(pindata.pincode.length > 0) {
      addtoServer()
  }else{
      alert("Please fill required fields")
  }
}

function addtoServer() {
  // console.log(catdata)
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('pincode', pindata.pincode);
  bodyFormData.set('price', pindata.price);
  apiRequest('addPincode',bodyFormData)
  .then(function (response) {
      setModal(false)
      // se(initialStatevstData)
      getPincodes(setPincodeData)
  })
  .catch(error => {
      
      alert(error.response.data)
  })

}


function deletePincode(item) {
  var confirmbox = window.confirm("confirm delete pincode!");
  if(confirmbox == true) {
      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      bodyFormData.set('id', item);
      apiRequest('deletePincode',bodyFormData)
      .then(function (response) {
        getPincodes(setPincodeData)
      })
      .catch(error => {
          alert(error.response.data)
      })
  }

}

function onModalPress(){
  setModal(true)
  setPindata(initialStatevstData)
}
    return(
        <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">Available Pincodes</div>
            <div class="button-round" onClick={()=>onModalPress()}>add pincode</div>
          </div>
          <table class="main-table">
            <tr class="heading">
              <td>ID</td>
              <td>Pincode</td>
              <td>Price</td>
              <td></td>
            </tr>

            {pincodes.map((item)=>(
                <tr class="content">
                  <td>{item.id}</td>
                  <td class="package-name-click">{item.pincode}</td>
                  <td class="package-name-click">{item.price}</td>
                  <td class="action-box flex-row">
                    <div class="button-round" onClick={()=>deletePincode(item.id)}>delete</div>
                  </td>
                </tr>
            ))}
         
            
     
            
          </table>
        </div>

        {/* add pincode */}

        <Modal
          isOpen={modal}
          onRequestClose={()=>onRequestCloseModal(setModal,setPincodeData,initialStatevstData)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:500}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Add pincode</div>
                    <div className="button-round" onClick={()=>onRequestCloseModal(setModal,setPincodeData,initialStatevstData)}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Pincode* :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={pindata.pincode} onChange={onChange} name="pincode" className="input_text" type="text" />
                    </div>
                </div>


                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Price* :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={pindata.price} onChange={onChange} name="price" className="input_text" type="number" />
                    </div>
                </div>

                 <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30}}>
                        <div className="button-round" onClick={()=>checkAddData()}>Add pincode</div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>
      </div>
    )
 }
 

 function getPincodes(setPincodeData) {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('page', 0);
  bodyFormData.set('limit', 200);
  apiRequest('listPincodes',bodyFormData)
  .then(function (response) {
      // console.log(response.data)
      setPincodeData(response.data)
  })
  .catch(error => {
      console.log(error.response.data)
  })
}

 function onRequestCloseModal (setModal,setPincodeData) {
  setModal(false)
  // setPincodeData(initial)
}
 export default App
