import React, { Component,useState,useEffect,useReducer} from 'react'
import './App.scss'
import Collapse from '../../components/Collapse'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
import Modal from 'react-modal';
import apiRequest from '../../apiRequest'
import {data} from '../../constant'
import Resizer from 'react-image-file-resizer'
import _ from 'lodash'

const customStyles = {
  content : {
    top                   : '40%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    height:'500px',
    overlfow: 'scroll' // <-- This tells the modal to scrol
  }
};


function App (){
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [modal,setModal] = useState(false)
  const [manager,setManager] = useState(false)
  const [editmodal,setEditModal] = useState(false)
  const [viewmodal,setViewModal] = useState(false)
  // const [extra,setExtra] = useState([])
  const [productslist,setProductslist] = useState([])
  const [products,setProducts] = useState([])
  const [extraproducts,setExtraProducts] = useState([])
  const [themes,setThemes] = useState([])
  const [packages,setPackages] = useState([])
  const initialpackage = {name:'',description:'',image:'',price:'',peoplecount:'',products:[],extra:[],id:0}
  const [packagedata,setPackageData] = useState(initialpackage)
  const [packageEditdata,setPackageEditdata] = useState(initialpackage)
  const [packageExtra,setPackageExtra] = useState([])

  const onChange = e => {
    const { name, value } = e.target;
    setPackageData(prevState => ({ ...prevState, [name]: value }));
  }

  const onChangeEdit = e => {
    const { name, value } = e.target;
    setPackageEditdata(prevState => ({ ...prevState, [name]: value }));
  }
  function onRequestCloseEditModal () {
    // console.log('workign')
    setEditModal(false)
    listAllProducts()
  
  }

  function onRequestCloseViewModal () {
    // console.log('workign')
    setViewModal(false)
    listAllProducts()
  
  }
  useEffect(()=>{
    getPackages(setPackages)
    // listAllCategories(setCategories)
    listAllThemes(setThemes)
    listAllProducts(setProducts,setExtraProducts)
 }, [])


 function fileChangedHandler (event) {
    var that = this
    var fileInput = false
    if(event.target.files[0]) {
        fileInput = true
    }
    if(fileInput) {
        Resizer.imageFileResizer(
            event.target.files[0],
            300,
            300,
            'PNG',
            50,
            0,
            uri => {
                console.log(uri)
                productImageUpload(setPackageData,uri,setPackageEditdata)
            },
            'blob'
        );
      }

    }


    function AddPackage() {
        console.log(packagedata)
        if(packagedata.name.length > 0 && packagedata.extra.length > 0 && packagedata.price  > 0 && packagedata.peoplecount  > 0 && packagedata.image.length > 0) {
          setPackageData(initialpackage)
            AddToServer()
        }else{
            alert("Please fill required fields")
        }
    } 

  function AddToServer() {
   var filterProducts = []
   products.map((item)=>{
        if(item.count > 0){
            filterProducts.push({"id":item.id.toString(),"count":item.count.toString()})
        }
    })
    // console.log("filters products",filterProducts)
    // console.log("this is my package",packagedata)
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('name',packagedata.name);
    bodyFormData.set('description', packagedata.description);
    bodyFormData.set('products', JSON.stringify(filterProducts));
    bodyFormData.set('extras',JSON.stringify(packagedata.extra.map(String)));
    bodyFormData.set('price', packagedata.price);
    bodyFormData.set('count', packagedata.peoplecount);
    bodyFormData.set('image', packagedata.image);
    // bodyFormData.set('themeid', packagedata.themeid);
  
    apiRequest('addPackage',bodyFormData)
    .then(function (response) {
       setModal(false)
       getPackages(setPackages)
      //  setProducts([])
      const data =  [...products]

      data.map((single)=>{
        if(single.count > 0) {
          single.count = 0
        }
      })
       setProducts(data)
    })
    .catch(error => {
      console.log(error)
  })
   }


  function deletePackage(item) {
    var confirmbox = window.confirm("confirm delete package!");
    if(confirmbox == true) {
        var bodyFormData = new FormData();
        bodyFormData.set('token', localStorage.getItem('token'));
        bodyFormData.set('id', item);
        apiRequest('deletePackage',bodyFormData)
        .then(function (response) {
            getPackages(setPackages)
        })
        .catch(error => {
            alert(error.response.data)
        })
    }
  
}

function searchProduct2(text) {
    var newArray = productslist.filter(item => item.product_name.indexOf(text.toUpperCase()) > -1);
    setProducts(newArray)
}

function searchProduct1(text) {
  var newArray = productslist.filter(item => item.product_name.indexOf(text.toUpperCase()) > -1);
  setExtraProducts(newArray)
}

function addProductCount(single) {
  const data =  [...products]
  single.count = single.count >= 0  ? single.count + 1 : 0
  setProducts(data)
}

function minusProductCount(single) {
  const data =  [...products]
  if(single.count > 0) {
        single.count = single.count - 1
  }
  setProducts(data)
}

function modalPress(){
  setModal(true)
  setPackageData(initialpackage)
}
function onEditPress(item) {
  // listAllProducts()
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('id', item);
  apiRequest('getPackage',bodyFormData)
  .then(function (response) {
      setEditModal(true)
      // console.log(response.data)
      let res = response.data
      // console.log(res)
      res.extrasproducts.map((single)=>{
          extraproducts.map((exp)=>{
            if(exp.id === single.id) {
              exp.checked = true
            }
          })
      })
  
      getAddProducts(item)
      setManager(true)
      setPackageEditdata({
        name:res.package_name,
        description:res.package_description,
        image:res.feature_image,
        price:res.customer_price,
        peoplecount:res.people_count,
        id:res.id,
        // products:res.products,
        // extra:res.extra
      })
      
  })
  .catch(error => {
      console.log(error)
  })
}

function onViewPress(item) {
  // listAllProducts()
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('id', item);
  apiRequest('getPackage',bodyFormData)
  .then(function (response) {
      setViewModal(true)
      // console.log(response.data)
      let res = response.data
      // console.log(res)
      res.extrasproducts.map((single)=>{
          extraproducts.map((exp)=>{
            if(exp.id === single.id) {
              exp.checked = true
            }
          })
      })
  
      getAddProducts(item)
      setManager(true)
      setPackageEditdata({
        name:res.package_name,
        description:res.package_description,
        image:res.feature_image,
        price:res.customer_price,
        peoplecount:res.people_count,
        id:res.id,
        // products:res.products,
        // extra:res.extra
      })
      
  })
  .catch(error => {
      console.log(error)
  })
}
function getAddProducts(item) {
  var bodyFormData = new FormData();
  bodyFormData.set('id', item);
  apiRequest('getProductWithCount',bodyFormData)
  .then(function (response) {
     console.log("add on data",response.data) 
    var res = response.data
    res.map((single)=>{
      products.map((exp)=>{
        if(exp.id === single.id) {
          exp.count = parseInt(single.count)
        }
      })
    })
    
    forceUpdate()
    console.log("after",products)
  })
  .catch(error => {
      console.log(error)
  })
}

function checkEditData () {

  let productslist =[]
  extraproducts.map((item)=>{
      if(item.checked == true) {
        productslist.push(item.id)
      }
  })

  var filterProducts = []
  products.map((item)=>{
       if(item.count > 0){
           filterProducts.push({"id":item.id.toString(),"count":item.count.toString()})
       }
   })
  console.log("set data",productslist)
  if(packageEditdata.name.length > 0 && packageEditdata.price  > 0 && packageEditdata.peoplecount  > 0 && packageEditdata.image.length > 0 && packageEditdata.id  > 0) {
      console.log(packageEditdata)
      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      bodyFormData.set('id', packageEditdata.id);
      bodyFormData.set('name', packageEditdata.name);
      bodyFormData.set('description', packageEditdata.description);
      bodyFormData.set('price', packageEditdata.price)
      bodyFormData.set('peoplecount', packageEditdata.peoplecount)
      bodyFormData.set('extra', JSON.stringify(productslist.map(String)));
      bodyFormData.set('products', JSON.stringify(filterProducts));
      bodyFormData.set('image', packageEditdata.image);

      apiRequest('editPackage',bodyFormData)
      .then(function (response) {
          setEditModal(false)
          setPackageEditdata(initialpackage)
          getPackages(setPackages)
          // setExtraProducts([])
          listAllProducts()
      })
      .catch(error => {
          alert(error.response.data)
      })
  }else{
      alert("Please fill required fields")
  }
  
}


function extraSelection (item) {
  var array = packagedata.extra
  const isInArray = array.includes(item);
  if(!isInArray == true ) {
      array.push(item);
      setPackageData(prevState => ({ ...prevState, extra: array }));
  }else{
    for( var i = 0; i < array.length; i++){ 
      if ( array[i] === item) {
        array.splice(i, 1); 
      }
   }
   setPackageData(prevState => ({ ...prevState, extra: array }));
  }
  console.log(array)
}

function listAllProducts() {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('page', 0);
  bodyFormData.set('limit', 200);

  apiRequest('listProducts',bodyFormData)
  .then(function (response) {
      console.log(response.data)
      var result = response.data.map(function(el) {
        var o = Object.assign({}, el);
        o.checked = false;
        return o;
      })
      setProducts(result)
      setExtraProducts(result)
      setProductslist(result)
  })
  .catch(error => {
    console.log(error.response)
})
}

// function catSelection (item) {

//   var array = packagedata.extra
//   const isInArray = array.includes(item);
//   if(!isInArray == true ) {
//       array.push(item);
//       setPackageData(prevState => ({ ...prevState, extra: array }));
//   }else{
//     for( var i = 0; i < array.length; i++){ 
//       if ( array[i] === item) {
//         array.splice(i, 1); 
//       }
//    }
//    setPackageData(prevState => ({ ...prevState, extra: array }));
//   }

// }

// function listproductsbycat (itemme) {
  
//    var single = categories.find(x => x.id === itemme)
//    var result = single.products.map(function(el) {
//     var o = Object.assign({}, el);
//     o.value = false;
//     return o;
//   })
//   let prev = packageExtra
//   prev.concat(result)
//   // var non_duplidated_data = _.uniq(prev, 'id'); 
  
//   setPackageExtra(result)
  

// }

function extraSelectior (itemme) {
  extraproducts.map((item)=>{
    if(item.id == itemme) {
      item.checked = !item.checked
    }
  })
  forceUpdate()
}
    return(
        <div class="body-container">
        <div class="table-container flex-col">
          <div class="header-container flex-row">
            <div class="text">package management</div>
            <div class="button-round" onClick={()=>modalPress()}>add package</div>
          </div>
          <ReactCSSTransitionGroup
          transitionName="example"
          transitionAppear={true}
          transitionAppearTimeout={500}
          transitionEnter={false}
          transitionLeave={false}>
          
       

        <table class="main-table">
            <tr class="heading">
              <td>ID</td>
              <td>IMAGE</td>
              <td>PACKAGE NAME</td>
              <td>PEOPLE COUNT</td>
              <td>PRICE</td>
              <td></td>
              <td></td>
              <td>ACTION</td>
            </tr>

          {packages.map((item)=>(
           <tr class="content">
              <td>{item.id}</td>
              <td><div class="icon"><img alt="" src={data.baseurl + item.feature_image} style={{width:28,height:28,borderRadius:28/2}} ></img></div></td>
              <td class="package-name-click">{item.package_name}</td>
              <td class="package-name-click">{item.people_count}</td>
              <td class="package-name-click">{item.customer_price}</td>
              <td class="package-name-click"></td>
              <td class="package-name-click"></td>
              <td class="package-name-click">
                <div class="action-container" style={{display:'-webkit-inline-box'}}>
                <div class="button button-round"  style={{marginRight:8}} onClick={()=>onViewPress(item.id)}>view</div>
                  <div class="button button-round"  style={{marginRight:8}} onClick={()=>onEditPress(item.id)}>edit</div>
                  <div class="button button-round" onClick={()=>deletePackage(item.id)} >delete</div>
                </div>
              </td>
              
            </tr>  
          ))}
          </table>

          {/* add package */}

        <Modal
          isOpen={modal}
          onRequestClose={()=>onRequestCloseModal(setModal)}
          style={customStyles}
          contentLabel="Example Modal"
        >
            <div style={{width:700}}>
                <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Add package</div>
                    <div className="button-round" onClick={()=>onRequestCloseModal(setModal)}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">image :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                                packagedata.image 
                            ?  
                            data.baseurl + packagedata.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                      </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Package name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packagedata.name} onChange={onChange} name="name" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Description :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packagedata.description} onChange={onChange} name="description" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Price :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packagedata.price} onChange={onChange} name="price" className="input_text" type="text" />
                    </div>
                </div>
                
                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">PeopleCount :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packagedata.peoplecount} onChange={onChange} name="peoplecount" className="input_text" type="text" />
                    </div>
                </div>

                {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">Extra :</div>
                        </div>
                        <div style={{flex:1}}>
                        <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {categories.map((item,index)=>(
                                <div key={index}>
                                  <input id={index}  type="checkbox"   className="checkbox"  onClick={()=>catSelection(item.id)}/>
                                  <label htmlFor={index}>{item.name}</label>    
                                </div>
                              ))}
                            
                              </div>
                        </div>
                </div> */}

                {/* <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">Include Product by category:</div>
                        </div>
                        <div style={{flex:1}}>
                    <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {packageExtra.map((item,index)=>(
                                <div key={index}>
                                  <input id={index+236}  type="checkbox" className="checkbox" />
                                  <label htmlFor={index+236}>{item.product_name}</label>    
                                </div>
                              ))}
                    </div>
                        </div>
                </div> */}
                    <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">Package Includes :*</div>
                        </div>
                        <div style={{flex:1}}>
                        <input type="text" className="search_bar" onChange={(e)=>searchProduct2(e.target.value)} placeholder="search here"/>
                        <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {products.map((item,index)=>(
                                <div class="varient_container">
                                  <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',marginTop:10}}>
                                      <div className="name"> {item.product_name} </div>
                                      <div className="" style={{flexDirection:'row',display:'flex'}}>
                                          <div className="">qty</div>
                                          <div className="mybtn" oPackage InnClick={()=>minusProductCount(item)}>-</div>
                                          <div className="text">{item.count}</div>
                                          <div className="mybtn" onClick={()=>addProductCount(item)}>+</div>
                                      </div>
                                      
                                  </div>
                                </div>
                              ))}
                    </div>
                        </div>
                </div>

                  <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">Add-ons :*</div>
                        </div>
                        <div style={{flex:1}}>
                        <input type="text" className="search_bar" onChange={(e)=>searchProduct1(e.target.value)} placeholder="search here"/>
                        <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {extraproducts.map((item,index)=>(
                              <div key={index}>
                                 <input id={index}  type="checkbox" onClick={()=>extraSelection(item.id)} className="checkbox" />
                                 <label htmlFor={index}>{item.product_name}</label>    
                              </div>
                              ))}
                    </div>
                        </div>
                </div>

            

                 <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30}}>
                        <div className="button-round" onClick={()=>AddPackage()}>Add package</div>
                </div>
             

                <div>
                </div>
            </div>

        </Modal>

        {/* edit theme */}
        <Modal
          isOpen={editmodal}
          onRequestClose={()=>onRequestCloseEditModal(setEditModal)}
          style={customStyles}
          contentLabel="Example Modal"
          >
            <div style={{width:700}}>
            <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Edit package</div>
                    <div className="button-round" onClick={()=>onRequestCloseEditModal(setEditModal)}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">image :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                                packageEditdata.image 
                            ?  
                            data.baseurl + packageEditdata.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                      </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Package name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packageEditdata.name} onChange={onChangeEdit} name="name" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Description :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packageEditdata.description} onChange={onChangeEdit} name="description" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Price :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packageEditdata.price} onChange={onChangeEdit} name="price" className="input_text" type="text" />
                    </div>
                </div>
                
                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">PeopleCount :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packageEditdata.peoplecount} onChange={onChangeEdit} name="peoplecount" className="input_text" type="text" />
                    </div>
                </div>
                
                {manager && 
                
                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">Add-ons :*</div>
                        </div>
                        <div style={{flex:1}}>
                        <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {extraproducts.map((item,index)=>(
                                <div key={index}>
                                  {/* {console.log(item.checked)} */}
                                  <input id={index}  type="checkbox"  checked={item.checked} className="checkbox"  onClick={()=>extraSelectior(item.id)}/>
                                  <label htmlFor={index}>{item.product_name}</label>    
                                </div>
                              ))}
                            
                              </div>
                        </div>
                </div>
                }


                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">Package Includes :*</div>
                        </div>
                        <div style={{flex:1}}>
                        <input type="text" className="search_bar" onChange={(e)=>searchProduct2(e.target.value)} placeholder="search here"/>
                        <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {products.map((item,index)=>(
                                <div class="varient_container">
                                  <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',marginTop:10}}>
                                      <div className="name"> {item.product_name} </div>
                                      <div className="" style={{flexDirection:'row',display:'flex'}}>
                                          <div className="">qty</div>
                                          <div className="mybtn" onClick={()=>minusProductCount(item)}>-</div>
                                          <div className="text">{item.count}</div>
                                          <div className="mybtn" onClick={()=>addProductCount(item)}>+</div>
                                      </div>
                                      
                                  </div>
                                </div>
                              ))}
                    </div>
                        </div>
                </div>

                 <div className="input_box_container flex_row flex_end" style={{justifyContent: 'flex-end',marginTop:30}}>
                        <div className="button-round" onClick={()=>checkEditData()}>Update package</div>
                </div>
             

                <div>
                  </div>
            </div>

        </Modal>



           {/* view package */}
           <Modal
          isOpen={viewmodal}
          onRequestClose={()=>onRequestCloseViewModal(setViewModal)}
          style={customStyles}
          contentLabel="Example Modal"
          >
            <div style={{width:700}}>
            <div className="modalmain" style={{marginTop:20,paddingLeft:20,paddingRight:20}}>
                    <div style={{fontWeight:'bold'}}>Edit package</div>
                    <div className="button-round" onClick={()=>onRequestCloseViewModal(setViewModal)}>cancel</div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">image :*</div>
                        </div>
                        <div style={{flex:1,flexDirection:'row',display:'flex'}}>
                            <div  className="add_image_box" style={{display:'block'}}>
                            <div  className="add_button">
                            <input id="file-input" disabled className="add_button_image_img" type="file" accept="image/x-png,image/gif,image/jpeg" onChange={(e)=>fileChangedHandler(e)} />
                            </div>
                            </div>
                            <div className="image-upload">
                        <label htmlFor="file-input">
                          
                        <img alt="" src=
                            {       
                                packageEditdata.image 
                            ?  
                            data.baseurl + packageEditdata.image 
                            : 
                            data.baseurl + "assets/noimage.svg"
                            }  style={{borderRadius:10,width:80,height:60,marginLeft:10}}/> 
                        </label>
                        
                       </div>
                        </div>
                      </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:60}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Package name :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packageEditdata.name}  disabled onChange={onChangeEdit} name="name" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Description :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packageEditdata.description} disabled onChange={onChangeEdit} name="description" className="input_text" type="text" />
                    </div>
                </div>

                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">Price :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packageEditdata.price} disabled onChange={onChangeEdit} name="price" className="input_text" type="text" />
                    </div>
                </div>
                
                <div style={{display:'flex',flexDirection:'row',marginTop:10}}>
                    <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                        <div className="text">PeopleCount :</div>
                    </div>
                    <div style={{flex:1}}>
                    <input value={packageEditdata.peoplecount} disabled onChange={onChangeEdit} name="peoplecount" className="input_text" type="text" />
                    </div>
                </div>
                
                {manager && 
                
                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">Add-ons :*</div>
                        </div>
                        <div style={{flex:1}}>
                        <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {extraproducts.map((item,index)=>(
                                <div key={index}>
                                  {/* {console.log(item.checked)} */}
                                  <input id={index}  type="checkbox" disabled checked={item.checked} className="checkbox"  onClick={()=>extraSelectior(item.id)}/>
                                  <label htmlFor={index}>{item.product_name}</label>    
                                </div>
                              ))}
                            
                              </div>
                        </div>
                </div>
                }


                <div style={{display:'flex',flexDirection:'row',marginTop:20}}>
                        <div style={{flex:1,display:'flex',justifyContent:'center'}}>
                            <div className="text">Package Includes :*</div>
                        </div>
                        <div style={{flex:1}}>
                        <input type="text" disabled className="search_bar" onChange={(e)=>searchProduct2(e.target.value)} placeholder="search here"/>
                        <div className="category_main_box" style={{height:100,overflow:'scroll'}}>
                              {products.map((item,index)=>(
                                <div class="varient_container">
                                  <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row',marginTop:10}}>
                                      <div className="name"> {item.product_name} </div>
                                      <div className="" style={{flexDirection:'row',display:'flex'}}>
                                          <div className="">qty</div>
                                          <div className="mybtn" onClick={()=>minusProductCount(item)}>-</div>
                                          <div className="text">{item.count}</div>
                                          <div className="mybtn" onClick={()=>addProductCount(item)}>+</div>
                                      </div>
                                      
                                  </div>
                                </div>
                              ))}
                    </div>
                        </div>
                </div>

               
             

                <div>
                  </div>
            </div>

        </Modal>
          </ReactCSSTransitionGroup>
        </div>
      </div>
    )
 }





 function getPackages(setPackages) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('page', 0);
    bodyFormData.set('limit', 200);
    apiRequest('listPackages',bodyFormData)
    .then(function (response) {
          console.log(response.data)
        setPackages(response.data)
    })
    .catch(error => {
        console.log(error.response)
    })
}


 function onRequestCloseModal (setModal) {
  setModal(false)
}



function productImageUpload (setPackageData,uriimage,setPackageEditdata) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('singleImage', uriimage);
    apiRequest('uploadImage',bodyFormData)
    .then(function (response) {
        setPackageData(prevState => ({ ...prevState, image: response.data }));
        setPackageEditdata(prevState => ({ ...prevState, image: response.data }));
    })
    .catch(error => {
      console.log(error.response.data)
    })
  }



  function listAllThemes(setThemes) {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('page', 0);
    bodyFormData.set('limit', 200);
  
    apiRequest('listThemes',bodyFormData)
    .then(function (response) {
        // console.log(response.data)
        var result = response.data.map(function(el) {
          var o = Object.assign({}, el);
          o.checked = false;
          return o;
        })
        setThemes(result)
      
    })
    .catch(error => {
      console.log(error.response)
  })
  }
 
 export default App