import React, { Component, useEffect,useState } from 'react'
import ReactCSSTransitionGroup from 'react-addons-css-transition-group'
// import './style.scss'
import apiRequest from '../../apiRequest'
import {data} from '../../constant'
import './main.css'
import Background from '../../assets/banner.jpg'
import { Link, Route,withRouter } from 'react-router-dom'
import {Header,Footer} from '../../components'
import Carousel from 'react-carousel';
import './caro.css';
import Slider from 'react-animated-slider';
import 'react-animated-slider/build/horizontal.css';
import './slider-animations.css';

const  App = ({props,match,history,location}) => {

    const [backgrounds,Setbackgrounds] = useState([])
    const [themes,SetThemes] = useState([])

useEffect(()=>{
    window.scrollTo(0, 0)
    getBackground()
    getThemes()
    // const values = queryString.parse(this.props.location.search)
    // console.log(values)
    
    }, [])

function getBackground() {
    var bodyFormData = new FormData();
    apiRequest('getBackground',bodyFormData)
    .then(function (response) {
            console.log(response.data)
            Setbackgrounds(response.data)
    })
    .catch(error => {
        // console.log(error.response)
    })
}

function getThemes() {
    var bodyFormData = new FormData();
    bodyFormData.set('token', localStorage.getItem('token'));
    bodyFormData.set('page', 0);
    bodyFormData.set('limit', 8);
    apiRequest('listThemes',bodyFormData)
    .then(function (response) {
        console.log(response.data)
        SetThemes(response.data)
    })
    .catch(error => {
        // console.log(error)
    })
}

// function checkColor (color) {
//     if (color) {
//         return color
//     }else {
//         return 'white'
//     }
// }
    return(
        <ReactCSSTransitionGroup
        transitionName="example"
        transitionAppear={true}
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave={false}>
        <div id="container">
        <Header {...props}/>
        <>
        <Slider className="slider-wrapper" infinite={true} autoplay={3000}>
			{backgrounds.map((item, index) => (
				<div
					key={index}
					className="slider-content"
					style={{ background: `url('${data.baseurl+item.image}') no-repeat center center` }}
				>
					<div className="inner">
						<h1>{item.text}</h1>
					</div>
				
				</div>
			))}
		</Slider>
        </>
    <div className="landing">
    <div className="col">
        
   
            
            <br/>


            <div className="container full row center">
                <div className="main_header upper">OUR FEATURES</div>
            </div>

            <div className="container pad_50_100 description_section sm_20" style={{display:"flex", justifyContent: "center",flexDirection:'row'}}>
                <div className="landing_top_fet">
                    <div className="landing_top_fet_sec">
                            <div className="landing_top_fet_sec_col">
                                <div className="landing_top_fet_sec_col_cent_img hover06">
                                    <figure>
                                        <img width="145" height="142" src={require('../../assets/avl1-01.png')}/>
                                    </figure>
                                </div>
                            <div className="landing_top_fet_sec_col_cent">Compact Systems</div>
                            {/* <div className="landing_top_fet_sec_col_cent_last">book by the hour,day or week </div> */}
                        </div>
                    </div>
                    <div className="landing_top_fet_sec">
                    <div className="landing_top_fet_sec_col">
                        <div className="landing_top_fet_sec_col_cent_img hover06">
                        <figure>
                                <img width="145" height="142" src={require('../../assets/avl1-02.png')}/>
                        </figure>
                            
                        </div>
                        <div className="landing_top_fet_sec_col_cent">With Or Without Technician</div>
                            {/* <div className="landing_top_fet_sec_col_cent_last">Book a vehicle with lowest security deposit in.
                            </div> */}
                        </div>
                    </div>
                    <div className="landing_top_fet_sec">
                        <div className="landing_top_fet_sec_col">
                        <div className="landing_top_fet_sec_col_cent_img hover06">
                        <figure>
                                <img width="145" height="142" src={require('../../assets/avl1-03.png')}/>
                        </figure>
                            
                        </div>
                            <div className="landing_top_fet_sec_col_cent">International Brands</div>
                            {/* <div className="landing_top_fet_sec_col_cent_last">Get your car delivered right at your doorstep.Home, office or airport, we'll be there.</div> */}
                        </div>
                    </div>
                    <div className="landing_top_fet_sec">
                        <div className="landing_top_fet_sec_col">
                        <div className="landing_top_fet_sec_col_cent_img hover06">
                        <figure>
                                <img width="145" height="142" src={require('../../assets/avl1-04.png')}/>
                        </figure>
                            
                        </div>
                            <div className="landing_top_fet_sec_col_cent">Experienced Engineers</div>
                            {/* <div className="landing_top_fet_sec_col_cent_last">Book with or without fuel.</div> */}
                        </div>
                    </div>
                    {/* <div className="landing_top_fet_sec">
                        <div className="landing_top_fet_sec_col">
                        <div className="landing_top_fet_sec_col_cent_img hover06">
                        <figure>
                                <img width="65" height="62" src={require('../../assets/avl1-05.png')}/>
                        </figure>
                            
                        </div>
                            <div className="landing_top_fet_sec_col_cent">Instant Refund</div>
                            <div className="landing_top_fet_sec_col_cent_last">Refund and payments auto processed immediately</div>
                        </div>
                    </div> */}
                </div>
            </div>
            
            <div style={{display: localStorage.getItem('role') == 'vendor'  ? 'none' : 'block'}}>
            <div className="container full row center">
                <div className="main_header upper">PACKAGES BY THEME</div>
            </div>
            <div className="theme_box row wrap justify_between container full pad_50">

                {themes.map((item)=>(
                    <Link to={`${match.url}rentals/?theme=${item.id}`} className="box_item bg respi" style={{backgroundImage:"url(" + data.baseurl+item.image + ")",backgroundSize:'cover',textDecoration:'none'}} >
                    <div className="overlay col justify_end align_center">
                        <div className="txt upper light strong">{item.title}</div>
                    </div>
                     </Link>
                ))}
  
            </div>
            </div>

            <div style={{display: localStorage.getItem('role') == 'vendor' ? 'block' : 'none'}}>
            <div className="container full row center">
                <div className="main_header upper">categories</div>
            </div>
            <div className="add_p_box container full pad_50_100 row wrap justify_around">
                <Link to={`${match.url}categories/2`} className="circle_box" style={{textDecoration:'none'}}>
                    <div className="circle" style={{backgroundImage:"url(" + require('../../assets/mic.jpeg') + ")"}}></div>
                    <div className="text">MIC</div>
                </Link>
                <Link to={`${match.url}categories/2`} className="circle_box" style={{textDecoration:'none'}}>
                    <div className="circle" style={{backgroundImage:"url(" + require('../../assets/mic.jpeg') + ")"}}></div>
                    <div className="text">AMPLIFIER</div>
                </Link>
                <Link to={`${match.url}categories/2`}className="circle_box" style={{textDecoration:'none'}}>
                    <div className="circle" style={{backgroundImage:"url(" + require('../../assets/mic.jpeg') + ")"}}></div>
                    <div className="text">MONITOR SPEAKER</div>
                </Link>
                <Link to={`${match.url}categories/2`} className="circle_box" style={{textDecoration:'none'}}>
                     <div className="circle" style={{backgroundImage:"url(" + require('../../assets/mic.jpeg') + ")"}}></div>
                    <div className="text">MIXER</div>
                </Link>
                <Link to={`${match.url}categories/2`} className="circle_box" style={{textDecoration:'none'}}>
                    <div className="circle" style={{backgroundImage:"url(" + require('../../assets/mic.jpeg') + ")"}}></div>
                    <div className="text">SPOT LIGHT</div>
                </Link>
            </div>
            </div>

            <div className="book_box container full pad_50_100 row wrap justify_around align_center">
                <div className="txt light strong">Any problem in choosing Packages? Book a call for free, Our Sales team will love to assist you</div>
                <div className="button_box row justify_end">
                    <div className="button txt strong small" onClick={()=>history.push('/contact')}>Book a call</div>
                </div>
            </div>
        </div>
    </div>

  
            <Footer />
    </div>
    </ReactCSSTransitionGroup>
    
    )
}

export default App
