import React, { Component,useContext,useState} from 'react'
import '../pages/frontend/main.css'
import { Link, Route } from 'react-router-dom'
import auth from '../auth'
import { CartContext } from '../contexts/CartContext';

const App = ({ match,history }) => {
const {cart} = useContext(CartContext);
const [handbar,setHandbar] = useState(true)

function checkUserRole (direction) {
  let user = localStorage.getItem('role')
  switch (user) {
    case 'admin':
    return (
      <div style={{display:'flex',flexDirection:direction,alignItems:'center'}}>
      <MenuLink  to="/" label="Home" />
      <MenuLink  to="/rentals" label="Rentals" />
      <MenuLink  to="/vendor" label="Vendor" />
      <MenuLink  to="/about" label="About" />
      <MenuLink  to="/contact" label="Contact" />
      <Link to="/dashboard" className="button" style={{textDecoration:'none'}}>Dashboard</Link>
      </div>
    )
    break;
    case 'user':
      return (
        <div style={{display:'flex',flexDirection:direction,alignItems:'center'}}>
          <MenuLink  to="/" label="Home" />
          <MenuLink  to="/rentals" label="Rentals" />
          <MenuLink  to="/vendor" label="Vendor" />
          <MenuLink  to="/about" label="About" />
      
          <MenuLink  to="/contact" label="Contact" />
         
         
          <Link to="/profile" style={{position:'relative',cursor:'pointer',marginLeft:25}} >
            <img src={require('../assets/user.png')} style={{width:25,height:25}} />
          </Link>
        <Link to="/" className="button" style={{textDecoration:'none',marginRight:10}} onClick={()=>auth.logout()}>Logout</Link>
      </div>
      )
      break;
      case 'vendor':
        return (
          <div style={{display:'flex',flexDirection:direction,alignItems:'center'}}>
            <MenuLink  to="/" label="Home" />
            <MenuLink  to="/categories" label="Categories" />
            {/* <MenuLink  to="/vendor" label="Vendor" /> */}
            <MenuLink  to="/about" label="About" />
            <MenuLink  to="/contact" label="Contact" />
            <Link to="/cart" style={{position:'relative',cursor:'pointer'}} >
              <img src={require('../assets/carticon.png')} style={{width:25,height:20}} />
              <div style={{position:'absolute',height:20,width:20,backgroundColor:'#FF5722',top:-10,right:-10,display:'flex',alignItems:'center',justifyContent:'center',borderRadius:'50%'}}>
                  <div style={{fontSize:10,color:'white'}}>
                      {cart.length}
                  </div>
              </div>
            </Link>
            <Link to="/profile" style={{position:'relative',cursor:'pointer',marginLeft:25}} >
            <img src={require('../assets/user.png')} style={{width:25,height:25}} />
          </Link>
          <Link to="/" className="button" style={{textDecoration:'none',marginRight:10}} onClick={()=>auth.logout()}>Logout</Link>
        </div>
        )
        break;
    default : 
    return (
      <div style={{display:'flex',flexDirection:direction,alignItems:'center'}}>
      <MenuLink  to="/" label="Home" />
      <MenuLink  to="/rentals" label="Rentals" />
      {/* <MenuLink  to="/vendor" label="Vendor" /> */}
      <MenuLink  to="/about" label="About" />
      <MenuLink  to="/contact" label="Contact" />
     
      <Link to="/login" className="button" style={{textDecoration:'none'}}>Login/Signup</Link>
      </div>
  
    )
  }
} 

    return(
      
        <div className="nav_bar">
        <div className="left_section">
            <a href="/"><img src={require('../assets/log.png')} className="logo" /></a>
        </div>
        <div className="right_section">
                {checkUserRole("row")}
        </div>
        {handbar ?
            <div className="right_section_mob">
                <div id="toggle_nav_btn" className="toggle_button" onClick={()=>setHandbar(!handbar)}>
                    <div className="toggle_button_div"></div>
                    <div className="toggle_button_div"></div>
                    <div className="toggle_button_div"></div>
                </div>
            </div>
            :
            <div className="right_section_mob">
                   <div id="toggle_nav_btn" className="toggle_button" onClick={()=>setHandbar(!handbar)}>
                      <div className="toggle_button_div_2"></div>
                      <div className="toggle_button_div_3"></div>
                      <div className="toggle_button_div_4"></div>
                    </div>
              </div>
            }


                <div className="handbar_div right_section_mob" style={{display:handbar ? 'none' : 'block'}}>
                    {checkUserRole("column")}
                </div>
                        
     </div>
   
    )
}

function MenuLink({ label, to, activeOnlyWhenExact }) {
    return (
      <Route
        path={to}
        exact={activeOnlyWhenExact}
        children={({ match }) => (
            <Link to={to} className="flex-row item" style={match ? {textDecoration:'none',color:'black',fontWeight:'500'} : {textDecoration:'none',color:'black',fontWeight:'300'}}>
                 <div className="item">{label}</div>
            </Link>
        )}
      />
    );
  }

export default App