
import React, { Component,useState } from 'react'
import {Header, Footer} from '../../components'
import { Slide } from 'react-slideshow-image'

function App (props){
    const [slideImages,setSlideImages] = useState([require('../../assets/slider.jpg'),require('../../assets/1.jpg'),require('../../assets/2.jpg'),require('../../assets/3.jpg')])
    const [id,setId] = useState(0)
   
    const properties = {
        duration: 5000,
        transitionDuration: 500,
        infinite: true,
        indicators: true,
        arrows: true,
        onChange: (oldIndex, newIndex) => {
          console.log(`slide transition from ${oldIndex} to ${newIndex}`);
        }
      }
 
    return(
        <div id="container">>
            <Header {...props}/>
            <div>
        <div class="about">
        <div class="welcome_container">
            <div class="box">
                <div class="text">about</div>
            </div>
        </div>

        
        <div style={{marginTop:40}} >
        <div style={{display:'flex',justifyContent:'center',marginBottom:30}}>
                            <div className="main_header upper" style={{fontSize:22}}>OUR FEATURES</div>
        </div>
          {/* <img src={require('../../assets/slider.jpg')} style={{height:350,width:'100%'}} ></img> */}
          
          <div class="container pad_50_100 description_section sm_20" style={{display:"flex", justifyContent: "center",flexDirection:'row'}}>
                <div class="landing_top_fet">
                    <div class="landing_top_fet_sec">
                            <div class="landing_top_fet_sec_col">
                                <div class="landing_top_fet_sec_col_cent_img hover06">
                                    <figure>
                                        <img width="75" height="72" src={require('../../assets/avl1-01.png')}/>
                                    </figure>
                                </div>
                            <div class="landing_top_fet_sec_col_cent">Compact Systems</div>
                            {/* <div class="landing_top_fet_sec_col_cent_last">book by the hour,day or week </div> */}
                        </div>
                    </div>
                    <div class="landing_top_fet_sec">
                    <div class="landing_top_fet_sec_col">
                        <div class="landing_top_fet_sec_col_cent_img hover06">
                        <figure>
                                <img width="75" height="72" src={require('../../assets/avl1-02.png')}/>
                        </figure>
                            
                        </div>
                        <div class="landing_top_fet_sec_col_cent">With Or Without Technician</div>
                            {/* <div class="landing_top_fet_sec_col_cent_last">Book a vehicle with lowest security deposit in.
                            </div> */}
                        </div>
                    </div>
                    <div class="landing_top_fet_sec">
                        <div class="landing_top_fet_sec_col">
                        <div class="landing_top_fet_sec_col_cent_img hover06">
                        <figure>
                                <img width="75" height="72" src={require('../../assets/avl1-03.png')}/>
                        </figure>
                            
                        </div>
                            <div class="landing_top_fet_sec_col_cent">International Brands</div>
                            {/* <div class="landing_top_fet_sec_col_cent_last">Get your car delivered right at your doorstep.Home, office or airport, we'll be there.</div> */}
                        </div>
                    </div>
                    <div class="landing_top_fet_sec">
                        <div class="landing_top_fet_sec_col">
                        <div class="landing_top_fet_sec_col_cent_img hover06">
                        <figure>
                                <img width="75" height="72" src={require('../../assets/avl1-04.png')}/>
                        </figure>
                            
                        </div>
                            <div class="landing_top_fet_sec_col_cent">Experienced Engineers</div>
                            {/* <div class="landing_top_fet_sec_col_cent_last">Book with or without fuel.</div> */}
                        </div>
                    </div>
                    {/* <div class="landing_top_fet_sec">
                        <div class="landing_top_fet_sec_col">
                        <div class="landing_top_fet_sec_col_cent_img hover06">
                        <figure>
                                <img width="65" height="62" src={require('../../assets/avl1-05.png')}/>
                        </figure>
                            
                        </div>
                            <div class="landing_top_fet_sec_col_cent">Instant Refund</div>
                            <div class="landing_top_fet_sec_col_cent_last">Refund and payments auto processed immediately</div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
        
        <div class="heading_container">
            <div class="main">Who we are</div>
            <div class="sub">It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</div>
        </div>
    </div>
        <Footer />
    </div>
        </div>
    )
}


export default App