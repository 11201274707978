
import React, { Component,useContext,useEffect,useState} from 'react'
import {Header, Footer} from '../../components'
import { CartContext } from '../../contexts/CartContext';
import apiRequest from '../../apiRequest'
import {data} from '../../constant'
import { template } from 'handlebars';

function App (props){
  const {cart,setCart } = useContext(CartContext)
  const [total, setTotal] = useState('')
  // const [cartdata, setCartdata] = useState(


  useEffect(()=>{
    window.scrollTo(0, 0)
    console.log(cart)
    // setMycart(cart)
    // getTotal()
    
    // console.log("data cart",localStorage.removeItem('cartdata'))
    
   
    // console.log(resArr);
 }, [])



function DeleteProduct(id) {
  
    var data = [...cart]
    var myArray = data.filter(function( obj ) {
      return obj.id !== id;
    });
    var total = 0
    for(var i=0;i<myArray.length;i++) {
        total = total + myArray[i].vendor_price 
    }
    setTotal(total)
    setCart(myArray)
    // setCartdata(myArray)
    console.log(myArray)
}



function getTotal () {
  // console.log("cart data",cart)
  var total = 0
  for(var i=0;i<cart.length;i++) {
      total = total + cart[i].vendor_price * cart[i].count
  }
  // setTotal(total)
  return total
}


function onPurchaseClick() {
    if(cart.length > 0) {
      var bodyFormData = new FormData();
      bodyFormData.set('token', localStorage.getItem('token'));
      apiRequest('getMe',bodyFormData)
      .then(function (response) {
          console.log("data",response.data)
          var res=response.data
          if(res.vendor == "success"){
            console.log("verification success")
            createOrder()
          }else{
            // setVerification(true)
          }
      })
      .catch(error => {
        console.log(error.response.data)
      })
    }else{
      alert('cart is empty')
    }
}

function createOrder () {
  var bodyFormData = new FormData();
  bodyFormData.set('token', localStorage.getItem('token'));
  bodyFormData.set('products', JSON.stringify(cart));
  bodyFormData.set('amount',getTotal());
  apiRequest('orderVendor',bodyFormData)
  .then(function (response) {
      // console.log("data",response.data)
      // var res=response.data
      console.log(response)
      setCart([])
      setTotal(0)
      localStorage.removeItem('cartdata')
  })
  .catch(error => {
    console.log(error.response)
  })

}

    return(
        <div id="container">
        <Header {...props} />
        <div class="about">
        <div class="welcome_container">
            <div class="box">
                <div class="text">cart</div>
            </div>
        </div>
        </div>
        <div style={{marginTop:60,width:'60%',transform:'translate(30%,0%)',marginBottom:60,display:'block'}}>
         <div style={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
          <table class="main-table">
            <tr class="heading">
              <td>Product</td>
              <td>Price</td>
              <td>Quantity</td>
              {/* <td>Date</td> */}
              {/* <td>Addons Price</td> */}
              <td>Total</td>
              <td></td>
              <td></td>
            </tr>


            {cart.map((item)=>(
               <tr class="content">
               <td>{item.product_name}</td>
               <td class="package-name-click">{item.vendor_price}</td>
               <td>{item.count}</td>
               {/* <td>{item.date}</td>  */}
                <td>{item.count * item.vendor_price}</td> 
                {/* <td>{item.count}</td>  */}
               <td class="action-box flex-row">
                 <div class="button-round" onClick={() => DeleteProduct(item.id)}>delete</div>
               </td>
             </tr>
            ))} 
         
            
          </table>            
            <div style={{display:'flex',alignItems:'flex-end',flexDirection:'column',marginTop:30}}>
                <div><span style={{fontSize:16,fontWeight:'bold'}}>Subtotal</span> : {getTotal()}</div>
                <div><span style={{fontSize:16,fontWeight:'bold'}}>Total</span> : {getTotal()}</div>
                <div class="button-round" style={{marginTop:30}} onClick={()=>onPurchaseClick()}>Purchase</div>
            </div>  
            <div style={{display:'flex',alignItems:'flex-start',flexDirection:'column',marginTop:30}}>
                <div><span style={{fontSize:16,fontWeight:'bold'}}>Payment Details</span></div>
                <div><span style={{fontSize:16,fontWeight:'bold'}}>Account Number</span> : 917020078420358</div>
                <div><span style={{fontSize:16,fontWeight:'bold'}}>Account Name</span> : AVL Solutions India Pvt Ltd</div>
                <div><span style={{fontSize:16,fontWeight:'bold'}}>IFS Code</span> : UTIB0000081</div>
                <div><span style={{fontSize:16,fontWeight:'bold'}}>Bank</span> : Axis Bank</div>
            </div>
          </div>
          </div>
        <Footer />
        </div>
    )
}


export default App